import React from "react";
import { Link } from "react-router-dom";
import {ElementsConsumer} from '@stripe/react-stripe-js';
import {Helmet} from "react-helmet";

import PaymentForm from './forms/PaymentForm';

class Payment extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      checkoutLink: '/checkout/',
      totalCost: 0.00
    }

  }


  componentDidMount = () => {

    window.scrollTo(0, 0);

    if (localStorage.getItem('cartContents')) {

      let order = JSON.parse(localStorage.getItem('cartContents'));

      this.setState({
        order: order
      });

    }

  }




  render() {

      let checkoutProps = {};
      if (this.props.location.state) {
        checkoutProps = this.props.location.state;
      }

      return (
        <div className="content-wrap">

            <Helmet>
              <title>Payment - ylo</title>
              <meta name="description" content="Make a payment to complete your ylo order." />
            </Helmet>

            <div className="page-intro">
              <h1>Payment</h1>
            </div>

            <div>
              <ElementsConsumer>
                {({stripe, elements}) => (
                  <PaymentForm stripe={stripe} elements={elements} order={this.state.order} history={this.props.history} checkout={checkoutProps} />
                )}
              </ElementsConsumer>
            </div>

        </div>
      );

  }

}

export default Payment;
