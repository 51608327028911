import React from "react";
import { Link } from "react-router-dom";
import Spinner from "react-spinkit";
import moment from 'moment';
import {Helmet} from "react-helmet";
import BottomScrollListener from 'react-bottom-scroll-listener';

import * as api from "../../config/api";

import TabsMenu from "../tabs/TabsMenu";


class Blog extends React.Component {

    constructor(props) {
      super(props);
      this.state = {
        isLoading: true,
        isLoadingMore: false,
        accessToken: '',
        posts: [],
        offset: 0
      }
    }



    componentDidMount = () => {

      window.scrollTo(0, 0);

      this.getContent();

    }


    componentDidUpdate = (prevProps) => {
      if (this.props.match.params.id !== prevProps.match.params.id) {
        this.getContent();
      }
    }


    // getCategoryID = () => {
    //
    //   let categoryID = '';
    //
    //   switch (this.props.match.params.name) {
    //     case 'phones':
    //       categoryID = "16";
    //       break;
    //     case 'laptops':
    //       categoryID = "17";
    //       break;
    //     case 'accessories':
    //       categoryID = "18";
    //       break;
    //     case 'mobile internet':
    //       categoryID = "19";
    //       break;
    //     case 'services':
    //       categoryID = "183";
    //       break;
    //     case 'more':
    //       categoryID = "21";
    //       break;
    //     default:
    //       categoryID = '16';
    //   }
    //
    //   return categoryID;
    //
    // }



    getContent = () => {


     // Fetch page content from wordpress backend

     // const currPath = this.props.location.pathname;
     // const slug = currPath.replace(/\\/g, '');

     //this.props.match.params.name

     this.setState({
       posts: [],
       offset: 0,
       isLoading: false,
       isLoadingMore: false
     }, function() {
       this.fetchPosts();
     });


   }



   fetchPosts = () => {


     let fetchURL = api.wordpressURL + 'posts?_embed&per_page=8&offset=0';

     if (this.props.match.params.id) {
       fetchURL = api.wordpressURL + 'posts?_embed&per_page=8&offset=0&categories=' + this.props.match.params.id;
     }


     return fetch(fetchURL, {
       headers: {
         'Accept': 'application/json',
       },
     })
     .then((response) => response.json())
     .then((responseJson) => {

         this.setState((state) => {
            return {
              isLoading: false,
              posts: responseJson,
              offset: 8
            };
         });

     })
     .catch((error) => {

       this.setState({
         isLoading: false
       });

     });

   }



   fetchMorePosts = () => {


     if (this.state.offset >= 8) {

       this.setState({
         isLoadingMore: true
       });


       let fetchURL = api.wordpressURL + 'posts?_embed&per_page=8&offset=' + this.state.offset;

       if (this.props.match.params.id) {
         fetchURL = api.wordpressURL + 'posts?_embed&per_page=8&offset=' + this.state.offset + '&categories=' + this.props.match.params.id;
       }


       return fetch(fetchURL, {
         headers: {
           'Accept': 'application/json',
         },
       })
       .then((response) => response.json())
       .then((responseJson) => {

           this.setState((state) => {
              return {
                isLoadingMore: false,
                posts: [...state.posts, ...responseJson],
                offset: state.offset + 8
              };
           });

       })
       .catch((error) => {

         this.setState({
           isLoadingMore: false
         });

       });

     }

   }



   handleNearBottom = () => {
     this.fetchMorePosts();
   }


    render() {


      let blogPosts = (
        <div className="loading-spinner loading-more-spinner">
          <Spinner name="pacman" color="#65c84c" fadeIn="half" />
        </div>
      );

      if (this.state.posts && this.state.posts.length > 0) {

        blogPosts = this.state.posts.map((post) => {

            let postImage = (
              <div />
            );

            if (post.featured_media && post._embedded["wp:featuredmedia"].length > 0 && post._embedded["wp:featuredmedia"][0].media_details) {
                postImage = (
                  <Link to={"/blog/" + post.id + "/" + post.slug} className="post-image"><img className="fill-div" src={post._embedded["wp:featuredmedia"][0].media_details.sizes.thumbnail.source_url} /></Link>
                );
            }

            return (
              <article className="blog-strip-post column" key={post.id.toString()}>

                {postImage}

                <h3 dangerouslySetInnerHTML={{__html: post.title.rendered}}></h3>

                <Link className="medium-button" to={"/blog/" + post.id + "/" + post.slug}>Read Post</Link>

              </article>
            );

        });

      }


      if (this.state.isLoading) {

        return (
          <div className="loading-spinner">
            <Spinner name="pacman" color="#65c84c" fadeIn="half" />
          </div>
        );

      } else {

        // <div className="content">
        //   <h2>Thoughts from ylo</h2>
        // </div>

        let subHeading = "Keep up with all things ylo, get useful tips and read our take on creativity and business.";


        return (
          <div className="content-wrap">

            <Helmet>
              <title>Blog - ylo</title>
              <meta name="description" content={subHeading} />
            </Helmet>

            <div className="category-intro">
              <h1>Blog</h1>
              <h4>{subHeading}</h4>
            </div>

            <TabsMenu menuName="blog-tabs" />

            <div className="columns-wrap">
              <div className="blog-strip columns columns--3">
                {blogPosts}
              </div>
            </div>

            {this.state.isLoadingMore &&
              <div className="loading-spinner loading-more-spinner">
                <Spinner name="pacman" color="#65c84c" fadeIn="half" />
              </div>
            }

            <BottomScrollListener offset={875} debounce={500} onBottom={this.handleNearBottom} />
          </div>
        );

      }

    }

}

export default Blog;
