import React from "react";
import { Link } from "react-router-dom";
import Spinner from "react-spinkit";
import Select from "react-dropdown-select";
import { ValidatorForm } from 'react-form-validator-core';

import ValidatedTextInput from "./forms/ValidatedTextInput";
import ValidatedEmailInput from "./forms/ValidatedEmailInput";
import ValidatedNumberInput from "./forms/ValidatedNumberInput";

import * as api from "../../config/api";

class ContractMoreDetails extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      buttonLoading: false,
      accessToken: '',
      pageContent: '',

      orderNum: '',
      bankingNameOnAcc: '',
      bankingAccNum: '',
      bankingSortcode: '',
      pac: '',
      formSubmitted: false,

      buttonText: 'Send Details',
      totalCost: 0.00
    }

    this.handleOrderNumChange = this.handleOrderNumChange.bind(this);
    this.handleBankingNameOnAccChange = this.handleBankingNameOnAccChange.bind(this);
    this.handleBankingAccNumChange = this.handleBankingAccNumChange.bind(this);
    this.handleBankingSortcodeChange = this.handleBankingSortcodeChange.bind(this);
    this.handlePACChange = this.handlePACChange.bind(this);

    this.handleSubmit = this.handleSubmit.bind(this);
  }



  componentDidMount = () => {

    window.scrollTo(0, 0);

    const cartContents = JSON.parse(localStorage.getItem('cartContents'));
    if (cartContents) {
      this.calcTotal(cartContents);
    }

  }


  calcTotal = (cartContents) => {

    let runningTotal = 0.00;

    cartContents.forEach((cartItem, index) => {
      runningTotal += parseFloat(cartItem.price);

      if (cartContents.length - 1 === index) {

        if (runningTotal > 0) {
          this.setState({
            buttonText: "Make a Payment",
            totalCost: runningTotal.toFixed(2)
          });
        }

      }
    });

  }



  handleOrderNumChange = (event) => {
    this.setState({orderNum: event.target.value});
  }

  handleBankingNameOnAccChange = (event) => {
    this.setState({bankingNameOnAcc: event.target.value});
  }

  handleBankingAccNumChange = (event) => {
    this.setState({bankingAccNum: event.target.value});
  }

  handleBankingSortcodeChange = (event) => {
    this.setState({bankingSortcode: event.target.value});
  }

  handlePACChange = (event) => {
    this.setState({pac: event.target.value});
  }




  handleSubmit = (event) => {

    // prevent browser window jump for link
    event.preventDefault();

    console.log('submit begins!');

    this.setState({buttonLoading: true});


    let pacCode = 'Not Provided';

    if (this.state.pac) {
      pacCode = this.state.pac;
    }


    let metaData = [];

    metaData = [
      {
        "key": "Bank - Name on Account",
        "value": this.state.bankingNameOnAcc
      },
      {
        "key": "Bank - Account Number",
        "value": this.state.bankingAccNum
      },
      {
        "key": "Bank - Sortcode",
        "value": this.state.bankingSortcode
      },
      {
        "key": "PAC Code",
        "value": pacCode
      }
    ];


    let data = {
      "meta_data": metaData,
    };


    const bodyJSON = JSON.stringify(data);

    return fetch(api.woocommerceURL + 'orders/' + this.state.orderNum, {
      method: 'POST',
      headers: {
        'Authorization': api.woocommerceAuth,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: bodyJSON
    })
    .then((response) => response.json())
    .then((responseJson) => {

      //console.log(responseJson);

      if (responseJson.id && responseJson.status) {

        this.setState({ buttonLoading: false, formSubmitted: true });

        window.scrollTo(0, 0);

        //this.props.history.push('/contract/updated');

      } else {
        // handle error
        this.setState({buttonLoading: false});
      }


    })
    .catch((error) => {
    });


  }




  render() {

    if (this.state.formSubmitted) {

      return (
        <div className="content-wrap">

          <div className="page-intro">
            <h1>More Details</h1>
            <h4>To complete your contract setup, we just need a few more details.</h4>
          </div>

          <div className="content-with-sidebar">

            <div className="order-confirmation-block">
              <h3>Information Received</h3>

              <p>Thank you for providing the required information. Your contract is in the final stages of being setup. Keep a look out for further emails from us and our partners at 2Circles.</p>
            </div>

          </div>
        </div>
      );

    } else {

      let submitButton = this.state.buttonLoading ? (
        <div className="loading-spinner-button">
          <Spinner name="pacman" color="#65c84c" fadeIn="half" />
        </div>
      ) : (
        <input type="submit" value={this.state.buttonText} className="medium-button fadeIn" />
      );

      return (
        <div className="content-wrap">

          <div className="page-intro">
            <h1>More Details</h1>
            <h4>To complete your contract setup, we need a few more details.</h4>
          </div>

          <div className="content-with-sidebar">

            <ValidatorForm onSubmit={this.handleSubmit} style={{ maxWidth: '500px' }}>

              <div style={{ marginBottom: '10px' }}>
                <h3 style={{ marginBottom: '38px' }}>Banking Details</h3>
                <p>
                  Your first payment will not be processed until your contract has been approved. Your order number is available on the email we sent you when you first ordered.
                </p>
              </div>

              <div>
                <div className="input-group">
                  <div className="input-wrap">
                    <div className="input-label">Name on Account</div>
                    <ValidatedTextInput
                      onChange={this.handleBankingNameOnAccChange}
                      name="bankingNameOnAcc"
                      value={this.state.bankingNameOnAcc}
                      validators={['required', "matchRegexp:(^[-'a-zA-ZÀ-ÖØ-öø-ſ\\s]+$)"]}
                      errorMessages={['Please provide your name.', 'Ensure you only use letters.']}
                    />
                  </div>

                  <div className="input-wrap">
                    <div className="input-label">Order Number</div>
                    <ValidatedTextInput
                      onChange={this.handleOrderNumChange}
                      name="orderNum"
                      value={this.state.orderNum}
                      validators={["required", "isNumber"]}
                      errorMessages={["Please provide your order number.", 'Only use numbers.']}
                    />
                  </div>
                </div>

                <div className="input-group">
                  <div className="input-wrap">
                    <div className="input-label">Account Number</div>
                    <ValidatedTextInput
                      onChange={this.handleBankingAccNumChange}
                      name="bankingAccNum"
                      value={this.state.bankingAccNum}
                      validators={["required", "isNumber"]}
                      errorMessages={["Please provide an account number.", "Only use numbers."]}
                    />
                  </div>

                  <div className="input-wrap">
                    <div className="input-label">Sortcode</div>
                    <ValidatedTextInput
                      onChange={this.handleBankingSortcodeChange}
                      name="bankingSortcode"
                      value={this.state.bankingSortcode}
                      validators={['required', "matchRegexp:^[0-9 ()+-]+$"]}
                      errorMessages={['Please provide a sortcode.', 'Only use numbers.']}
                    />
                  </div>
                </div>

                <h3 style={{marginTop: '20px'}}>Keeping your Number</h3>

                <p>If you want to keep your existing number and move it over to your new contract. You'll need to provide us with a PAC code.</p>

                <p><a target="_blank" href="https://weareylo.com/blog/2588/end-it-with-a-text">How to get my PAC code?</a></p>

                <div className="input-group">
                  <div className="input-wrap">
                    <div className="input-label">PAC Code (optional)</div>
                    <ValidatedTextInput
                      onChange={this.handlePACChange}
                      name="pac"
                      value={this.state.pac}
                      validators={["isNumber"]}
                      errorMessages={['Only use numbers.']}
                    />
                  </div>
                </div>


                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                  {submitButton}
                </div>
            </div>

            </ValidatorForm>

          </div>
        </div>
      );

    }


  }

  //<input type="text" onChange={this.handleCompanyIndustryTypeChange} name="companyIndustryType" value={this.state.companyIndustryType} className="txt-input" />

}

export default ContractMoreDetails;
