import React from "react";
import Truncate from 'react-truncate';
import htmr from 'htmr';

const CategoryBanner = (props) => {

    if (props.content) {

        //console.log(props);

        let htmlElement = htmr(props.content);
        let htmlElementProps = Object.assign({}, htmlElement.props); // create custom props object

        if (htmlElement.props.className == "banner-wrapper") {
            // Add style with backgroundColor to custom props object for wrapping div
            htmlElementProps.style = { backgroundColor: props.colour };
        }

        // Merge in a custom element with custom props, replacing original element props
        htmlElement = {...htmlElement, ...{props: htmlElementProps}};

        return (
            <>{htmlElement}</>
        );

    } else {

        return (
            <></>
        );

    }
}

export default CategoryBanner;
