import React from "react";
import { Link } from "react-router-dom";
import Spinner from "react-spinkit";
import ReactGA from 'react-ga';
import {Helmet} from "react-helmet";
import Select from "react-dropdown-select";
import cheerio from 'cheerio';

import * as api from "../config/api";
import * as AvailablePlans from "../config/AvailablePlans";
import SwitchContent from "../lib/SwitchContent";

import ProductContractPlans from "./ProductContractPlans";
import ProductDeals from "./ProductDeals";


class Product extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      accessToken: '',
      allCategories: [],
      productBottomContent: '',
      productImages: [],
      productImagesDisplayed: [],
      variations: [],
      selectedOptions: [{ name: '', option: '' }],
      mainImage: '',
      productPrice: '',
      selectedVariationID: 0,
      //numOfConnections: 1,
      currentSlideIndex: 0,
      translateValue: 0,
      prevImageArrowOpacity: 0.25,
      nextImageArrowOpacity: 0.25,
      relatedProducts: [],
      //monthlyData: '',
      //simPlans: [],
      reevoo: {},
      showProductExtras: false,
      itemValue: ''
    }

    //this.handleOptionSelected = this.handleOptionSelected.bind(this);
    this.handleOptionsChange = this.handleOptionsChange.bind(this);
  }

  componentDidMount = () => {
    window.scrollTo(0, 0);

    // this.getPlans();
    //
    // if (this.props.match.params.data) {
    //
    //     this.setState({
    //       monthlyData: this.props.match.params.data.toUpperCase()
    //     });
    //
    // }

    this.getCategories();
    this.getContent();

    window.fbq('track', 'ViewContent');
  }

  componentDidUpdate = (prevProps) => {

    if (prevProps.match.params.id !== this.props.match.params.id) {

      window.scrollTo(0, 0);

      this.setState({
        isLoading: true,
        product: {},
        productImages: [],
        productImagesDisplayed: [],
        variations: [],
        selectedOptions: [{ name: '', option: '' }],
        mainImage: '',
        productPrice: '',
        selectedVariationID: 0,
        currentSlideIndex: 0,
        translateValue: 0,
        prevImageArrowOpacity: 0.25,
        nextImageArrowOpacity: 0.25,
        relatedProducts: []
      }, function() {
        this.getContent();
      });

    } else if (prevProps.contentType !== this.props.contentType && this.state.allCategories.length > 0) {

      //const categoryPath = '/category/' + this.state.product.categories[1].slug;

      const categoryPaths = this.state.product.categories.map(category => '/category/' + category.slug);
      let newPath = SwitchContent(this.props.contentType, categoryPaths, this.state.allCategories);
      newPath = '/category' + newPath;

      if (!categoryPaths.includes(newPath)) {
        this.props.history.push(newPath);
      }

      window.scrollTo(0, 0);

    }

  }

  getCategories = () => {

   // Get all categories, used in aid of contentType switching to find
   // a suitable business or non business version of a category to switch to

   return fetch(api.cacheURL + 'categories.php', {
     headers: {
       'Accept': 'application/json'
     }
   })
   .then((response) => response.json())
   .then((responseJson) => {

     this.setState({
       allCategories: responseJson,
     });

   })
   .catch((error) => {
   });

 }


  filterProductImages = (imagesArray, options) => {

    return imagesArray.filter(el => {

      // Get option name from the end of the image title/name
      const optionFromImageName = el.name.toLowerCase().substr(el.name.toLowerCase().length - options[0].option.toLowerCase().length);

      // Check img has name/title and the name contains the word filter, then we know to filter it.
      if (el.name && el.name.toLowerCase().indexOf('filter') !== -1) {

        // Workaround for colour due to old format title name with trailing info
        // new format requires image title/name to end with "filter option name"

        if (options[0].name === "Colour") {
          return el.name.toLowerCase().indexOf(options[0].option.toLowerCase()) !== -1;
        } else {
          // Use exact option name from end of image title, this prevents:
          // iPhone 11 Pro images showing when looking for iPhone 11 etc
          return optionFromImageName.indexOf(options[0].option.toLowerCase()) !== -1;
        }

      } else if (el.src === options[0].image) {

        return true;

      } else {

        return false;

      }

    });

  }

  checkImageRepeatSrc = (arr, newImage) => {
    return arr.some(item => item.src === newImage);
  }

  getRelatedProducts = (relatedProductIDs) => {


    let relatedProductsArray = [];

    let foreachPromise = Promise.all(relatedProductIDs.map(function (productID, index) {

      if (index <= 2) {

        return fetch(api.cacheURL + 'item-by-id.php?id=' + productID, {
          headers: {
            'Accept': 'application/json'
          }
        })
        .then((response) => response.json())
        .then((responseJson) => {

          if (responseJson?.id) {
            relatedProductsArray.push(responseJson);
          }

        })
        .catch((error) => {
            //console.log(error);
        });

      }

    }));


    return Promise.all([foreachPromise]).then(() => {

        this.setState({
          relatedProducts: relatedProductsArray
        });

    });

  }



  getContent = () => {

   // Fetch page content from wordpress backend

   const productID = this.props.match.params.id;

   let providedVariationID = this.props.match.params.variationid;
   if (providedVariationID === 'default') {
     providedVariationID = 0;
   }

   return fetch(api.cacheURL + 'item-by-id.php?id=' + productID, {
     headers: {
       'Accept': 'application/json'
     }
   })
   .then((response) => response.json())
   .then((responseJson) => {

       if (responseJson?.error) {
            this.setState({
              isLoading: false
            });

            return;
        }

       if ((responseJson.related_ids && responseJson.related_ids.length > 0) && (responseJson.upsell_ids && responseJson.upsell_ids.length > 0)) {
         // related_ids come as an array of product ids, the product data then needs to be pulled from each
         this.getRelatedProducts(responseJson.upsell_ids.concat(responseJson.related_ids));

       } else if (responseJson.related_ids && responseJson.related_ids.length > 0) {

         this.getRelatedProducts(responseJson.related_ids);
       }

       if (responseJson.variations && responseJson.variations.length > 0) {

         let variationsArray = responseJson.product_variations;
         let presumedOptions = [];
         let defaultOptions = [];
         let itemValue = responseJson.acf.insurance_item_value;
         // Should be ultimately overriden by variation image for the defaukt attribute/variaition
         let optionImage = responseJson.images[0].src;

         // Tweak the output as a new variationsArray
         // variationsArray = responseJson.product_variations.map((variation, index) => {
         //     let variationObj = {};
         //     variationObj.attributes = variation.attributes;
         //     variationObj.id = variation.id;
         //     variationObj.image = variation.image.src;
         //     variationObj.price = variation.price;
         //     variationObj.itemValue = variation.item_value;
         //     return variationObj;
         // });

         variationsArray.forEach((variation, i) => {

           if (responseJson.default_attributes && responseJson.default_attributes.length > 0) {

             // presumedOptions not needed, we have default, utilise

             // Remove id for comparison sake as variations output doesn't come with the id
             const defaultAttributesSimplified = responseJson.default_attributes.map(({ id, ...item }) => item);

             if (JSON.stringify(defaultAttributesSimplified) === JSON.stringify(variation.attributes)) {
               if (variation.item_value) {
                 itemValue = variation.item_value; // item value with default attributes set
               }

               optionImage = variation.image.src;
             }

           } else if (i === 0) {

             // Set first variaiton as presumed options in absence of defualts set or url request
             let vattr = 0;
             for (vattr = 0; vattr < variation.attributes.length; vattr++) {

               presumedOptions.push({
                 name: variation.attributes[vattr].name,
                 option: variation.attributes[vattr].option,
                 price: variation.price,
                 image: variation.image.src,
                 itemValue: variation.itemValue,
                 variationID: variation.id
               });

             }

           }



           if (
              providedVariationID &&
              parseInt(variation.id) === parseInt(providedVariationID)
           ) {

             // URL requested variation, set that as the presumedOption

             // reset set options
             presumedOptions = [];

             let vattr = 0;
             for (vattr = 0; vattr < variation.attributes.length; vattr++) {

               presumedOptions.push({
                 name: variation.attributes[vattr].name,
                 option: variation.attributes[vattr].option,
                 price: variation.price,
                 image: variation.image.src,
                 itemValue: variation.itemValue,
                 variationID: variation.id
               });

             }

           }

         });



         if (responseJson.default_attributes && responseJson.default_attributes.length > 0 && presumedOptions.length === 0) {

           // Got default attributes, sort them out and set the default selected options
           // -----------------------

           let da = 0;
           for (da = 0; da < responseJson.default_attributes.length; da++) {

             // Make the option lowercase and remove dashes, woocommerce currently makes
             // default attributes lc uri style, unlike in other situations, seems a bug,
             // this may be resolved one day, to avoid future conflicts, we lowercase them to be sure, remove special characters
             // then get the normal case version from attributes

             let daOptionLC = responseJson.default_attributes[da].option.toLowerCase();
             let daFilteredOption = daOptionLC.replace(/-/g, " ");

             if (this.props.match.params.colour && responseJson.default_attributes[da].name === 'Colour') {
               // If we have a url param for colour and we are currently setting that attribute
               daFilteredOption = this.props.match.params.colour;
             }

             // Set this for now, should be replaed with correct normalised version
             // from attributes below
             let setOption = daFilteredOption;

             let attr = 0;
             for (attr = 0; attr < responseJson.attributes.length; attr++) {

               let opt = 0;
               for (opt = 0; opt < responseJson.attributes[attr].options.length; opt++) {

                 // Make the option lowercase and remove dashes
                 let aOptionLC = responseJson.attributes[attr].options[opt].toLowerCase();
                 let aFilteredOption = aOptionLC.replace(/-/g, " ");

                 if (aFilteredOption === daFilteredOption) {
                   // If this option in lowercase matches the default attribute, get it
                   // as it normally would appear and set that, doing this means,
                   // all options will be presented the same mixed case in all sceanrios
                   // and matches variations data
                   setOption = responseJson.attributes[attr].options[opt];
                 }

               }

             }


             defaultOptions.push({
               name: responseJson.default_attributes[da].name,
               option: setOption,
               price: responseJson.price,
               image: optionImage,
               itemValue: itemValue,
               variationID: 0
             });
             // variationID = 0 as its a default attribute,
             // saves looping through variation data for the matching option value

             if (responseJson.default_attributes.length - 1 === da) {

               const filteredImages = this.filterProductImages(responseJson.images, defaultOptions);

               this.setState({
                 isLoading: false,
                 product: responseJson,
                 productPrice: responseJson.price,
                 mainImage: responseJson.images[0].src,
                 productImages: responseJson.images,
                 productImagesDisplayed: filteredImages,
                 selectedOptions: defaultOptions,
                 variations: variationsArray,
                 itemValue: itemValue
               });

             }

           }


         } else {

           // No default attributes or there's a provided variaiton, use the presumedOptions set a little earlier
           // -----------------------

           if (presumedOptions[0].itemValue) {
             itemValue = presumedOptions[0].itemValue;
           }

           const checkImageSrc = this.checkImageRepeatSrc(responseJson.images, presumedOptions[0].image);

           if (presumedOptions[0].image && !checkImageSrc) {

             this.setState(prevState => ({
              isLoading: false,
              product: responseJson,
              productPrice: presumedOptions[0].price,
              mainImage: presumedOptions[0].image,
              productImages: [{"name": "filter " + presumedOptions[0].option, "src": presumedOptions[0].image}, ...responseJson.images],
              productImagesDisplayed: this.filterProductImages([{"name": "filter " + presumedOptions[0].option, "src": presumedOptions[0].image}, ...responseJson.images], presumedOptions),
              selectedOptions: presumedOptions,
              variations: variationsArray,
              itemValue: itemValue
             }));

           } else {

             // variation image already included in main set of images

             this.setState(prevState => ({
              isLoading: false,
              product: responseJson,
              productPrice: presumedOptions[0].price,
              mainImage: responseJson.images[0].src,
              productImages: responseJson.images,
              productImagesDisplayed: this.filterProductImages(responseJson.images, presumedOptions),
              selectedOptions: presumedOptions,
              variations: variationsArray,
              itemValue: itemValue
             }));

           }

         } // end of handling various scenarios with variaitons included


     } else {

       // No default attributes or variations without a default set

       this.setState({
         isLoading: false,
         product: responseJson,
         productPrice: responseJson.price,
         mainImage: responseJson.images[0].src,
         productImages: responseJson.images,
         productImagesDisplayed: responseJson.images,
         itemValue: responseJson.acf.insurance_item_value
       });

     }

     const reevooLink = (responseJson.acf && responseJson.acf.reevoo_link) ? responseJson.acf.reevoo_link : '';
     if (reevooLink) {
       this.scrapeReevoo(reevooLink);
     }


     if (this.state.productImagesDisplayed.length > 1) {
       this.setState({
         nextImageArrowOpacity: 1
       })
     }

     this.generateStructuredJSON();

   })
   .catch((error) => {
   });




   // Fetch additional page content from wordpress backend

   return fetch(api.cacheURL + 'item-by-id.php?slug=why-ylo-product-page-banner', {
     headers: {
       'Accept': 'application/json'
     },
   })
   .then((response) => response.json())
   .then((responseJson) => {

       this.setState({
         isLoading: false,
         productBottomContent: responseJson.content.rendered,
       }, function() {
       });

   })
   .catch((error) => {
   });


 }




 handleOptionsChange = (attributeGroupName, option) => {

     //find the index of the current option obj with same group name to ultimately replace the values
     const optToChangeIndex = this.state.selectedOptions.findIndex(sOpt => sOpt.name === attributeGroupName);

     // Find a matching variation by taking the selected option e.g. 128gb
     // adding that to any other selected options like a colour and then finding
     // the variation which contains all those option values
     // involves reducing both arrays to just an array of options to match

     //console.log(attributeGroupName);

     const variationData = this.state.variations.find(variation => {

       //console.log(variation);

       const remainingSelectedOptions = this.state.selectedOptions.filter(sOpt => sOpt.name != attributeGroupName); // Skip the old option for the attributeGroupName selected
       const selectedOptionsToMatch = remainingSelectedOptions.map(rOpt => rOpt.option.toLowerCase()); // Create an array of just option values
       selectedOptionsToMatch.push(option.toLowerCase()); // add new option value selected, since we skipped the old one

       const variationOptions = variation.attributes.map(vOpt => vOpt.option.toLowerCase());

       if (
         variationOptions.every(r => selectedOptionsToMatch.includes(r))
       ) {
         return variation;
       }

     });


     let updatedOpt;

     if (variationData) {

       // Make new option object replacing the previous data for the particular attributeGroupName
       updatedOpt = {
         ...this.state.selectedOptions[optToChangeIndex],
         name: attributeGroupName,
         option: option,
         price: variationData.price,
         image: variationData.image.src,
         itemValue: variationData.itemValue,
         variationID: variationData.id
       };

     } else {

       // Someone forgot to add the variation with image etc for the attribute
       // Keep existing price and image, just change the selected for appearence sake

       updatedOpt = {
         ...this.state.selectedOptions[optToChangeIndex],
         name: attributeGroupName,
         option: option
       };

     }


     // make final new array of objects by combining updated object and anything else in the array of selected options
     const newSelectedOptions = [
       ...this.state.selectedOptions.slice(0, optToChangeIndex),
       updatedOpt,
       ...this.state.selectedOptions.slice(optToChangeIndex + 1),
     ];


     if (updatedOpt.itemValue) {
       this.setState({
        itemValue: updatedOpt.itemValue
      });
     }


     // Update the selected options in state
     this.setState({
        selectedOptions: newSelectedOptions
      });


     if (updatedOpt && updatedOpt.image) {

       const checkImageSrc = this.checkImageRepeatSrc(this.state.productImages, updatedOpt.image);

       if (!checkImageSrc) {

         this.setState(prevState => ({
          mainImage: updatedOpt.image,
          productImages: [{"name": "", "src": updatedOpt.image}, ...prevState.productImages],
          productImagesDisplayed: this.filterProductImages([{"name": "", "src": updatedOpt.image}, ...prevState.productImages], newSelectedOptions),
          currentSlideIndex: 0,
          translateValue: 0,
          nextImageArrowOpacity: 0.25,
          prevImageArrowOpacity: 0.25,
          productPrice: updatedOpt.price,
          selectedVariationID: updatedOpt.variationID
        }), () => {

          if (this.state.productImagesDisplayed.length > 1) {
            this.setState({
             nextImageArrowOpacity: 1,
           });
          }

        });

       } else {

         // Selection changed but not an image we don't already have the src for, just needs a quick filtering
         // and updating of price, incase thats different


         this.setState(prevState => ({
          productImagesDisplayed: this.filterProductImages(prevState.productImages, newSelectedOptions),
          currentSlideIndex: 0,
          translateValue: 0,
          nextImageArrowOpacity: 0.25,
          prevImageArrowOpacity: 0.25,
          productPrice: updatedOpt.price,
          selectedVariationID: updatedOpt.variationID
        }), () => {

          if (this.state.productImagesDisplayed.length > 1) {
            this.setState({
             nextImageArrowOpacity: 1,
           });
          }

        });

       }

   }

 }




 gotoDeal = (url) => {

   // Record to Google Analytics adding to cart or affliate product adding to an external cart, which also triggers opening of new tab
   ReactGA.event({
      category: 'Add to cart',
      action: 'Affiliate Product Added to External Cart'
      //value: comissionValue
    });
   //window.gtag_report_conversion();

   // Open deal in new tab
   const newWindow = window.open(url, '_blank');
   if (newWindow) {
     newWindow.focus();
   }

 }



 addContractToCart = (planName, planIcon, monthlyData, monthlyTexts, monthlyMinutes, monthlyCost, upfrontCost, numOfConnections) => {

   // This function now triggered by child component ProductContractPlans
   // prevent browser window jump for link
   //event.preventDefault();

   let newCartContents = [];
   if (localStorage.getItem('cartContents')) {
     newCartContents = JSON.parse(localStorage.getItem('cartContents'));
   }

   let cartItem = {};
   cartItem.productID = this.state.product.id;

   if (this.state.selectedVariationID && this.state.selectedVariationID !== 0) {
     cartItem.variationID = this.state.selectedVariationID;
   }

   let productCurrOptions = this.productSetOptionsString();

   cartItem.name = this.state.product.name;
   cartItem.summary = productCurrOptions;
   cartItem.image = this.state.productImagesDisplayed[0].src;
   cartItem.quantity = numOfConnections;
   cartItem.price = upfrontCost;

   cartItem.phoneContract = true;
   cartItem.contractPlan = planName;
   cartItem.contractPlanIcon = planIcon;
   cartItem.contractMonthlyData = monthlyData;
   cartItem.contractMonthlyTexts = monthlyTexts;
   cartItem.contractMonthlyMinutes = monthlyMinutes;
   cartItem.contractMonthlyCost = monthlyCost;
   cartItem.contractUpfrontCost = upfrontCost;


   newCartContents.push(cartItem);

   localStorage.setItem('cartContents', JSON.stringify(newCartContents));


   // this.setState({
   //   showProductExtras: true
   // });

   //this.props.history.push('/product/extras');


   const acf = this.state.product.acf;

   //|| (this.state.product.cross_sell_ids && this.state.product.cross_sell_ids.length > 0)
   if (acf && acf.insurance_available) {

     this.props.history.push({
        pathname: '/product/extras',
        state: {
          insurance: {
            "available": acf.insurance_available,
            "equipment": acf.insurance_equipment_type,
            "item_value": this.state.itemValue
          },
          product: this.state.product,
          //crossSellIDs: this.state.product.cross_sell_ids
        }
      });

   } else {

     this.props.history.push('/checkout/cart');

   }

 }



 addProductToCart = (event) => {

   // prevent browser window jump for link
   event.preventDefault();

   let newCartContents = [];
   if (localStorage.getItem('cartContents')) {
     newCartContents = JSON.parse(localStorage.getItem('cartContents'));
   }

   let cartItem = {};
   cartItem.productID = this.state.product.id;

   if (this.state.selectedVariationID && this.state.selectedVariationID !== 0) {
     cartItem.variationID = this.state.selectedVariationID;
   }

   let productCurrOptions = this.productSetOptionsString();

   cartItem.name = this.state.product.name;
   cartItem.summary = productCurrOptions;
   cartItem.image = this.state.productImagesDisplayed[0].src;
   cartItem.quantity = 1;
   cartItem.price = this.state.productPrice;

   newCartContents.push(cartItem);

   localStorage.setItem('cartContents', JSON.stringify(newCartContents));


   const acf = this.state.product.acf;
   // || (this.state.product.cross_sell_ids && this.state.product.cross_sell_ids.length > 0)
   if (acf && acf.insurance_available) {

      this.props.history.push({
         pathname: '/product/extras',
         state: {
           insurance: {
             "available": acf.insurance_available,
             "equipment": acf.insurance_equipment_type,
             "item_value": this.state.itemValue
           },
           product: this.state.product,
           //crossSellIDs: this.state.product.cross_sell_ids
         }
       });

   } else {

     this.props.history.push('/checkout/cart');

   }


 }




  gotoPrevSlide = (event) => {

    // prevent browser window jump for link
    event.preventDefault();


    if (this.state.currentSlideIndex === 1) {
      // Just hit previous, index is about to goto zero making the back arrow no longer active
      this.setState({
       prevImageArrowOpacity: 0.25
      });
    }

    if (this.state.currentSlideIndex === this.state.productImagesDisplayed.length - 1) {

      this.setState(prevState => ({
       nextImageArrowOpacity: 1,
       currentSlideIndex: prevState.currentSlideIndex - 1,
       translateValue: prevState.translateValue + 100
      }));

    } else if (this.state.currentSlideIndex === 1) {

      this.setState(prevState => ({
       prevImageArrowOpacity: 0.25,
       currentSlideIndex: prevState.currentSlideIndex - 1,
       translateValue: prevState.translateValue + 100
      }));

    } else if (this.state.currentSlideIndex !== 0) {

      this.setState(prevState => ({
       currentSlideIndex: prevState.currentSlideIndex - 1,
       translateValue: prevState.translateValue + 100
      }));

    }


  }



 gotoNextSlide = (event) => {

   // prevent browser window jump for link
   event.preventDefault();

   // was 540 when doing px

   if (this.state.currentSlideIndex === 0 && this.state.productImagesDisplayed.length > 1) {
     this.setState({
      prevImageArrowOpacity: 1
     });
   }

   if (this.state.currentSlideIndex === this.state.productImagesDisplayed.length - 2) {

     this.setState(prevState => ({
      nextImageArrowOpacity: 0.25,
      currentSlideIndex: prevState.currentSlideIndex + 1,
      translateValue: prevState.translateValue + -100
     }));

   } else if (this.state.currentSlideIndex === this.state.productImagesDisplayed.length - 1) {

     this.setState(prevState => ({
      prevImageArrowOpacity: 0.25,
      nextImageArrowOpacity: 1,
      currentSlideIndex: 0,
      translateValue: 0
     }));

   } else {

     this.setState(prevState => ({
      currentSlideIndex: prevState.currentSlideIndex + 1,
      translateValue: prevState.translateValue + -100
     }));

   }


 }





   scrapeReevoo = (url) => {

     // const headers = {
     //     'User-Agent': 'Mozilla/5.0 (Windows NT 6.1; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/27.0.1453.110 Safari/537.36',
     //     'Content-Type' : 'application/x-www-form-urlencoded'
     //  };

      return fetch('https://weareylo.com/scrapingRedirect.php?url=' + url, {
      })
      .then((response) => response.text())
      .then((responseHTML) => {

        const $ = cheerio.load(responseHTML);

        const reevooData = JSON.parse($("script.microdata-snippet").html());

        this.setState({ reevoo: reevooData.aggregateRating });

      })
      .catch((error) => {
      });

  }


  // showReviewsBox = (link) => {
  //
  // }


  productSetOptionsString = () => {

    let productCurrOptions = '';

    // Get selected options to display in order/cart summary
    if (this.state.selectedOptions) {
        this.state.selectedOptions.forEach((sOpt, index) => {
          productCurrOptions += sOpt.option;

          if (index != this.state.selectedOptions.length - 1) {
            productCurrOptions += ', ';
          }
        });

    }

    return productCurrOptions;

  }


  generateStructuredJSON = () => {

      let data = {};

      if (this.state.product) {
          data = {
              "@context": "https://schema.org/",
              "@type": "Product",
              "productID": this.state.product.id,
              "name": `${this.state.product.name}`,
              "image": this.state.productImagesDisplayed[0].src,
              "description": this.state.product.description,
              "url": window.location.href,
              "offers": [
                  {
                      "@type": "Offer",
                      "itemCondition": "https://schema.org/NewCondition",
                      "priceCurrency": "GBP",
                      "price": this.state.productPrice,
                      "availability": "https://schema.org/InStock"
                  }
              ]
          };

          // brand
          // if(prod['brand']) {
          //     data['mpn'] = prod['brand'];
          //     data['brand'] = {
          //         "@type": "Thing",
          //         "name": `${prod['brand']}`
          //     };
          // }

          // logo
          // if(prod['logo']){
          //     data['logo'] = imgPath + prod['logo'];
          // }
      }

      this.setState({structeredJSON: JSON.stringify(data)});

  }


  render() {

    if (this.state.isLoading || !this.state.product) {

      return (
        <div className="loading-spinner">
          <Spinner name="pacman" color="#65c84c" fadeIn="half" />
        </div>
      );

    } else {


      let options = null;
      let specsList = null;
      let specs = null;


      if (this.state.product && this.state.product.attributes) {

        options = this.state.product.attributes.map((attribute, index) => {

          if (attribute.options.length > 0 && attribute.variation) {

            const attributeGroupName = attribute.name;
            const selectedOption = this.state.selectedOptions.find(sOpt => sOpt.name === attributeGroupName);
            const currentOptionSet = selectedOption ? selectedOption.option : '';


            //if (attribute.options.length > 4) {

              // create new array in select/dropdown format
              const selectOptions = attribute.options.map((option, i) => {
                return { "name": option };
              });

              return (
                <div className="input-group" style={{ marginBottom: '20px' }} key={attributeGroupName}>
                  <div>
                    <div className="input-label">{attributeGroupName}</div>
                    <Select
                      options={selectOptions}
                      valueField="name"
                      labelField="name"
                      searchable={false}
                      clearable={false}
                      onChange={(values) => this.handleOptionsChange(attributeGroupName, values[0].name)}
                      className="dropdown"
                      color="#121212"
                    />
                  </div>
                </div>
              );

            //} else {

              // Note: selectedOptions contains attributes that are variations
              // it is presented as an array, I kept the WC naming convention
              // [
              //   {name: "Colour", option: "red"}
              //   {name: "Storage", option: "64gb"}
              // ]

              // selectedOption allows us to get a match between a product attribute,
              // and the options we have set for the default/selected. We may
              // have 5 colours, this allow us to figure out which one is set
              // currently and present it so

              // return (
              //   <div className="product-option-group" key={index.toString()}>
              //     {
              //      attribute.options.map((option, i) => {
              //
              //        return (
              //          <div key={option}>
              //            <a
              //             href="#"
              //             className={option === currentOptionSet ? "product-option product-option--selected" : "product-option"}
              //             onClick={this.handleOptionSelected.bind(this, attributeGroupName, option)}
              //           >
              //             {option}
              //           </a>
              //          </div>
              //        )
              //      })
              //     }
              //
              //   </div>
              // );


            //}


          } else {

            return null;

          }

        });



        specsList = this.state.product.attributes.map((attribute, index) => {

          if (attribute.options.length === 1 && !attribute.variation && attribute.visible) {

            // Only get attributes that are not variations and intended as specs

            return (
              <dl key={index.toString()}>
                <dt>{attribute.name}</dt>
                <dd>{attribute.options[0]}</dd>
              </dl>
            );

          } else {

            return null;

          }

        });


        specs = this.state.product.attributes.length > 2 ? (
          <div className="product-specs">
            {specsList}
          </div>
        ) : (
          <div></div>
        );

      }

      const relatedProducts = this.state.relatedProducts.map((product) => {
        return (
          <li className="product" key={product.id.toString()}>

            <Link to={"/product/" + product.id + "/" + product.slug} className="product-image"><img src={product?.images[0]?.src} alt={product?.images[0]?.name} /></Link>

            <h3>{product.name}</h3>

            <div className="price-view">
              <Link className="medium-button" to={"/product/" + product.id + "/" + product.slug}>View</Link>
            </div>

          </li>
        );
      });

      let miniProductImage = '';
      if (this.state.productImagesDisplayed && this.state.productImagesDisplayed.length > 0) {
        miniProductImage = this.state.productImagesDisplayed[0].src;
      }

      let productPriceAndBuy = '';
      if (this.state.productPrice) {
            productPriceAndBuy = (
              <div className="product-price-buy">
                <h4 className="price">£{this.state.productPrice}</h4>
                <a href="#" onClick={this.addProductToCart.bind(this)} className="medium-button">Add to Bag</a>
              </div>
            );
      }

      if (this.state.product.contract_product === "1" && this.state.product.contract_show_data_plans !== "1") {

        // Monthly contract cost but doesn't use data plans
        productPriceAndBuy = (
          <div className="product-price-buy">
            <h4 className="price">£{this.state.productPrice} per month</h4>
            <a href="#" onClick={this.addProductToCart.bind(this)} className="medium-button">Add to Bag</a>
          </div>
        );

      } else if (
        (this.state.product?.contract_product === "1" && this.state.product?.contract_show_data_plans === "1") ||
        (this.state.product?.acf?.show_external_deals === "1" || this.state.product?.acf?.show_external_deals)
      ) {

        // Monthly contract and will use data plans, therefore hide normal payment section
        // OR External Deal

        productPriceAndBuy = (
          <div></div>
        );

      }


      const reevooLink = this.state.product.acf.reevoo_link;

      let reevooSection = (
        <div></div>
      );


      if (this.state.reevoo && this.state.reevoo.ratingValue) {
        reevooSection = (
            <a href={reevooLink + "#reviews"} target="_blank" className="reevoo-score-block">
              <div>
                <h2 style={{
                    color: 'rgb(236, 139, 17)',
                    marginBottom: '-4px',
                    fontSize: '28px !important',
                    lineHeight: '36px !important'
                }}>{this.state.reevoo.ratingValue}</h2>
                <h5 className="secondary-text">Out of 10</h5>
              </div>
              <div className="logo-review-count">
                <img style={{ marginTop: '4px'}} src="/img/reevoo-logo.png" alt="reevoo company logo" />
                <h5 className="secondary-text" style={{ marginTop: '2px', textDecoration: 'underline'}}>Read {this.state.reevoo.ratingCount} reviews</h5>
              </div>
            </a>
        );
      }



      // Get external deals for currently set variation, pass into ProductDeals component for display

      let externalDeals = [];

      if (this.state.product.acf.show_external_deals) {
        const selectedVariation = this.state.variations.find(variation => variation.id === this.state.selectedOptions[0].variationID);
        externalDeals = selectedVariation.external_deals;
      }




      return (
        <div className="content-wrap">

          <Helmet>
            <title>{this.state.product.name} - ylo</title>
            <meta name="description" content={this.state.product.short_description} />
            <script type="application/ld+json">{this.state.structeredJSON}</script>
          </Helmet>

          <div className="single-product">

            <div className="product-main">

                <div
                  className="product-image"
                >

                {this.state.productImagesDisplayed.length > 1 &&
                  <a href="#prev" onClick={this.gotoPrevSlide.bind(this)} className="prev-image-arrow" style={{ opacity: this.state.prevImageArrowOpacity}}>
                    <img className="fill-div" src="/img/right-arrow-tall.png" alt="arrow tall" />
                  </a>
                }

                  <div
                    className="image-slider"
                    style={{
                      transform: `translateX(${this.state.translateValue}%)`,
                      transition: 'transform ease-out 0.45s',
                    }}
                  >
                    {
                      this.state.productImagesDisplayed.map((image, i) => (
                        <div key={i} className="image-slide" style={{
                          backgroundImage: `url(${image.src})`,
                          backgroundSize: 'contain',
                          backgroundRepeat: 'no-repeat',
                          backgroundPosition: '50% 50%'
                        }}>
                        </div>
                      ))
                    }
                  </div>


                  {this.state.productImagesDisplayed.length > 1 &&
                    <a href="#next" onClick={this.gotoNextSlide.bind(this)} className="next-image-arrow" style={{ opacity: this.state.nextImageArrowOpacity}}>
                      <img className="fill-div" src="/img/right-arrow-tall.png" alt="arrow tall" />
                    </a>
                  }

                </div>

                <div className="product-info">
                  <h1 style={{ marginBottom: '6px' }}>{this.state.product.name}</h1>
                  <h4 className="secondary-text">{this.productSetOptionsString()}</h4>

                  {this.state.product.on_sale &&
                    <span className="sale-tag" style={{ marginTop: '-4px', marginBottom: '20px' }}>sale</span>
                  }

                  <div className="product-options">
                    {options}
                  </div>

                  {productPriceAndBuy}

                  {reevooSection}

                  <div dangerouslySetInnerHTML={{__html: this.state.product.description}}></div>

                  {this.state.product.acf && this.state.product.acf.product_spec_sheet &&
                    <div className="product-further-info">
                      <a href="#whyylo">
                        <i className="fb-icon-element-1 fb-icon-element fontawesome-icon fa-question-circle far circle-no fusion-text-flow"></i>
                        <span className="text">Why buy with ylo?</span>
                      </a>

                      <a target="_blank" href={this.state.product.acf.product_spec_sheet}>
                        <i className="fb-icon-element-2 fb-icon-element fontawesome-icon fa-file-pdf far circle-no fusion-text-flow" style={{paddingLeft: '2px', paddingRight: '6px'}}></i>
                        <span className="text">Download Full Spec Sheet</span>
                      </a>
                    </div>
                  }

                  {this.state.product.contract_show_data_plans === "1" &&
                    <ProductContractPlans
                      productImage={miniProductImage}
                      productName={this.state.product.name}
                      productPrice={this.state.productPrice}
                      recommendedPlans={this.state.product.acf.recommended_data_plans}
                      urlParams={this.props.match.params}
                      selectedOptions={this.state.selectedOptions}
                      buttonPress={this.addContractToCart}
                    />
                  }

                  {externalDeals.length > 0 &&
                    <ProductDeals
                      productImage={miniProductImage}
                      productName={this.state.product.name}
                      recommendedDeals={this.state.product.acf.recommended_data_plans}
                      urlParams={this.props.match.params}
                      queryParams={this.props.location.search}
                      deals={externalDeals}
                      selectedOptions={this.state.selectedOptions}
                      buttonPress={this.gotoDeal}
                    />
                  }

                </div>

            </div>

            {specs}

            {this.state.product.categories[0].slug === "business-phones" &&
              <div className="content-wrap" style={{ paddingTop: '0px', paddingBottom: '0px' }}>
                <div dangerouslySetInnerHTML={{__html: this.state.productBottomContent}}>
                </div>
              </div>
            }

            <div className="related-products">
              <h2>Similar Products</h2>
              <ul className="product-listings">
                {relatedProducts}
              </ul>
            </div>

          </div>
        </div>
      );

    }

  }

}

export default Product;
