import React from "react";
//import logo from './logo.svg';
import './App.scss';
import {loadStripe} from '@stripe/stripe-js';
import {Elements} from '@stripe/react-stripe-js';
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";

// import ReactGA from 'react-ga';
import {Helmet} from "react-helmet";
import flexibility from "flexibility";

import Header from './components/Header';
import { HeaderContext } from './components/Header';
//import Tabs from './components/Tabs';
import Footer from './components/Footer';
import Home from './components/Home';
import Page from './components/Page';
import SimOnly from './components/SimOnly';
import Broadband from './components/Broadband';
import Category from './components/Category';
//import Tag from './components/Tag';
import Product from './components/Product';
import ProductExtras from './components/ProductExtras';
import Cart from './components/cart-checkout/Cart';
import CheckoutDetails from './components/cart-checkout/CheckoutDetails';
import ContractSignup from './components/cart-checkout/ContractSignup';
import ContractSignupPart2 from './components/cart-checkout/ContractSignupPart2';
import Payment from './components/cart-checkout/Payment';
import OrderReceived from './components/cart-checkout/OrderReceived';
import ContractMoreDetails from './components/cart-checkout/ContractMoreDetails';
import Blog from './components/blog/Blog';
import BlogPost from './components/blog/BlogPost';
import NoMatch from './components/other/NoMatch';

const stripePromise = loadStripe('pk_live_Of0cb9OV3sWAPatuoGXkkzop');



class App extends React.Component {

  //<Tabs />
  //<Route path="/tag/:name" component={Tag} />

  // <Route
  //   path='/checkout/cart'
  //   render={(props) => <Cart {...props} />}
  // />


  constructor(props) {
    super(props);
    this.state = {
    }

  }



  componentDidMount(){

    flexibility(document.body);

    // ReactGA.initialize('UA-150533959-1');
    // ReactGA.pageview(window.location.pathname + window.location.search);
    //gtag('config', 'AW-656816777');

    window.gtag('send', 'page_view', {
        'page_location': window.location.href,
        'page_path': window.location.pathname
    });

  }


  render() {

    // <Route path="/shop/product/:id/:slug" component={ShopProduct} />
    // <Route path="/shop/product/:id" component={ShopProduct} />
    // <Route path="/shop" component={Shop} />

    return (
    <Elements stripe={stripePromise}>
      <Router>
        <div className="wrap">

          <Header>

            <main>
              <Switch>

                <Route exact path="/" render={routeProps => (
                  <HeaderContext.Consumer>
                    {context => (
                      <Page contentType={context} {...routeProps} />
                    )}
                  </HeaderContext.Consumer>
                )} />

                <Route path="/category/:name/:tag" component={Category} />

                <Route path="/category/:name" render={routeProps => (
                  <HeaderContext.Consumer>
                    {context => (
                      <Category contentType={context} {...routeProps} />
                    )}
                  </HeaderContext.Consumer>
                )} />

                <Route path="/product/extras" component={ProductExtras} />

                <Route path="/product/:id/:slug/:variationid/:externalid/:data/:provider" render={routeProps => (
                  <HeaderContext.Consumer>
                    {context => (
                      <Product contentType={context} {...routeProps} />
                    )}
                  </HeaderContext.Consumer>
                )} />

                <Route path="/product/:id/:slug/:variationid/:externalid/:data" render={routeProps => (
                  <HeaderContext.Consumer>
                    {context => (
                      <Product contentType={context} {...routeProps} />
                    )}
                  </HeaderContext.Consumer>
                )} />

                <Route path="/product/:id/:slug/:variationid/:externalid" render={routeProps => (
                  <HeaderContext.Consumer>
                    {context => (
                      <Product contentType={context} {...routeProps} />
                    )}
                  </HeaderContext.Consumer>
                )} />

                <Route path="/product/:id/:slug/:variationid" render={routeProps => (
                  <HeaderContext.Consumer>
                    {context => (
                      <Product contentType={context} {...routeProps} />
                    )}
                  </HeaderContext.Consumer>
                )} />

                <Route path="/product/:id/:slug" render={routeProps => (
                  <HeaderContext.Consumer>
                    {context => (
                      <Product contentType={context} {...routeProps} />
                    )}
                  </HeaderContext.Consumer>
                )} />

                <Route path="/checkout/cart" component={Cart} />
                <Route path="/checkout/details" component={CheckoutDetails} />
                <Route path="/checkout/contract" component={ContractSignup} />
                <Route path="/checkout/contract-step-2" component={ContractSignupPart2} />
                <Route path="/checkout/payment" component={Payment} />
                <Route path="/checkout/order-received" component={OrderReceived} />
                <Route path="/contract/more-details" component={ContractMoreDetails} />
                <Route path="/blog/category/:id" component={Blog} />
                <Route path="/blog/:id/:slug" component={BlogPost} />
                <Route path="/blog" component={Blog} />
                <Route path="/business/:name" component={Page} />
                <Route path="/page/:name" component={Page} />

                <Route path="/:name" render={routeProps => (
                  <HeaderContext.Consumer>
                    {context => (
                      <Page contentType={context} {...routeProps} />
                    )}
                  </HeaderContext.Consumer>
                )} />
                {/* when none of the above match, <NoMatch> will be rendered */}
                <Route component={NoMatch} />
              </Switch>
            </main>

            <Footer />

          </Header>

          { // Assistant component <Assistant /> will go here
          }

        </div>
      </Router>
    </Elements>
    );
  }

}

export default App;
