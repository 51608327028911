import React from "react";
import { Link } from "react-router-dom";
import Spinner from "react-spinkit";
import Select from "react-dropdown-select";
import { ValidatorForm } from 'react-form-validator-core';
import {Helmet} from "react-helmet";

import ValidatedTextInput from "./forms/ValidatedTextInput";
import ValidatedEmailInput from "./forms/ValidatedEmailInput";
import ValidatedNumberInput from "./forms/ValidatedNumberInput";

import * as api from "../../config/api";
import OrderSummary from "./OrderSummary";

const IndustryTypeOptions = [
{ name: 'Accounting', },
{ name: 'Advertising', },
{ name: 'Aerospace', },
{ name: 'Agriculture', },
{ name: 'Architects', },
{ name: 'Automotive', },
{ name: 'Banking', },
{ name: 'Betting', },
{ name: 'Broadcasting', },
{ name: 'Brokerage', },
{ name: 'Building', },
{ name: 'Call Centre', },
{ name: 'Catering', },
{ name: 'Charity', },
{ name: 'Chemical', },
{ name: 'Cleaning Services', },
{ name: 'Construction', },
{ name: 'Consulting', },
{ name: 'Cosmetics', },
{ name: 'Defence', },
{ name: 'Education', },
{ name: 'Electronics', },
{ name: 'Energy', },
{ name: 'Entertainment & Leisure', },
{ name: 'Estate Agent', },
{ name: 'Financial Services', },
{ name: 'Florists', },
{ name: 'Food & Beverages', },
{ name: 'Football Club', },
{ name: 'Funeral Services', },
{ name: 'Government Body', },
{ name: 'Grocery', },
{ name: 'Health & Beauty', },
{ name: 'Health & Fitness', },
{ name: 'Health Care', },
{ name: 'Hospital', },
{ name: 'Insurance', },
{ name: 'Internet Publishing', },
{ name: 'Investment Banking', },
{ name: 'IT Services', },
{ name: 'Legal', },
{ name: 'Logistics', },
{ name: 'Manufacturing', },
{ name: 'Market Research', },
{ name: 'Music', },
{ name: 'Other', },
{ name: 'Opticians', },
{ name: 'Packaging', },
{ name: 'Pharmaceuticals', },
{ name: 'PR / Marketing', },
{ name: 'Property Management', },
{ name: 'Publishing', },
{ name: 'Recruitment', },
{ name: 'Religious Institute', },
{ name: 'Residential', },
{ name: 'Retail & Wholesale', },
{ name: 'Security & Alarms', },
{ name: 'Service', },
{ name: 'Software', },
{ name: 'Solicitor', },
{ name: 'Sports', },
{ name: 'Technology', },
{ name: 'Telecommunications', },
{ name: 'Television', },
{ name: 'Transportation', },
{ name: 'Utilities', },
{ name: 'Veterinary', },
];

class ContractSignup extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      buttonLoading: false,
      accessToken: '',
      pageContent: '',

      contactName: '',
      contactJob: '',
      contactEmail: '',
      contactNumber: '',
      companyName: '',
      companyRegNum: '',
      companyYearsTrading: '',
      companyNumEmployees: '',
      companyIndustryType: '',
      companyAddressLine1: '',
      companyAddressLine2: '',
      companyAddressCity: '',
      companyPostcode: '',
      formSubmitted: false,
      displayError: false,
      order: [],

      buttonText: 'Next Step ->',
      totalCost: 0.00
    }

    this.handleContactNameChange = this.handleContactNameChange.bind(this);
    this.handleContactJobChange = this.handleContactJobChange.bind(this);
    this.handleContactEmailChange = this.handleContactEmailChange.bind(this);
    this.handleContactNumberChange = this.handleContactNumberChange.bind(this);
    this.handleCompanyNameChange = this.handleCompanyNameChange.bind(this);
    this.handleCompanyRegNumChange = this.handleCompanyRegNumChange.bind(this);
    this.handleCompanyYearsTradingChange = this.handleCompanyYearsTradingChange.bind(this);
    this.handleCompanyNumEmployeesChange = this.handleCompanyNumEmployeesChange.bind(this);
    this.handleCompanyIndustryTypeChange = this.handleCompanyIndustryTypeChange.bind(this);
    this.handleCompanyAddressLine1Change = this.handleCompanyAddressLine1Change.bind(this);
    this.handleCompanyAddressLine2Change = this.handleCompanyAddressLine2Change.bind(this);
    this.handleCompanyAddressCityChange = this.handleCompanyAddressCityChange.bind(this);
    this.handleCompanyPostcodeChange = this.handleCompanyPostcodeChange.bind(this);

    this.handleSubmit = this.handleSubmit.bind(this);
  }



  componentDidMount = () => {

    window.scrollTo(0, 0);

    // const cartContents = JSON.parse(localStorage.getItem('cartContents'));
    // if (cartContents) {
    //   this.calcTotal(cartContents);
    // }

    if (localStorage.getItem('cartContents')) {

      let order = JSON.parse(localStorage.getItem('cartContents'));

      console.log(order);

      this.setState({
        order: order
      }, function() {
        this.calcTotal();
      });

    }

  }


  calcTotal = () => {

    let runningTotal = 0.00;
    const orderContents = this.state.order; //orderContents

    if (orderContents) {

      orderContents.forEach((orderItem, index) => {
        runningTotal += parseFloat(orderItem.price);

        if (orderContents.length - 1 === index) {
          this.setState({
            totalCost: runningTotal.toFixed(2)
          });
        }
      });
    }

  }


  handleContactNameChange = (event) => {
    this.setState({contactName: event.target.value});
  }

  handleContactJobChange = (event) => {
    this.setState({contactJob: event.target.value});
  }

  handleContactNumberChange = (event) => {
    this.setState({contactNumber: event.target.value});
  }

  handleContactEmailChange = (event) => {
    this.setState({contactEmail: event.target.value});
  }

  handleCompanyNameChange = (event) => {
    this.setState({companyName: event.target.value});
  }

  handleCompanyRegNumChange = (event) => {
    this.setState({companyRegNum: event.target.value});
  }

  handleCompanyYearsTradingChange = (event) => {
    this.setState({companyYearsTrading: event.target.value});
  }

  handleCompanyNumEmployeesChange = (event) => {
    this.setState({companyNumEmployees: event.target.value});
  }

  handleCompanyIndustryTypeChange = (values) => {
    if (values && values.length > 0) {
      this.setState({companyIndustryType: values[0].name});
    }
  }

  handleCompanyNumEmployeesChange = (event) => {
    this.setState({companyNumEmployees: event.target.value});
  }

  handleCompanyAddressLine1Change = (event) => {
    this.setState({companyAddressLine1: event.target.value});
  }

  handleCompanyAddressLine2Change = (event) => {
    this.setState({companyAddressLine2: event.target.value});
  }

  handleCompanyAddressCityChange = (event) => {
    this.setState({companyAddressCity: event.target.value});
  }

  handleCompanyPostcodeChange = (event) => {
    this.setState({companyPostcode: event.target.value});
  }




  handleSubmit = () => {

    this.setState({displayError: false, buttonLoading: true});


    let fullName = this.state.contactName.split(' '),
    firstName = fullName[0],
    lastName = fullName[fullName.length - 1];

    const cartContents = this.state.order;
    let lineItems = [];

    cartContents.forEach((cartItem, index) => {

      let lineItem = {};

      lineItem.product_id = cartItem.productID;

      if (cartItem.variationID) {
        lineItem.variation_id = cartItem.variationID;
      }

      lineItem.name = cartItem.name;
      lineItem.quantity = cartItem.quantity;
      lineItem.subtotal = cartItem.price;
      lineItem.total = cartItem.price;

      if (cartItem.phoneContract || cartItem.simOnlyContract) {
        lineItem.meta_data = [
          {
            "key": "Plan",
            "value": cartItem.contractPlan + '. ' + cartItem.contractMonthlyData + ' Data. Unlimited Calls & Texts'
          },
          {
            "key": "Monthly Cost",
            "value": cartItem.contractMonthlyCost
          }
        ];
      } else if (cartItem.fibreContract) {
        lineItem.meta_data = [
          {
            "key": "Details",
            "value": cartItem.summary
          },
          {
            "key": "Monthly Cost",
            "value": cartItem.contractMonthlyCost
          }
        ];
      } else if (cartItem.summary) {
        lineItem.meta_data = [
          {
            "key": "Details",
            "value": cartItem.summary
          }
        ];
      }

      lineItems.push(lineItem);

    });


    let metaData = [];

    // if (this.state.companyRegNum || (this.state.companyYearsTrading && this.state.companyYearsTrading != 0)) {
    //
    //   // Must be a contract signup, get additional company details
      metaData = [
        {
          "key": "job_title",
          "value": this.state.contactJob
        },
        {
          "key": "company_reg_number",
          "value": this.state.companyRegNum
        },
        {
          "key": "number_of_years_trading",
          "value": this.state.companyYearsTrading
        },
        {
          "key": "number_of_employees",
          "value": this.state.companyNumEmployees
        },
        {
          "key": "industry_type",
          "value": this.state.companyIndustryType
        }
      ];
    // }


    let data = {
        "status": "pending",
        "payment_method": "bacs",
        "payment_method_title": "Direct Bank Transfer",
        "billing": {
          "first_name": firstName,
          "last_name": lastName,
          "company": this.state.companyName,
          "address_1": this.state.companyAddressLine1,
          "address_2": this.state.companyAddressLine2,
          "city": this.state.companyAddressCity,
          "postcode": this.state.companyPostcode,
          "country": "GB",
          "email": this.state.contactEmail,
          "phone": this.state.contactNumber
        },
        "shipping": {
          "first_name": firstName,
          "last_name": lastName,
          "company": this.state.companyName,
          "address_1": this.state.companyAddressLine1,
          "address_2": this.state.companyAddressLine2,
          "city": this.state.companyAddressCity,
          "postcode": this.state.companyPostcode,
          "country": "GB"
        },
        "line_items": lineItems,
        "shipping_lines": [
          {
            "method_id": "flat_rate",
            "method_title": "Free Delivery",
            "total": "0"
          }
        ],
        "meta_data": metaData,
    };


    //"set_paid": false,

    // If the total cost isn't 0, don't mark for BACS payment, get things ready for a card payment
    // they may be purchasing something in addition to signing up for a contract

    if (this.state.totalCost > 0) {

      data = {
         "status": "pending",
         "billing": {
           "first_name": firstName,
           "last_name": lastName,
           "company": this.state.companyName,
           "address_1": this.state.companyAddressLine1,
           "address_2": this.state.companyAddressLine2,
           "city": this.state.companyAddressCity,
           "postcode": this.state.companyPostcode,
           "country": "GB",
           "email": this.state.contactEmail,
           "phone": this.state.contactNumber
         },
         "shipping": {
           "first_name": firstName,
           "last_name": lastName,
           "company": this.state.companyName,
           "address_1": this.state.companyAddressLine1,
           "address_2": this.state.companyAddressLine2,
           "city": this.state.companyAddressCity,
           "postcode": this.state.companyPostcode,
           "country": "GB"
         },
         "line_items": lineItems,
         "shipping_lines": [
           {
             "method_id": "flat_rate",
             "method_title": "Free Delivery",
             "total": "0"
           }
         ],
         "meta_data": metaData,
     };

    }


    //console.log(data);

    const bodyJSON = JSON.stringify(data);

    return fetch(api.woocommerceURL + 'orders', {
      method: 'POST',
      headers: {
        'Authorization': api.woocommerceAuth,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: bodyJSON
    })
    .then((response) => response.json())
    .then((responseJson) => {

      // console.log('posted order');
      // console.log(responseJson);

      if (responseJson.id && responseJson.status) {

        this.setState({buttonLoading: false });

        this.props.history.push({
          pathname: '/checkout/contract-step-2',
          state: {
            orderID: responseJson.id,
            contactName: this.state.contactName,
            contactEmail: this.state.contactEmail,
            addressLine1: this.state.companyAddressLine1,
            addressLine2: this.state.companyAddressLine2,
            addressCity: this.state.companyAddressCity,
            postcode: this.state.companyPostcode
          }
        });

      } else {

        // handle error
        this.handleOrderError();

      }


    })
    .catch((error) => {
      // handle error
      this.handleOrderError();
    });


  }



  handleOrderError = () => {

    this.setState({
      displayError: true,
      buttonLoading: false
    });

    window.scrollTo(0, 0);

  }



  handleOrderSummaryButton = () => {

    this.refs['contractForm'].submit();

  }




  render() {

    // if (this.state.formSubmitted) {
    //
    //   return (
    //     <div className="content-wrap">
    //
    //       <Helmet>
    //         <title>Sign Up - ylo</title>
    //         <meta name="description" content="We need a few more details to process your order and setup your new contract." />
    //       </Helmet>
    //
    //       <div className="page-intro">
    //         <h1>Sign Up</h1>
    //         <h4>The first of two steps to process your order and setup your new contract.</h4>
    //       </div>
    //
    //       <div className="content-with-sidebar">
    //
    //         <div className="order-confirmation-block">
    //           <h3>Order Received</h3>
    //
    //           <p>Thank you very much for choosing ylo. Your one step closer to a new contract. Our team will now process your order and get back to you with more information in the next few days.</p>
    //         </div>
    //
    //         <OrderSummary />
    //
    //       </div>
    //     </div>
    //   );
    //
    // } else {

      // let submitButton = this.state.buttonLoading ? (
      //   <div className="loading-spinner-button">
      //     <Spinner name="pacman" color="#65c84c" fadeIn="half" />
      //   </div>
      // ) : (
      //   <input type="submit" value={this.state.buttonText} className="medium-button fadeIn" />
      // );
      //
      // <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
      //   {submitButton}
      // </div>

      const topAlert = this.state.displayError ? (
        <div className="alert alert--red">
          Something went wrong, please try again. If the issue presists, call (01604) 946888.
        </div>
      ) : (
        <div className="alert alert--ylo">
          We setup contracts intended for business use in the UK. Please only place an order if you are purchasing for a company or self employed.
        </div>
      );


      return (
        <div className="content-wrap">

          <Helmet>
            <title>Sign Up - ylo</title>
            <meta name="description" content="We need a few more details to process your order and setup your new contract." />
          </Helmet>

          <div className="page-intro">
            <h1>Sign Up</h1>
            <h4>The first of two steps to process your order and setup your new contract.</h4>
          </div>

          <div className="content-with-sidebar">

            <ValidatorForm onSubmit={this.handleSubmit} ref="contractForm" style={{ maxWidth: '500px' }}>

              <div style={{ marginBottom: '10px' }}>
                <h3 style={{ marginBottom: '38px' }}>Your Details</h3>
                {topAlert}
              </div>

              <div>
                <div className="input-group">
                  <div className="input-wrap">
                    <div className="input-label">Your Name</div>
                    <ValidatedTextInput
                      onChange={this.handleContactNameChange}
                      name="contactName"
                      value={this.state.contactName}
                      validators={['required', "matchRegexp:(^[-'a-zA-ZÀ-ÖØ-öø-ſ\\s]+$)"]}
                      errorMessages={['Please provide your name.', 'Ensure you only use letters.']}
                    />
                  </div>

                  <div className="input-wrap">
                    <div className="input-label">Job Title</div>
                    <ValidatedTextInput
                      onChange={this.handleContactJobChange}
                      name="contactJob"
                      value={this.state.contactJob}
                      validators={['required', "matchRegexp:^[-'a-zA-ZÀ-ÖØ-öø-ſ\\s]+$"]}
                      errorMessages={['Please provide your job title.', 'Ensure you only use letters.']}
                    />
                  </div>
                </div>

                <div className="input-group">
                  <div className="input-wrap">
                    <div className="input-label">Contact Number</div>
                    <ValidatedTextInput
                      onChange={this.handleContactNumberChange}
                      name="contactNumber"
                      value={this.state.contactNumber}
                      validators={['required', "matchRegexp:^[0-9 ()+-]+$"]}
                      errorMessages={['Please provide a phone number.', 'Only use numbers.']}
                    />
                  </div>

                  <div className="input-wrap">
                    <div className="input-label">Email</div>
                    <ValidatedEmailInput
                      onChange={this.handleContactEmailChange}
                      name="contactEmail"
                      value={this.state.contactEmail}
                      validators={['required']}
                      errorMessages={['An email address is required.']}
                    />
                  </div>
                </div>

                <h3 style={{marginTop: '20px'}}>Company Details</h3>

                <div className="input-group">
                  <div className="input-wrap">
                    <div className="input-label">Company Name</div>
                    <ValidatedTextInput
                      onChange={this.handleCompanyNameChange}
                      name="companyName"
                      value={this.state.companyName}
                      validators={['required', "matchRegexp:^[-'a-zA-Z0-9À-ÖØ-öø-ſ\\s]+$"]}
                      errorMessages={['A company name is required.', 'Only use letters and numbers.']}
                    />
                  </div>

                  <div className="input-wrap">
                    <div className="input-label">Company Reg Number (Optional)</div>
                    <ValidatedTextInput
                      onChange={this.handleCompanyRegNumChange}
                      name="companyRegNum"
                      value={this.state.companyRegNum}
                      validators={["isNumber"]}
                      errorMessages={['Only use numbers.']}
                    />
                  </div>
                </div>

                <div className="input-group">
                  <div className="input-wrap">
                    <div className="input-label">Number of Years Trading</div>
                    <ValidatedNumberInput
                      onChange={this.handleCompanyYearsTradingChange}
                      name="companyYearsTrading"
                      value={this.state.companyYearsTrading}
                      step="1"
                      min="1"
                      validators={['required', "isNumber", 'minNumber:1']}
                      errorMessages={['Number Required', 'Only use numbers.', 'Minimum of 1 year, round up if under.']}
                    />
                  </div>

                  <div className="input-wrap">
                    <div className="input-label">Number of Employees</div>
                    <ValidatedNumberInput
                      onChange={this.handleCompanyNumEmployeesChange}
                      name="companyNumEmployees"
                      value={this.state.companyNumEmployees}
                      step="1"
                      min="1"
                      validators={['required', "isNumber", 'minNumber:1']}
                      errorMessages={['Number Required', 'Only use numbers.', 'Minimum of 1 year, round up if under.']}
                    />
                  </div>
                </div>


                <div className="input-group" style={{ paddingBottom: '5px', marginBottom: '30px', borderBottom: '1px solid #f1f1f1' }}>
                  <div className="input-wrap">
                    <div className="input-label">Industry Type</div>
                    <Select
                      options={IndustryTypeOptions}
                      valueField="name"
                      labelField="name"
                      clearable={true}
                      onChange={(values) => this.handleCompanyIndustryTypeChange(values)}
                      className="dropdown"
                      color="#121212"
                    />
                  </div>
                </div>


                <div className="input-group">
                  <div className="input-wrap">
                    <div className="input-label">Address Line 1</div>
                    <ValidatedTextInput
                      onChange={this.handleCompanyAddressLine1Change}
                      name="companyAddressLine1"
                      value={this.state.companyAddressLine1}
                      validators={['required', "matchRegexp:^[-'a-zA-Z0-9À-ÖØ-öø-ſ\\s]+$"]}
                      errorMessages={['An address is required.', 'Only use letters and numbers.']}
                    />
                  </div>

                  <div className="input-wrap">
                    <div className="input-label">Address Line 2</div>
                    <ValidatedTextInput
                      onChange={this.handleCompanyAddressLine2Change}
                      name="companyAddressLine2"
                      value={this.state.companyAddressLine2}
                      validators={["matchRegexp:^[-'a-zA-Z0-9À-ÖØ-öø-ſ\\s]+$"]}
                      errorMessages={['Only use letters and numbers.']}
                    />
                  </div>
                </div>

                <div className="input-group">
                  <div className="input-wrap">
                    <div className="input-label">City/Town</div>
                    <ValidatedTextInput
                      onChange={this.handleCompanyAddressCityChange}
                      name="companyAddressCity"
                      value={this.state.companyAddressCity}
                      validators={['required', "matchRegexp:^[-'a-zA-ZÀ-ÖØ-öø-ſ\\s]+$"]}
                      errorMessages={['A city or town is required.', 'Only use letters.']}
                    />
                  </div>

                  <div className="input-wrap">
                    <div className="input-label">Postcode</div>
                    <ValidatedTextInput
                      onChange={this.handleCompanyPostcodeChange}
                      name="companyPostcode"
                      value={this.state.companyPostcode}
                      validators={['required']}
                      errorMessages={['A postcode is required.']}
                    />
                  </div>
                </div>

            </div>

            </ValidatorForm>

            <OrderSummary
              order={this.state.order}
              buttonPress={this.handleOrderSummaryButton}
              buttonLoading={this.state.buttonLoading}
              buttonText={this.state.buttonText}
            />

          </div>
        </div>
      );

    //}


  }

  //<input type="text" onChange={this.handleCompanyIndustryTypeChange} name="companyIndustryType" value={this.state.companyIndustryType} className="txt-input" />

}

export default ContractSignup;
