import React from "react";
import { NavLink } from "react-router-dom";

import * as api from "../../config/api";


class TabsMenu extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      menuItems: [],
    }

    this.getMenu();

  }



  getMenu = () => {


   // Fetch menus from cache, find current menu required

   return fetch(api.cacheURL + 'menus.php', {
     headers: {
       'Accept': 'application/json'
     },
   })
   .then((response) => response.json())
   .then((responseJson) => {

     const currentMenu = responseJson.find(menu => menu.slug == this.props.menuName);

     if (currentMenu && currentMenu.items) {
       this.setState({
         isLoading: false,
         menuItems: currentMenu.items,
       });
     }

   })
   .catch((error) => {
   });


 }


  render() {

    if (this.state.menuItems.length > 0) {

      const menu = this.state.menuItems.map((item) => {

        let menuURL = item.url;

        if (this.props.menuName === 'blog-tabs' && menuURL.includes('/category')) {
          menuURL = '/blog/category/' + item.object_id;
        } else if (menuURL.includes('https://weareylo.com/backend')) {
          menuURL = menuURL.replace('https://weareylo.com/backend', '');
        }

        return (
          <li key={item.ID.toString()}>
            <NavLink exact to={menuURL} activeClassName="selected">{item.title}</NavLink>
          </li>
        );

      });

      return (
        <nav className="nav-tabs">
          <ul>
            {menu}
          </ul>
        </nav>
      );

    } else {

      return (
        <nav className="nav-tabs">
          <ul style={{height: 48}}>
          </ul>
        </nav>
      );

    }

  }

}

export default TabsMenu;
