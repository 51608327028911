import React from "react";
import { Link } from "react-router-dom";
import Spinner from "react-spinkit";

class OrderSummary extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      totalCost: 0.00
    }
  }


  componentDidMount = () => {
    this.calcTotal();
  }


  componentDidUpdate = (prevProps) => {
    if (this.props.order !== prevProps.order) {
      this.calcTotal();
    }
  }


  calcTotal = () => {

    let runningTotal = 0.00;
    const orderContents = this.props.order; //orderContents

    if (orderContents) {

      orderContents.forEach((orderItem, index) => {
        runningTotal += parseFloat(orderItem.price);

        if (orderContents.length - 1 === index) {
          this.setState({
            totalCost: runningTotal.toFixed(2)
          });
        }
      });
    }

  }


  handleButtonPress = (event) => {

    // prevent browser window jump for link
    event.preventDefault();

    // Parent component handles press
    this.props.buttonPress();

  }


  render() {

    const orderContents = this.props.order; //orderContents
    let orderItems = null;

    if (orderContents) {

      orderItems = orderContents.map((orderItem, index) => {

        // const planName = plan.name;
        // const planIcon = plan.icon;

        let itemTotal = (
          <div className="item-total">
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <h4 style={{ marginRight: '6px' }}>£{orderItem.price}</h4><h5 style={{ color: '#a5a5a5', marginBottom: '20px' }}>cost</h5>
            </div>
            <div className="item-quantity" style={{marginTop: '-18px'}}>
              x{orderItem.quantity}
            </div>
          </div>
        );


        if (orderItem.phoneContract) {

          itemTotal = (
            <div>
              <div className="contract-summary">
                <div className="plus-icon">
                  <div className="vertical"></div>
                  <div className="horizontal"></div>
                </div>
                <div className="icon"><img className="fill-div" src={orderItem.contractPlanIcon} alt="mobile network icon" /></div>
                <div className="contract-summary-text">
                  <p>{orderItem.contractMonthlyData} Monthly Data. Unlimited Calls & Texts</p>
                  <p style={{ color: '#a5a5a5' }}>{orderItem.contractPlan}</p>
                </div>
              </div>

              <div className="item-total">

                <div className="item-cost">
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <h4 style={{ marginBottom: '6px', marginRight: '6px' }}>£{orderItem.contractMonthlyCost}</h4><h5 className="secondary-text" style={{ marginBottom: '6px' }}>per month<span className="secondary-text" style={{fontSize: '15px'}}> (exc VAT)</span></h5>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <h4 style={{ marginRight: '6px' }}>£{orderItem.contractUpfrontCost}</h4><h5 className="secondary-text" style={{ marginBottom: '20px' }}>up front<span className="secondary-text" style={{fontSize: '15px'}}> (exc VAT)</span></h5>
                  </div>
                </div>

                <div className="item-quantity">
                  x{orderItem.quantity}
                </div>

              </div>

              <p className="secondary-text" style={{ marginBottom: '20px' }}>The first payment for your contract will only be taken once you digitally sign and accept your contract. A 2Circles contract document will be sent to you by email.</p>
            </div>
          );

        } else if (orderItem.simOnlyContract) {

          itemTotal = (
            <div>
              <div className="item-total">

                <div className="item-cost">
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <h4 style={{ marginBottom: '6px', marginRight: '6px' }}>£{orderItem.contractMonthlyCost}</h4><h5 className="secondary-text" style={{ marginBottom: '6px' }}>per month<span className="secondary-text" style={{fontSize: '15px'}}> (exc VAT)</span></h5>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <h4 style={{ marginRight: '6px' }}>£{orderItem.contractUpfrontCost}</h4><h5 className="secondary-text" style={{ marginBottom: '20px' }}>up front<span className="secondary-text" style={{fontSize: '15px'}}> (exc VAT)</span></h5>
                  </div>
                </div>

                <div className="item-quantity">
                  x{orderItem.quantity}
                </div>

                <p className="secondary-text" style={{ marginBottom: '20px' }}>The first payment for your contract will only be taken once you digitally sign and accept your contract. A 2Circles contract document will be sent to you by email.</p>
              </div>
            </div>
          );

        } else if (orderItem.fibreContract) {

          itemTotal = (
            <div>
              <div className="item-total">

                <div className="item-cost">
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <h4 style={{ marginBottom: '6px', marginRight: '6px' }}>£{orderItem.contractMonthlyCost}</h4><h5 className="secondary-text" style={{ marginBottom: '6px' }}>per month<span className="secondary-text" style={{fontSize: '15px'}}> (exc VAT)</span></h5>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <h4 style={{ marginRight: '6px' }}>£{orderItem.contractUpfrontCost}</h4><h5 className="secondary-text" style={{ marginBottom: '20px' }}>up front<span className="secondary-text" style={{fontSize: '15px'}}> (exc VAT)</span></h5>
                  </div>
                </div>

                <div className="item-quantity">
                  x{orderItem.quantity}
                </div>

                <p className="secondary-text" style={{ marginBottom: '20px' }}>The first payment for your broadband will only be taken once you digitally sign and accept your contract. A 2Circles contract document will be sent to you by email.</p>
              </div>
            </div>
          );

        }

        return (
          <div className="cart-item" key={index.toString()}>

              <div className="item-summary">
                <div className="item-product">
                  {orderItem.image &&
                    <div className="mini-product-image"><img className="fill-div" src={orderItem.image} alt="product" /></div>
                  }
                  <div className="item-summary-text">
                    <h4>{orderItem.name}</h4>
                    <p className="secondary-text" style={{ marginBottom: '0px' }}>{orderItem.summary}</p>
                  </div>
                </div>
              </div>

              {itemTotal}

          </div>
          );

      });

    }



    let primaryButton = this.props.buttonLoading ? (
      <div className="loading-spinner-button">
        <Spinner name="pacman" color="#65c84c" fadeIn="half" />
      </div>
    ) : (
      <a href="#" className="medium-button fadeIn" style={{ minWidth: '160px'}} onClick={this.handleButtonPress}>{this.props.buttonText}</a>
    );


    return (
      <div className="order-summary">
          <h3 style={{ marginBottom: '30px' }}>Your Order</h3>

          {orderItems}

          <div className="order-total">
            <h5 className="secondary-text" style={{ marginBottom: '2px' }}>{this.props.paid ? "Paid" : "Pay Today"}</h5>
            <h3 style={{ marginBottom: '6px' }}>£{this.state.totalCost}</h3>
          </div>

          {this.props.buttonText &&
            <div className="checkout-button-strip">
              <Link className="medium-button medium-button--grey" to={"/checkout/cart"}>Amend Order</Link>
              {primaryButton}
            </div>
          }

      </div>
    );
  }

}

export default OrderSummary;
