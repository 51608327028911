import React from "react";
import { Link } from "react-router-dom";
import Spinner from "react-spinkit";
import {Helmet} from "react-helmet";
import Truncate from 'react-truncate';

import * as api from "../config/api";
import SwitchContent from "../lib/SwitchContent";
//import GetCategoryID from "../lib/GetCategoryID";
import Sidebar from "./sidebar/Sidebar";
import CategoryBanner from "./CategoryBanner";
import BottomScrollListener from 'react-bottom-scroll-listener';


class Category extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      accessToken: '',
      allCategories: [],
      products: [],
      productsDisplayed: [],
      tagApplied: false,
      info: {"name": "", "description": ""},
      setNumber: 1,
      pageCount: 1,
      isLoadingMore: false,
      filters: {
        dealsType: 'Pay Monthly',
        monthCost: {},
        upfrontCost: {},
        monthlyData: '',
        totalCost: {}
      }
    }
  }

  componentDidMount = () => {
    window.scrollTo(0, 0);
    this.getCategory();
  }

  componentDidUpdate = (prevProps, prevState) => {

    if (
        prevProps.match.params.name !== this.props.match.params.name
        && this.props.match.params.name
    ) {

      window.scrollTo(0, 0);

      this.setState({
        isLoading: true,
        products: [],
        productsDisplayed: [],
        pageCount: 1,
        tagApplied: false
      });

      this.getCategory();

    } else if (
        prevProps.match.params.tag !== this.props.match.params.tag
        && this.props.match.params.tag
    ) {

      window.scrollTo(0, 0);

      this.setState({
        isLoading: true,
        products: [],
        productsDisplayed: [],
        pageCount: 1,
        tagApplied: true
      });

      this.getTag();
    }

  }

  getCategory = () => {

   // Find Matching Category with ID and Info

   return fetch(api.cacheURL + 'categories.php', {
      headers: {
        'Accept': 'application/json'
      }
   })
   .then((response) => response.json())
   .then((responseJson) => {

       if (responseJson) {

         const currentCategory = responseJson.find(category => category.slug == this.props.match.params.name);

         if (currentCategory) {

           // Reformat the name correctly
           currentCategory["name"] = currentCategory["name"].replace('&amp;', '&');

           this.setState({
             info: currentCategory
           }, function(){
              this.fetchProducts();
           });

         }

       }

   })
   .catch((error) => {

     console.log(error);

     // Show blank page, error getting categories
     this.setState({
       isLoading: false
     });

   });

 }

 getTag = () => {

    // Find Matching Tag with ID and Info

    //const categoryID = GetCategoryID(this.props.match.params.name);
    return fetch(api.woocommerceURL + 'products/tags?per_page=60', {
    headers: {
      'Accept': 'application/json',
      'Authorization': api.woocommerceAuth,
    },
    })
    .then((response) => response.json())
    .then((responseJson) => {

      if (responseJson) {

        const currentTag = responseJson.find(tag => tag.slug == this.props.match.params.tag);

        if (currentTag) {

          // Reformat the name correctly
          currentTag["name"] = currentTag["name"].replace('&amp;', '&');

          this.setState({
            info: currentTag
          }, function(){
             this.fetchProducts();
          });

        }

      }

    })
    .catch((error) => {

    console.log(error);

    // Show blank page, error getting tags
    this.setState({
      isLoading: false
    });

    });
 }

 fetchProducts = () => {

   // const categoires = ["contract-phones", "sim-free-phones", "sim-only"];

   // Calc offset if page greater than initial (1), since no offsetting is 0
   // we reduce the page count by 1 in the calc e.g. 2 - 1 * 20 = offset results by 20
   let offset = this.state.pageCount > 1 ? '&offset=' + ((this.state.pageCount - 1) * 20) : '';

   return fetch(api.cacheURL + 'items-by-taxonomy-id.php?id=' + this.state.info.id + offset, {
     headers: {
       'Accept': 'application/json'
     }
   })
   .then((response) => response.json())
   .then((responseJson) => {

    if (responseJson?.error) {
       this.setState({
         isLoading: false,
         isLoadingMore: false
       });
       return;
    }

    if (this.props.match.params.tag) {

        // They've gone straight to a link with a tag, handle it
        this.setState(prevState => ({
         products: [...prevState.products, ...responseJson],
         productsDisplayed: [],
         tagApplied: true,
         pageCount: prevState.pageCount + 1
        }), () => this.filterProducts(this.props.match.params.tag));

    } else {

        this.setState(prevState => ({
         isLoading: false,
         products: [...prevState.products, ...responseJson],
         productsDisplayed: [...prevState.productsDisplayed, ...responseJson],
         pageCount: prevState.pageCount + 1,
         isLoadingMore: false
        }));

    }

   })
   .catch((error) => {

     console.log(error);

     this.setState({
       isLoading: false,
       isLoadingMore: false
     });

   });

 }



 removeFilter = () => {

   this.setState({
     isLoading: false,
     productsDisplayed: this.state.products,
     tagApplied: false
   });

 }



 shuffleArray = (array) => {

   let m = array.length, t, i;

   // While there remain elements to shuffle…
   while (m) {

     // Pick a remaining element…
     i = Math.floor(Math.random() * m--);

     // And swap it with the current element.
     t = array[m];
     array[m] = array[i];
     array[i] = t;
   }

   return array;

 }



 filterProducts = (currTag) => {


   let productsFiltered = [];

   if (currTag === "o2" || currTag === "ee" || currTag === "vodafone") {

     const allProducts = [...this.state.products];
     const shuffledProducts = this.shuffleArray(allProducts);

     this.setState({
       isLoading: false,
       isLoadingMore: false,
       productsDisplayed: shuffledProducts,
     });

   } else {

     this.state.products.forEach((product, index) => {

       let categoryMatched = false;

       // See if "currTag" provided in url is infact subcategory, if so add
       let sc = 0;
       for (sc = 0; sc < product.categories.length; sc++) {
         if (product.categories[sc].slug === currTag) {
           productsFiltered.push(product);
           categoryMatched = true;
         }
       }

       // Not subcategory, check against tags and add product
       if (!categoryMatched) {
         let t = 0;
         for (t = 0; t < product.tags.length; t++) {
           if (product.tags[t].slug === currTag) {
             productsFiltered.push(product);
           }
         }
       }

       if (this.state.products.length - 1 === index) {

         this.setState(prevState => ({
           isLoading: false,
           productsDisplayed: [...prevState.productsDisplayed, ...productsFiltered],
           isLoadingMore: false
         }));

       }

     });

   }



 }



 getCompanyLogo = (company) => {

   let filteredCompanyName = company.replace(/\s/g, "-");
   filteredCompanyName = filteredCompanyName.toLowerCase();

   return "/img/companies/" + filteredCompanyName + ".png";

   // switch(company.toLowerCase()) {
   //   case "mobiles.co.uk":
   //     return "/img/companies/mobiles-co-uk.png";
   //     break;
   //   case "the carphone warehouse":
   //     return "/img/companies/the-carphone-warehouse.png";
   //     break;
   //   case "id mobile":
   //     return "/img/companies/id-mobile.png";
   //     break;
   //   default:
   //     return "/img/companies/o2.png";
   // }

 }



 findBestDeal = (deals) => {

   const dealsCopy = [...deals];

   let monthlyDataMin = 500;
   let monthlyDataMax = 500000;
   let monthlyDataUnlimited = false;

   switch(this.state.filters.monthlyData)
   {
      case '1GB':
        monthlyDataMin = 500;
        monthlyDataMax = 5000;
      break;
      case '5GB':
       monthlyDataMin = 5000;
       monthlyDataMax = 10000;
      break;
      case '10GB':
       monthlyDataMin = 10000;
       monthlyDataMax = 20000;
      break;
      case '20GB':
       monthlyDataMin = 20000;
       monthlyDataMax = 50000;
      break;
      case '50GB':
       monthlyDataMin = 50000;
       monthlyDataMax = 100000;
      break;
      case '100GB':
       monthlyDataMin = 100000;
       monthlyDataMax = 500000;
      break;
      case 'Unlimited':
       monthlyDataUnlimited = true;
      break;
   }


   // Set output based on deals type -----------------------------

   if (this.state.filters.dealsType === 'Pay As You Go') {

     for (let d = 0; d < dealsCopy.length; d++) {

       if (dealsCopy[d]["month_cost"] && dealsCopy[d]["month_cost"] === "0.00") {

         if (this.state.filters.totalCost.value) {

           let totalCost = parseFloat(this.state.filters.totalCost.value);
           let totalCostType = this.state.filters.totalCost.type;

           if (
             (parseFloat(dealsCopy[d]["initial_cost"]) <= totalCost && totalCostType === 'upto') ||
             (parseFloat(dealsCopy[d]["initial_cost"]) > totalCost && totalCostType === 'over')
           ) {
             return dealsCopy[d];
           }

         } else {
           return dealsCopy[d];
         }

       }

     }

   } else if (this.state.filters.dealsType === 'Sim Free') {

     for (let d = 0; d < dealsCopy.length; d++) {

       if (!dealsCopy[d]["month_cost"]) {

         if (this.state.filters.totalCost.value) {

           let totalCost = parseFloat(this.state.filters.totalCost.value);
           let totalCostType = this.state.filters.totalCost.type;

           if (
             (parseFloat(dealsCopy[d]["initial_cost"]) <= totalCost && totalCostType === 'upto') ||
             (parseFloat(dealsCopy[d]["initial_cost"]) > totalCost && totalCostType === 'over')
           ) {
             return dealsCopy[d];
           }

         } else {
           return dealsCopy[d];
         }

       }

     }

   } else {

     let maxMonthCost = this.state.filters.monthCost.value ? parseFloat(this.state.filters.monthCost.value) : 60;
     let maxUpfrontCost = this.state.filters.upfrontCost.value ? parseFloat(this.state.filters.upfrontCost.value) : 100;

     let monthCostType = this.state.filters.monthCost.type ? this.state.filters.monthCost.type : '';
     let upfrontCostType = this.state.filters.upfrontCost.type ? this.state.filters.upfrontCost.type : '';


     const dealsOrderedByMonthlyPrice = dealsCopy.sort(function (a, b) {
         return parseFloat(a.month_cost) - parseFloat(b.month_cost);
     });


     let dealsOrderedByPrice = [];
     dealsOrderedByMonthlyPrice.forEach((deal, i) => {

       if (
           (deal.month_cost &&
           (monthCostType === 'over' && parseFloat(deal.month_cost) > maxMonthCost) ||
           parseFloat(deal.month_cost) <= maxMonthCost)
           &&
           ((upfrontCostType === 'over' && parseFloat(deal.initial_cost) > maxUpfrontCost) ||
           parseFloat(deal.initial_cost) <= maxUpfrontCost)
       ) {

           const dataAllowanceNumber = parseInt(deal.inc_data);

           if (this.state.filters.monthlyData && ((monthlyDataUnlimited && deal.inc_data === "Unlimited") ||
             (dataAllowanceNumber >= monthlyDataMin && dataAllowanceNumber <= monthlyDataMax))
           ) {
             dealsOrderedByPrice.unshift(deal);
           } else if (!this.state.filters.monthlyData) {
             dealsOrderedByPrice.unshift(deal);
           }

       } else {

         if (!this.state.filters.monthCost.value && !this.state.filters.upfrontCost.value) {
           // Price Filtering not yet active, keep it in the mix, just lower down in priority
           dealsOrderedByPrice.push(deal);
         }

       }

     });

     return dealsOrderedByPrice[0];

   }

   // --------------------------------------------------------

 }



 reorderProducts = (order) => {

   // default original order
   let orderedProducts = [...this.state.products];

   if (order === "low to high") {

    orderedProducts = this.state.productsDisplayed.sort((a, b) => {
      const price = parseFloat(a.price);
      const salePrice = parseFloat(a.sale_price);

      const priceB = parseFloat(b.price);
      const salePriceB = parseFloat(b.sale_price);

      if (salePrice && salePriceB) {
        return salePrice - salePriceB;
      } else if (salePrice) {
        return salePrice - priceB;
      } else if (salePriceB) {
        return price - salePriceB;
      } else {
        return price - priceB;
      }
    });

  } else if (order === "high to low") {

    orderedProducts = this.state.productsDisplayed.sort((a, b) => {
      const price = parseFloat(a.price);
      const salePrice = parseFloat(a.sale_price);

      const priceB = parseFloat(b.price);
      const salePriceB = parseFloat(b.sale_price);

      if (salePrice && salePriceB) {
        return salePriceB - salePrice;
      } else if (salePrice) {
        return salePriceB - price;
      } else if (salePriceB) {
        return priceB - salePrice;
      } else {
        return priceB - price;
      }
    });

  }

  this.setState({
    productsDisplayed: orderedProducts
  });

 }



 updateFiltering = (filters) => {

   // Set new filtering, effecting products shown and any external deals shown for products

   this.setState({
     filters: filters
   });

   // Change order such as price low to high
   this.reorderProducts(filters.order);

 }


 renderProducts = () => {

   return this.state.productsDisplayed.map((product) => {

     const productTypePhone = product.categories.find(category => category.slug == 'phones');

     if (productTypePhone) {
       return this.renderPhone(product);
     } else {
       return this.renderProduct(product);
     }

   });

 }


 renderPhone = (product) => {

     let productName = product.name;
     let productVariation = product.product_variations[0];

     if (productVariation) {

       // Set the best deal as the recommended offer ------
       let deal = this.findBestDeal(productVariation.external_deals);
       // --------------


       let dealType = 'Sim Free';
       if (deal && deal.month_cost && deal.month_cost === '0.00') {
         dealType = 'Pay As You Go';
       } else if (deal && deal.month_cost && deal.month_cost !== '0.00') {
         dealType = 'Pay Monthly';
       }

       if (deal && this.state.filters.dealsType === dealType) {

         let productEdition = productVariation.attributes[0].option;

         const retailerName = deal.retailer;
         const retailerLogoSrc = this.getCompanyLogo(deal.retailer);

         let retailerLogo = (
           <img className={"deal-logo" + " deal-logo--" + retailerName.replace(/\s/g, "")} src={retailerLogoSrc} alt={retailerName} />
         );


         // Handle Extras and Gifts

         // BREAKDOWN:

         // product["Telcos:deal_extras_json"].groups
         // foreach -> extraGroup
         // extraGroup["Bundled Products"] or extraGroup["Entertainment Offers"]
         // foreach -> extra
         // extra["Product"][0] or extra["Inclusive Entertainment"][0]

         //{
           // desc: "Nintendo Switch is a breakthrough home video game console. It not only connects to a TV at home, but it also instantly transforms into a…"
           // groupingId: "P87596"
           // id: "57658"
           // img_url: "http://media.bigupdata.co.uk/2020-10-15_15-35-24_img_product_image_main_large1_product_edition0000087596.png"
           // title: "Nintendo Switch 32GB Blue and Red"
         // }

         // Inclusive Entertainment example
         //{id: "38242", groupingId: "M4", title: "Disney+ Subscription", desc: "Disney+ Subscription - see website for full terms and conditions."}

         // NOTE: "img_url" not always present

         let extras = null;
         if (deal.extras) {

           const extrasJson = JSON.parse(deal.extras);

           if (extrasJson.groups) {

             extras = extrasJson.groups.map((extraGroup) => {

               if (extraGroup["Bundled Products"]) {

                 return (
                   <div className="product-deal-extra" key={extraGroup.id}>
                     <h6>Free Gift</h6>
                     {
                       extraGroup["Bundled Products"].map((extra, i) => {
                         if (extra["Product"][0].title === "Sony Playstation 5 1TB White + FIFA 21 (1TB White)") {
                           return (
                             <h5 key={i.toString()}>FIFA 21 (PS5)</h5>
                           )
                         } else if (extra["Product"][0].title === "Microsoft Xbox Series X White 1TB + FIFA 21") {
                           return (
                             <h5 key={i.toString()}>FIFA 21 (Xbox Series X)</h5>
                           )
                         } else {
                           return (
                             <h5 key={i.toString()}>{extra["Product"][0].title}</h5>
                           )
                         }
                       })
                     }
                   </div>
                 );

               } else if (extraGroup["Entertainment Offers"]) {

                 return (
                   <div className="product-deal-extra" key={extraGroup.id}>
                     <h6>Included Free</h6>
                     {
                       extraGroup["Entertainment Offers"].map((extra, i) => {
                         return (
                           <h5 key={i.toString()}>
                             <Truncate lines={1} ellipsis={<span>...</span>}>
                                {extra["Inclusive Entertainment"][0].title}
                              </Truncate>
                           </h5>
                         )
                       })
                     }
                   </div>
                 );

               }

             });

           }

         }


         // Check type of product to define presentation

         if (deal.month_cost && deal.month_cost !== '0.00') {

           // Names and Logo of Network
           const networkName = deal.network;
           const networkLogoSrc = this.getCompanyLogo(deal.network);

           const dataAllowanceNumber = parseInt(deal.inc_data);
           let dataAllowance = deal.inc_data;

           if (dataAllowanceNumber >= 1000) {
             dataAllowance = (dataAllowanceNumber / 1000) + 'GB';
           } else if (dataAllowanceNumber < 1000) {
             dataAllowance = dataAllowanceNumber + 'MB';
           }

           // let bundledPlan = false;
           // if (deal.month_cost === "0.00") {
           //   // Label Deals with Bundled Plan that are Upfront Cost only initially
           //   // With some inclusive data, minutes etc
           //   bundledPlan = true;
           // }

           let incMinutesTexts = (
             <React.Fragment>
               <div className="detail">{deal.inc_minutes} Minutes</div>
               <div className="detail">{deal.inc_texts} Texts</div>
             </React.Fragment>
           );

           if (deal.inc_minutes === "Unlimited" && deal.inc_texts === "Unlimited") {
             incMinutesTexts = (
               <div className="detail">Unlimited Minutes & Texts</div>
             );
           }

           //const priceIncContract = parseFloat(Math.ceil(product.price*1)/1 - 0.01, 2) + 9.00;
           let pricingText = (
             <div>
               <span className="price-block price-block--monthly"><h4 className="price">£{deal.month_cost}</h4> <span className="under-price-info">per month</span></span>
               <span className="price-block"><h4 className="price">£{deal.initial_cost}</h4> <span className="under-price-info">upfront</span></span>
             </div>
           );


 // company_id: "17"
 // logo_url: "http://media.bigupdata.co.uk/img_company_logo_company0000000017.png"
 // name: "Mobiles.co.uk"
 // terms_url: "https://www.mobiles.co.uk/t_and_c.html"

 // Telcos:network_details_json: Object
 // company_id: "85"
 // coverage_url: "https://www.idmobile.co.uk/help-and-advice/uk-coverage-checker"
 // description: "iD provides advanced 4G coverage to 98% of the population, so customers get the fastest 4G speeds in the UK. Customers can choose between …"
 // logo_url: "http://media.bigupdata.co.uk/img_company_logo_large1_company0000000085.png"
 // name: "iD Mobile"
 // terms_url: "https://www.idmobile.co.uk/legal/terms-and-conditions"


           return (
             <li className="product" key={product.id.toString()}>

               <div>
                 <Link className="product-image" to={"/product/" + product.id + "/" + product.slug}>
                   <img src={productVariation.image.src} alt={productName} />
                 </Link>

                 <h3>{productName}</h3>
                 <h5 className="secondary-text">{productEdition}</h5>

                 <div className="tariff-details">
                   <div className="detail">{dataAllowance} Data</div>
                   {incMinutesTexts}
                 </div>
               </div>

               <div>

                 <div>
                   {extras}
                 </div>

                 <div className="price-view">
                   {pricingText}
                 </div>

                 <div className="product-deal-buy">

                   <div>
                     <a href={deal.deep_link} target="_blank" className="medium-button">Get The Deal</a>
                   </div>

                   <div className="deal-logos">
                     {retailerLogo}
                     {networkName !== retailerName &&
                       <img style={{marginLeft: '15px'}} className={"deal-logo" + " deal-logo--" + networkName.replace(/\s/g, "")} src={networkLogoSrc} alt={networkName} />
                     }
                   </div>

                 </div>

                 <div className="product-more">
                   <Link className="medium-button medium-button--outline" to={"/product/" + product.id + "/" + product.slug}>View All Deals</Link>
                 </div>

               </div>

             </li>
           );


         } else {


           let pricingText = (
             <div>
               <span className="price-block"><h4 className="price">£{deal.initial_cost}</h4> <span className="under-price-info">total</span></span>
             </div>
           );

           return (
             <li className="product" key={product.id.toString()}>

               <div>
                 <Link className="product-image" to={"/product/" + product.id + "/" + product.slug}>
                   <img src={productVariation.image.src} alt={productName} />
                 </Link>

                 <h3>{productName}</h3>

                 <div className="tariff-details">
                   <div className="detail">{productEdition}</div>
                   {(deal.inc_data || deal.inc_minutes) &&
                     <div className="detail">Pay As You Go</div>
                   }
                   <div className="detail">{deal.tariff}</div>
                 </div>
               </div>

               <div>
                 <div className="price-view">
                   {pricingText}
                 </div>

                 <a className="medium-button" href={deal.deep_link} target="_blank">Get the Deal</a>

                 <div className="product-more">
                   <Link className="medium-button medium-button--outline" to={"/product/" + product.id + "/" + product.slug}>View All Deals</Link>
                 </div>
               </div>

             </li>
           );

         } // end of type of deal check

       } // end of deal check

     } // end of variaiton check

 }



 renderProduct = (product) => {

     let pricingText = '';
     if (product.price) {
         pricingText = (
           <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}><h4 className="price">£{product.price}</h4></div>
         );
     }

   if (this.state.info.name === 'Handsets & Contracts') {
     const priceIncContract = parseFloat(Math.ceil(product.price*1)/1 - 0.01, 2) + 9.00;
     pricingText = (
       <span><h4 className="price">From £{priceIncContract.toFixed(2)}</h4> <span className="under-price-info">per month</span><span style={{fontSize: '14px', color: '#707070'}}> (exc VAT)</span></span>
     );
   }

   return (
     <li className="product" key={product.id.toString()}>

       {product.on_sale &&
          <div className="sale-tag">sale</div>
       }

       <Link to={"/product/" + product.id + "/" + product.slug} className="product-image"><img src={product.images[0].src} alt={product.images[0].name} /></Link>

       <h3>{product.name}</h3>

       <div className="price-view" style={{ marginTop: this.state.info.name === 'Handsets & Contracts' ? '16px' : '8px'}}>
         {pricingText}
       </div>

     </li>
   );

 }




 handleNearBottom = () => {
   this.setState({ isLoadingMore: true });
   this.fetchProducts();
 }



  render() {

    //<h1 className="category-title">Phones</h1>

    if (this.state.isLoading) {

      return (
        <div className="loading-spinner">
          <Spinner name="pacman" color="#65c84c" fadeIn="half" />
        </div>
      );

    } else {

      //let categoryName = this.props.match.params.name.charAt(0).toUpperCase() + this.props.match.params.name.slice(1);
      // if (categoryName === 'Phones') {
      //   categoryName = 'Handsets & Contracts';
      // }

      return (
        <div className="content-wrap content-wrap--category">

          <Helmet>
            <title>{this.state.info.name} - ylo</title>
            <meta name="description" content={this.state.info.description} />
          </Helmet>

          <div className="category-container">

            {this.state.info.image &&
              <div className="category-banner" style={{ backgroundImage: 'url(' + this.state.info.image.src + ')' }}>
                <h1>{this.state.info.name}</h1>
                <h4>{this.state.info.description}</h4>
              </div>
            }

            <div className="category-content">

              <div className="category-sidebar">
                <Sidebar showSummary={this.state.info.image ? false : true} info={this.state.info} filterChanged={this.updateFiltering} />
              </div>

              <div className="category-products">

                  {this.state.info.acf.display_banner && !this.props.match?.params?.tag &&
                      <CategoryBanner
                          colour={this.state.info.acf.banner_colour}
                          content={this.state.info.acf.banner_content}
                      />
                  }

                <div className="columns-wrap" style={{padding: '0px'}}>
                  <ul className="product-listings">
                    {this.renderProducts()}
                    {this.state.productsDisplayed.length === 0 &&
                      <li>
                        <h3>No Results</h3>
                      </li>
                    }
                  </ul>
                </div>
              </div>

            </div>


            {this.state.isLoadingMore &&
              <div className="loading-spinner loading-more-spinner">
                <Spinner name="pacman" color="#65c84c" fadeIn="half" />
              </div>
            }

            <BottomScrollListener offset={875} debounce={500} onBottom={this.handleNearBottom} />

          </div>
        </div>
      );

    }

  }

}

export default Category;
