import React from 'react';
import { ValidatorComponent } from 'react-form-validator-core';

class ValidatedTextArea extends ValidatorComponent {

    render() {
        const { errorMessages, validators, requiredError, validatorListener, ...rest } = this.props;

        return (
            <div>
                <textarea
                    {...rest}
                    className="txt-area"
                    ref={(r) => { this.textarea = r; }}
                >
                </textarea>
                {this.errorText()}
            </div>
        );
    }

    errorText() {
        const { isValid } = this.state;

        if (isValid) {
            return null;
        }

        return (
            <div className="alert alert--black alert--form">
                {this.getErrorMessage()}
            </div>
        );
    }
}

export default ValidatedTextArea;
