import React from "react";
import { NavLink } from "react-router-dom";

import * as api from "../../config/api";


class SidebarSubMenu extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      menuItems: [],
    }
  }


  componentDidMount() {
      this.getMenu();
  }


  getMenu = () => {


   // Fetch menus from cache, find current menu required

   return fetch(api.cacheURL + 'menus.php', {
     headers: {
       'Accept': 'application/json'
     },
   })
   .then((response) => response.json())
   .then((responseJson) => {

     const currentMenu = responseJson.find(menu => menu.slug == this.props.menuName);

     if (currentMenu && currentMenu.items) {
       this.setState({
         isLoading: false,
         menuItems: currentMenu.items,
       });
     }

   })
   .catch((error) => {
   });


 }

 parseMenuURL = (url, object_id) => {
     if (url.includes('https://weareylo.com/backend/blog/category')) {
       url = '/blog/category/' + object_id;
     } else if (url.includes('https://weareylo.com/backend')) {
       url = url.replace('https://weareylo.com/backend', '');
     }

     return url;
 }

  render() {

    if (this.state.menuItems.length > 0) {

      const menu = this.state.menuItems.map((item) => {
        return (
          <li key={item.ID.toString()}>
            <NavLink exact to={this.parseMenuURL(item.url, item.object_id)} className="sidebar-link" activeClassName="sidebar-link--active">{item.title}</NavLink>
            {item?.child_items &&
                item.child_items.map((item) => (
                    <NavLink key={item.ID.toString()} exact to={this.parseMenuURL(item.url, item.object_id)} className="sidebar-link sidebar-link--child" activeClassName="sidebar-link--active">{item.title}</NavLink>
                ))
            }
          </li>
        );
      });

      return (
        <div className={this.props.filterOpen ? "sidebar-block sidebar-block--show" : "sidebar-block"}>
          <div className="option-label"><h4>Categories</h4></div>
            <ul style={{marginBottom: 24}}>
              {menu}
            </ul>
        </div>
      );

    } else {

      return (
        <div>
        </div>
      );

    }

  }

}

export default SidebarSubMenu;
