import React from "react";
import { Link } from "react-router-dom";

class Footer extends React.Component {

  constructor(props) {
    super(props);
  }

  currentYear = () => {
    const currentDateTime = new Date();
    return currentDateTime.getFullYear();
  }

  render() {
    return (

      <footer>
      <div className="footer-inner">
          <div className="footer-ylo-group"><span>Made by</span><img src="/img/ylo-text-only-black.png" alt="ylo group" style={{ height: '50px'}} /></div>
          <p>© ylo Ltd. {this.currentYear()}.<br />
          ylo is registered trademark of ylo Ltd<br />
          Registered in the UK, company number 10768906<br /></p>
          <p className="social-icons">
            <a href="https://x.com/yloenergy" target="_blank" style={{display: "inline-block", marginLeft:5, marginRight:5}}>
              <img src="/img/social-icon-twitter.png" alt="X Logo" style={{ height: "17px", marginTop: "-2px", marginRight: "6px" }} />
            </a>
            <a href="https://www.instagram.com/yloenergy" target="_blank" style={{display: "inline-block", marginLeft:5, marginRight:5}}>
              <i className="fb-icon-element-4 fb-icon-element fontawesome-icon fa-instagram fab circle-no" style={{fontSize:20}}></i>
            </a>
            <a href="http://www.linkedin.com/company/yloenergy" target="_blank" style={{display: "inline-block", marginLeft:5, marginRight:5}}>
              <i className="fb-icon-element-5 fb-icon-element fontawesome-icon fa-linkedin fab circle-no" style={{fontSize:20}}></i>
            </a>
          </p>

          <p>85 Great Portland Street - London - W1W 7LT</p>
          <p><Link to="/about">About</Link> | <Link to="/page/terms/">Terms</Link> | <Link to="/page/privacy/">Privacy</Link></p>
      </div>
      </footer>
    );
  }

}

export default Footer;
