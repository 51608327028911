import { Buffer } from "buffer";

// API URLs for Backend

export const yloURL = 'https://ylo.io/api/';

export const mainURL = 'https://yloenergy.com/api/';
export const cacheURL = 'https://yloenergy.com/cached-json/';

export const wordpressURL = 'https://yloenergy.com/backend/wp-json/wp/v2/';
export const wordpressMenuURL = 'https://yloenergy.com/backend/wp-json/';

export const woocommerceURL = 'https://ylotelecom.com/backend/wp-json/wc/v3/';
export const woocommerceAuth = 'Basic ' + Buffer.from("ck_ef7650c428bc3e34815b2bef72fd092073184e5a:cs_38e1921e528d4b9a5896eac2af985f122d8aa9d0").toString('base64');

