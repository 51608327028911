import React from "react";
import { Link } from "react-router-dom";
import Spinner from "react-spinkit";
import Select from "react-dropdown-select";
import { ValidatorForm } from 'react-form-validator-core';
import {Helmet} from "react-helmet";

import ValidatedTextInput from "./forms/ValidatedTextInput";
import ValidatedEmailInput from "./forms/ValidatedEmailInput";
import ValidatedNumberInput from "./forms/ValidatedNumberInput";

import * as api from "../../config/api";
import OrderSummary from "./OrderSummary";

class ContractSignupPart2 extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      buttonLoading: false,
      accessToken: '',
      pageContent: '',

      orderNum: '',
      bankingNameOnAcc: '',
      bankingAccNum: '',
      bankingSortcode1: '',
      bankingSortcode2: '',
      bankingSortcode3: '',
      pac: '',
      displayError: false,
      order: [],

      buttonText: 'Send Details ->',
      totalCost: 0.00
    }

    this.sortCode2 = React.createRef();
    this.sortCode3 = React.createRef();

    this.handleOrderNumChange = this.handleOrderNumChange.bind(this);
    this.handleBankingNameOnAccChange = this.handleBankingNameOnAccChange.bind(this);
    this.handleBankingAccNumChange = this.handleBankingAccNumChange.bind(this);
    this.handleBankingSortcode1Change = this.handleBankingSortcode1Change.bind(this);
    this.handleBankingSortcode2Change = this.handleBankingSortcode2Change.bind(this);
    this.handleBankingSortcode3Change = this.handleBankingSortcode3Change.bind(this);
    this.handlePACChange = this.handlePACChange.bind(this);

    this.handleSubmit = this.handleSubmit.bind(this);

  }



  componentDidMount = () => {

    window.scrollTo(0, 0);

    if (localStorage.getItem('cartContents')) {

      let order = JSON.parse(localStorage.getItem('cartContents'));

      this.setState({
        order: order
      }, function() {
        this.calcTotal();
      });

    }

  }


  calcTotal = () => {

    let runningTotal = 0.00;
    const orderContents = this.state.order; //orderContents

    if (orderContents) {

      orderContents.forEach((orderItem, index) => {
        runningTotal += parseFloat(orderItem.price);

        if (orderContents.length - 1 === index) {
          this.setState({
            totalCost: runningTotal.toFixed(2)
          });
        }
      });
    }

  }



  handleOrderNumChange = (event) => {
    this.setState({orderNum: event.target.value});
  }

  handleBankingNameOnAccChange = (event) => {
    this.setState({bankingNameOnAcc: event.target.value});
  }

  handleBankingAccNumChange = (event) => {
    this.setState({bankingAccNum: event.target.value});
  }

  handleBankingSortcode1Change = (event) => {
    this.setState({bankingSortcode1: event.target.value});

    if (event.target.value.length === 2) {
      this.sortCode2.current.focus();
    }
  }

  handleBankingSortcode2Change = (event) => {
    this.setState({bankingSortcode2: event.target.value});

    if (event.target.value.length === 2) {
      this.sortCode3.current.focus();
    }
  }

  handleBankingSortcode3Change = (event) => {
    this.setState({bankingSortcode3: event.target.value});
  }


  handlePACChange = (event) => {
    this.setState({pac: event.target.value});
  }


  handleKeepNumberOptionSelected = (option, event) => {

    // prevent browser window jump for link
    event.preventDefault();

    this.setState({keepNumber: option});

  }



  handleSubmit = () => {

    this.setState({buttonLoading: true});

    // Update order created in first step with banking and PAC info

    // Get state from step 1 passed in location props when moving to step 2
    let contractProps = {};
    if (this.props.location.state) {
      contractProps = this.props.location.state;
    }


    let pacCode = 'Not Provided';

    if (this.state.pac) {
      pacCode = this.state.pac;
    }


    let metaData = [];

    metaData = [
      {
        "key": "bank_name_on_account",
        "value": this.state.bankingNameOnAcc
      },
      {
        "key": "bank_account_number",
        "value": this.state.bankingAccNum
      },
      {
        "key": "bank_sortcode",
        "value": this.state.bankingSortcode1 + "-" + this.state.bankingSortcode2 + "-" + this.state.bankingSortcode3
      },
      {
        "key": "pac_code",
        "value": pacCode
      }
    ];


    let data = {
      "status": "processing",
      "meta_data": metaData,
    };

    if (this.state.totalCost > 0) {
      data = {
        "status": "pending",
        "meta_data": metaData,
      };
    }


    const bodyJSON = JSON.stringify(data);

    return fetch(api.woocommerceURL + 'orders/' + contractProps.orderID, {
      method: 'POST',
      headers: {
        'Authorization': api.woocommerceAuth,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: bodyJSON
    })
    .then((response) => response.json())
    .then((responseJson) => {

      //console.log(responseJson);

      if (responseJson.id && responseJson.status) {

        if (this.state.totalCost > 0) {

          // Still need to take payment for any accessories or other non contract items

          this.setState({ buttonLoading: false });

          this.props.history.push({
            pathname: '/checkout/payment',
            state: {
              orderID: contractProps.orderID,
              contactName: contractProps.contactName,
              contactEmail: contractProps.contactEmail,
              addressLine1: contractProps.addressLine1,
              addressLine2: contractProps.addressLine2,
              addressCity: contractProps.addressCity,
              postcode: contractProps.postcode
            }
          });

        } else {

          this.setState({ buttonLoading: false });

          window.scrollTo(0, 0);

          //this.props.history.push('/contract/updated');
          this.props.history.push('/checkout/order-received');

        }

      } else {
        // handle error
        this.handleOrderError();
      }


    })
    .catch((error) => {
      // handle error
      this.handleOrderError();
    });


  }



  handleOrderError = () => {

    this.setState({
      displayError: true,
      buttonLoading: false
    });

    window.scrollTo(0, 0);

  }



  handleOrderSummaryButton = () => {

    this.refs['contractFormPartTwo'].submit();

  }



  render() {

      // let submitButton = this.state.buttonLoading ? (
      //   <div className="loading-spinner-button">
      //     <Spinner name="pacman" color="#65c84c" fadeIn="half" />
      //   </div>
      // ) : (
      //   <input type="submit" value={this.state.buttonText} className="medium-button fadeIn" />
      // );

      // <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
      //   {submitButton}
      // </div>

      const topAlert = this.state.displayError ? (
        <div className="alert alert--red">
          Something went wrong, please try again. If the issue presists, call (01604) 946888.
        </div>
      ) : (
        <div className="alert alert--ylo">
          Your first monthly payment will not be processed until your contract has been confirmed.
        </div>
      );

      return (
        <div className="content-wrap">

          <Helmet>
            <title>Extra Details - ylo</title>
            <meta name="description" content="To complete your contract setup, we need a few more details." />
          </Helmet>

          <div className="page-intro">
            <h1>Extra Details</h1>
            <h4>To complete your contract setup, we need a few more details.</h4>
          </div>

          <div className="content-with-sidebar">

            <ValidatorForm onSubmit={this.handleSubmit} ref="contractFormPartTwo" style={{ maxWidth: '500px' }}>

              <div style={{ marginBottom: '10px' }}>
                <h3 style={{ marginBottom: '38px' }}>Banking Details</h3>
                {topAlert}
              </div>

              <div>
                <div className="input-group">
                  <div className="input-wrap">
                    <div className="input-label">Name on Account</div>
                    <ValidatedTextInput
                      onChange={this.handleBankingNameOnAccChange}
                      name="bankingNameOnAcc"
                      value={this.state.bankingNameOnAcc}
                      validators={['required', "matchRegexp:(^[-'a-zA-ZÀ-ÖØ-öø-ſ\\s]+$)"]}
                      errorMessages={['Please provide your name.', 'Ensure you only use letters.']}
                    />
                  </div>
                </div>

                <div className="input-group">
                  <div className="input-wrap">
                    <div className="input-label">Account Number</div>
                    <ValidatedTextInput
                      onChange={this.handleBankingAccNumChange}
                      name="bankingAccNum"
                      value={this.state.bankingAccNum}
                      validators={["required", "isNumber"]}
                      errorMessages={["Please provide an account number.", "Only use numbers."]}
                    />
                  </div>

                  <div className="input-wrap">
                    <div className="input-label">Sortcode</div>
                    <div className="multi-input-row">

                      <ValidatedTextInput
                        customclassname="txt-input txt-input--sortcode"
                        onChange={this.handleBankingSortcode1Change}
                        name="bankingSortcode 1"
                        value={this.state.bankingSortcode1}
                        maxLength="2"
                        validators={['required', "isNumber"]}
                        errorMessages={['Please provide a sortcode.', 'Only use numbers.']}
                      />

                      <div className="multi-input-row-divider">-</div>

                      <ValidatedTextInput
                        ref={this.sortCode2}
                        customclassname="txt-input txt-input--sortcode"
                        onChange={this.handleBankingSortcode2Change}
                        name="bankingSortcode 2"
                        value={this.state.bankingSortcode2}
                        maxLength="2"
                        validators={['required', "isNumber"]}
                        errorMessages={['Please provide a sortcode.', 'Only use numbers.']}
                      />

                      <div className="multi-input-row-divider">-</div>

                      <ValidatedTextInput
                        ref={this.sortCode3}
                        customclassname="txt-input txt-input--sortcode"
                        onChange={this.handleBankingSortcode3Change}
                        name="bankingSortcode 3"
                        value={this.state.bankingSortcode3}
                        maxLength="2"
                        validators={['required', "isNumber"]}
                        errorMessages={['Please provide a sortcode.', 'Only use numbers.']}
                      />

                    </div>
                  </div>
                </div>

                <h3 style={{marginTop: '20px'}}>Keeping your Number</h3>


                <div className="option-label"><h4>Would you like to keep your existing number?</h4></div>
                <div className="data-options" style={{marginBottom: '12px',}}>
                  <a href="#" onClick={this.handleKeepNumberOptionSelected.bind(this, "yes")} className={"yes" === this.state.keepNumber ? "data-option data-option--selected" : "data-option"}>Yes</a>
                  <a href="#" onClick={this.handleKeepNumberOptionSelected.bind(this, "no")} className={"no" === this.state.keepNumber ? "data-option data-option--selected" : "data-option"}>No</a>
                </div>

                {this.state.keepNumber === "yes" &&
                  <div>
                    <p>To move your number over to your new contract, you'll need to provide us with your PAC code.</p>

                    <p><a target="_blank" href="https://weareylo.com/blog/2588/end-it-with-a-text">How to get my PAC code?</a></p>

                    <div className="input-group">
                      <div className="input-wrap">
                        <div className="input-label">PAC Code</div>
                        <ValidatedTextInput
                          onChange={this.handlePACChange}
                          name="pac"
                          value={this.state.pac}
                          validators={["isNumber"]}
                          errorMessages={['Only use numbers.']}
                        />
                      </div>
                    </div>
                  </div>
                }

            </div>

            </ValidatorForm>

            <OrderSummary
              order={this.state.order}
              buttonPress={this.handleOrderSummaryButton}
              buttonLoading={this.state.buttonLoading}
              buttonText={this.state.buttonText}
            />

          </div>
        </div>
      );

  }


}

export default ContractSignupPart2;
