import React from "react";
import { withRouter, Link } from "react-router-dom";

import * as api from "../config/api";

export const HeaderContext = React.createContext('personal');

class Header extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      accessToken: '',
      menuOpen: false,
      //headerColour: 'rgba(255, 220, 21, 0.97)',
      currentPath: '',
      currentScrollHeight: 0,
      allMenus: [],
      menuItems: [],
      showSubMenu: 0,
      subMenuAvailable: true,
      contentType: 'personal'
    }

    //this.toggleMenuViaLink = this.toggleMenuViaLink.bind(this);
  }


  componentDidMount = () => {

    //this.getCategories();
    this.getInitialMenu();

  }



  componentDidUpdate = (prevProps, prevState) => {
    // if (window.location.pathname != this.state.currentPath) {
    //   this.updateHeaderColour();
    // }

    // if (prevProps.location.pathname !== this.props.location.pathname) {
    //
    //   const pathName = this.props.location.pathname;
    //
    //   if (this.state.contentType !== 'business' && pathName.includes('business-') || pathName.includes('/business') || pathName === '/') {
    //     this.setState({
    //       contentType: 'business'
    //     });
    //   }
    //
    // }

    // } else if (prevState.contentType !== this.state.contentType) {
    //   this.updateMenu();
    // }

  }



  getCategories = () => {

    return fetch(api.yloURL + 'categories', {
      headers: {
        'Accept': 'application/json'
      },
    })
    .then((response) => response.json())
    .then((responseJson) => {

      // Get all the main categories and add to menu under "Shop"
      const categoriesMenu = responseJson.filter(category => {
        if (
          !category.parent_id &&
          category.name !== "Travel" &&
          category.name !== "Clothing & Accessories" &&
          category.name !== "Mobile Phones"
        ) {
          return true;
        }
      });

      // let shopMenuItem = {
      //   "shopMenu": true,
      //   "categories": categoriesMenu
      // }

      this.setState(prevState => ({
        categories: responseJson,
        menuItems: prevState.menuItems
        //menuItems: [shopMenuItem, ...prevState.menuItems]
      }));

    })
    .catch((error) => {
    });

  }


  getInitialMenu = () => {

     // Fetch menus from cache, find initial menu required

     return fetch(api.cacheURL + 'menus.php', {
       headers: {
         'Accept': 'application/json'
       },
     })
     .then((response) => response.json())
     .then((responseJson) => {

       // Set default navigation
       let navTypeSlug = 'energy-nav';

       // Check if its a business page showing, if so put the toggle on "business"
       // and show the business navigation ----------------------
       // const pathName = this.props.location.pathname;
       //
       // if (pathName.includes('business-') || pathName.includes('/business') || pathName === '/') {
       //   this.setState({
       //     contentType: 'business'
       //   });
       //
       //   navTypeSlug = 'business-nav';
       // }
       // -----------------------------

       const currentMenu = responseJson.find(menu => menu.slug == navTypeSlug);

       if (currentMenu && currentMenu.items) {
         this.setState({
           isLoading: false,
           allMenus: responseJson,
           menuItems: currentMenu.items
         });
       }

     })
     .catch((error) => {
     });

  }



  // updateMenu = () => {
  //
  //   let navTypeSlug = 'main-nav';
  //
  //   if (this.state.contentType === 'business') {
  //     navTypeSlug = 'business-nav';
  //   }
  //
  //   const currentMenu = this.state.allMenus.find(menu => menu.slug == navTypeSlug);
  //
  //   if (currentMenu && currentMenu.items) {
  //     this.setState({
  //       menuItems: currentMenu.items
  //     });
  //   }
  //
  // }


  toggleMenu = (event) => {

    // prevent browser window jump for link
    event.preventDefault();

    this.setState(prevState => ({
     menuOpen: !prevState.menuOpen
    }));

  }


  closeMenuViaLink = () => {

    // toggle the full nav menu for mobile
    // Forcibly hide the submenu on desktop before re-enabling it to allow hover to work again

    this.setState({
     menuOpen: false,
     subMenuAvailable: false
    }, function() {
      setTimeout(() => {
        this.setState({
         subMenuAvailable: true,
        });
      }, 500);
   });

  }


  toggleSubMenu = (navItemID) => {

    // prevent browser window jump for link
    //event.preventDefault();

    // console.log("navItemID");
    // console.log(navItemID);

    if (this.state.showSubMenu === navItemID) {

      // Already showing this submenu, set to 0 so it doesn't render
      this.setState({
       showSubMenu: 0
      });

    } else {

      // Show this particular submenu
      this.setState({
       showSubMenu: navItemID
      });

    }


  }



  // toggleContent = (contentType) => {
  //   this.setState({
  //    contentType: contentType
  //   });
  // }




  render() {

    const cartContents = JSON.parse(localStorage.getItem('cartContents'));
    const navContainerClass = this.state.menuOpen ? 'nav-main nav-main--open' : 'nav-main';


    // Display Menu

    const menu = this.state.menuItems.map((item) => {

      if (item.shopMenu) {

        // api based dynamic menu item

        let navLinkStyle = "main-link";
        let navSubMenuStyle = "nav-submenu nav-submenu--narrow";


        if (this.state.showSubMenu === 0) {
          navLinkStyle = "main-link main-link--show";
        }

        if (this.state.showSubMenu === 'shop') {
          navSubMenuStyle = "nav-submenu nav-submenu--show";
        }

        if (!this.state.subMenuAvailable) {
          navSubMenuStyle = "nav-submenu nav-submenu--narrow nav-submenu--hide";
        }


        return (
          <li className="main" key={"shopMenu"}>

            <Link onClick={this.closeMenuViaLink} to="/shop" className={navLinkStyle}>
              <span className="main-item-text-wrap">Shop <span className="main-item-description">Grab the best technology deals</span></span>
            </Link>

          </li>


        );


      } else {

        // Wordpress Menu Items

        let menuTitle = item.title;
        let menuURL = item.url;

        if (menuURL.includes('https://yloenergy.com/backend/blog/category')) {
          menuURL = '/blog/category/' + item.object_id;
        } else if (menuURL.includes('https://yloenergy.com/backend')) {
          menuURL = menuURL.replace('https://yloenergy.com/backend', '');
        }

        // if (menuTitle === 'Business' && !menuURL.includes('business')) {
        //   menuURL = '/business/' + menuURL;
        // }

        let navLinkStyle = "main-link";
        let navSubMenuStyle = "nav-submenu";

        if (this.state.showSubMenu === 0) {
          navLinkStyle = "main-link main-link--show";
        }


        if (item.child_items) {

          // Menu has child items, handle the difference in display

          const subMenuHighlighted = item.child_items.map((subitem) => {
            if (subitem.classes && subitem.classes.includes("highlighted")) {

              // Tweak the url where neccessary to account for backend differences

              let subMenuURL = subitem.url;

              if (subMenuURL.includes('https://yloenergy.com/backend/blog/category')) {
                subMenuURL = '/blog/category/' + subitem.object_id;
              } else if (subMenuURL.includes('https://yloenergy.com/backend')) {
                subMenuURL = subMenuURL.replace('https://yloenergy.com/backend', '');
              }

              // if (menuTitle === 'Business' && !subMenuURL.includes('business')) {
              //   subMenuURL = '/business' + subMenuURL;
              // }

              // Only letters, numbers and spaces
              const cleanedTitle = subitem.title.replace(/[^a-zA-Z0-9]+/g, '');
              const highlightedImageURL = "/img/menu/" + cleanedTitle.toLowerCase() + ".png";

              return (
                <li key={subitem.ID.toString()}>
                  <Link onClick={this.closeMenuViaLink} to={subMenuURL}>
                    <span className="menu-highlighted-image" style={{ background: "url(" + highlightedImageURL + ") #f9f9f9", backgroundSize: "cover" }}></span>
                    {subitem.title}
                    <span className="item-description">{subitem.description}</span>
                  </Link>
                </li>
              );

            }
          });


          const subMenu = item.child_items.map((subitem) => {
            if (subitem.classes && !subitem.classes.includes("highlighted")) {

              // Tweak the url where neccessary to account for backend differences

              let subMenuURL = subitem.url;

              if (subMenuURL.includes('https://yloenergy.com/backend/blog/category')) {
                subMenuURL = '/blog/category/' + subitem.object_id;
              } else if (subMenuURL.includes('https://yloenergy.com/backend')) {
                subMenuURL = subMenuURL.replace('https://yloenergy.com/backend', '');
              }

              // if (menuTitle === 'Business' && !subMenuURL.includes('business')) {
              //   subMenuURL = '/business' + subMenuURL;
              // }

              return (
                <li key={subitem.ID.toString()}>
                  <Link onClick={this.closeMenuViaLink} to={subMenuURL}>
                    {subitem.title}
                    <span className="item-description">{subitem.description}</span>
                  </Link>
                </li>
              );

            }
          });


          if (this.state.showSubMenu === item.ID) {
            navSubMenuStyle = "nav-submenu nav-submenu--show";
          }

          if (!this.state.subMenuAvailable) {
            navSubMenuStyle = "nav-submenu nav-submenu--hide";
          }


          // <ul>
          //   {subMenu}
          // </ul>

          //<img className="dropdown-arrow" src="/img/right-arrow.png" alt="dropdown icon" />

          return (
            <li className="main" key={item.ID.toString()}>

              <Link onClick={this.toggleSubMenu.bind(this, item.ID)} to={menuURL} className={navLinkStyle}>
                <span className="main-item-text-wrap">
                  {item.title}
                  {item.description &&
                    <span className="main-item-description">{item.description}</span>
                  }
                </span>
              </Link>

              <div className={navSubMenuStyle}>
                <a onClick={this.toggleSubMenu.bind(this, item.ID)} className="nav-submenu-back-button">
                  <img className="back-arrow" src="/img/right-arrow.png" alt="back arrow icon" />
                  <span>Back</span>
                </a>
                <ul className="highlighted">
                  {subMenuHighlighted}
                </ul>
              </div>

            </li>
          );


        } else {

          // Menu item is normal, no submenu

          if (menuURL.includes('https://')) {

            return (
              <li className="main" key={item.ID.toString()}>
                <a href={menuURL} className={navLinkStyle} target="_blank">
                  <span className="main-item-text-wrap">
                    {item.title}
                    {item.description &&
                      <span className="main-item-description">{item.description}</span>
                    }
                  </span>
                </a>
              </li>
            );

          } else {

            return (
              <li className="main" key={item.ID.toString()}>
                <Link onClick={this.closeMenuViaLink} to={menuURL} className={navLinkStyle}>
                  <span className="main-item-text-wrap">
                    {item.title}
                    {item.description &&
                      <span className="main-item-description">{item.description}</span>
                    }
                  </span>
                </Link>
              </li>
            );

          }

        }

      }

    });



    //const homeURL = this.state.contentType === "personal" ? "/" : "/business-home";
    const homeURL = "/";


    // <div className={this.state.showSubMenu ? "toggle-switch toggle-switch--hide" : "toggle-switch"}>
    //   <a onClick={this.toggleContent.bind(this, "personal")} className={this.state.contentType === "personal" ? "toggle-switch-option toggle-switch-option--active" : "toggle-switch-option"}>
    //     <span>Personal</span>
    //   </a>
    //   <a onClick={this.toggleContent.bind(this, "business")} className={this.state.contentType === "business" ? "toggle-switch-option toggle-switch-option-business--active" : "toggle-switch-option"}>
    //     <span>Business</span>
    //   </a>
    // </div>


    return (
      <HeaderContext.Provider
        value={this.state.contentType}
      >

        <div className="header-wrap">
        <header>

          <div className="header-logo-menu">

            <div className="logo">
              <Link to={homeURL}><img className="fill-div" src="/img/logo-header.png?v=3" alt="ylo logo" /></Link>
            </div>

            <div className={navContainerClass}>
              <nav>

                <ul>
                  {menu}
                </ul>

                <Link to={"/contact"} onClick={this.closeMenuViaLink} className="nav-side-button">
                  <span>Contact Us</span>
                </Link>

              </nav>
            </div>

          </div>


          <div className="header-side-buttons">

            <a href="#menu" onClick={this.toggleMenu.bind(this)} className="header-button-surround header-button-menu">
              <span className="menu-button">
                <span className={this.state.menuOpen ? "menu-line-one menu-line-one--cross" : "menu-line-one"}></span>
                <span className={this.state.menuOpen ? "menu-line-two menu-line-two--cross" : "menu-line-two"}></span>
                <span className={this.state.menuOpen ? "menu-line-three menu-line-three--hide" : "menu-line-three"}></span>
              </span>
            </a>
          </div>

        </header>
        </div>

        {this.props.children}

      </HeaderContext.Provider>

    );
  }

}

export default withRouter(Header);
