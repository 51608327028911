import React from "react";
import { Link } from "react-router-dom";
import Spinner from "react-spinkit";
import {Helmet} from "react-helmet";

import * as api from "../config/api";
//import Featured from "./home/Featured";
//import FeaturedFull from "./home/FeaturedFull";
import CategoryBanner from "./home/CategoryBanner";
//import BlogStrip from "./blog/BlogStrip";

// No longer in use, currently, just using the basic page component

class Home extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      accessToken: '',
      pageContent: '',
      pageTitle: '',
      pageMeta: {},
      currentScrollHeight: 0,
    }

    this.getContent();
  }


  componentDidMount = () => {

   //  window.onscroll = () => {
   //   const newScrollHeight = Math.ceil(window.scrollY / 10) * 10;
   //   if (this.state.currentScrollHeight != newScrollHeight && newScrollHeight !== 0){
   //       this.setState({currentScrollHeight: newScrollHeight - 10 })
   //   }
   // }
   //
   // window.touchend = () => {
   //   const newScrollHeight = Math.ceil(window.scrollY / 10) * 10;
   //    if (this.state.currentScrollHeight != newScrollHeight && newScrollHeight !== 0){
   //        this.setState({currentScrollHeight: newScrollHeight - 10 })
   //    }
   //  }
   //
   //  window.scrollTo(0, 0);

  }


  getContent = () => {


   // Fetch additional page content from wordpress backend

   fetch(api.wordpressURL + 'pages?slug=home', {
     headers: {
       'Accept': 'application/json'
     },
   })
   .then((response) => response.json())
   .then((responseJson) => {

     console.log(responseJson[0].content.rendered);

       let metaObj = {};
       let pageTitle = '';

       if (responseJson[0].yoast_meta) {

         responseJson[0].yoast_meta.forEach((meta, index) => {

           if ((meta.name && meta.name === "description") || (meta.property && meta.property === "og:description")) {
             metaObj.description = meta.content;
           }
         });

         pageTitle = responseJson[0].yoast_title;

       }

       this.setState({
         isLoading: false,
         homeContent: responseJson[0].content.rendered,
         pageTitle: pageTitle,
         pageMeta: metaObj,
       }, function() {
       });

   })
   .catch((error) => {
   });


   // fetch(api.wordpressURL + 'pages?slug=home-middle', {
   //   headers: {
   //     'Accept': 'application/json'
   //   },
   // })
   // .then((response) => response.json())
   // .then((responseJson) => {
   //
   //     this.setState({
   //       isLoading: false,
   //       homeMiddleContent: responseJson[0].content.rendered,
   //     }, function() {
   //     });
   //
   // })
   // .catch((error) => {
   // });
   //
   //
   // return fetch(api.wordpressURL + 'pages?slug=home-bottom', {
   //   headers: {
   //     'Accept': 'application/json'
   //   },
   // })
   // .then((response) => response.json())
   // .then((responseJson) => {
   //
   //     this.setState({
   //       isLoading: false,
   //       homeBottomContent: responseJson[0].content.rendered,
   //     }, function() {
   //     });
   //
   // })
   // .catch((error) => {
   // });


 }


  render() {

    if (this.state.isLoading) {

      return (
        <div className="loading-spinner">
          <Spinner name="pacman" color="#65c84c" fadeIn="half" />
        </div>
      );

    } else {

      // <div dangerouslySetInnerHTML={{__html: this.state.homeTopContent}}>
      // </div>

      //let imagePosition = 415 - this.state.currentScrollHeight;

      // if (this.state.currentScrollHeight > 50) {
      //    imagePosition = 420 - this.state.currentScrollHeight;
      // }

      return (
        <div className="content-wrap" style={{ paddingTop: '0px', paddingBottom: '0px' }}>

          <Helmet>
            <title>{this.state.pageTitle}</title>
            <meta name="description" content={this.state.pageMeta.description} />
          </Helmet>

          <div className="content-wrap" style={{ paddingTop: '0px', paddingBottom: '10px' }}>
            <div dangerouslySetInnerHTML={{__html: this.state.homeContent}}>
            </div>
          </div>

        </div>
      );

    }

  } // end of render


}

export default Home;
