import React from "react";
import { Link } from "react-router-dom";
import Spinner from "react-spinkit";
import { ValidatorForm } from 'react-form-validator-core';

import * as api from "../../config/api";

import ValidatedTextInput from "./forms/ValidatedTextInput";
import ValidatedEmailInput from "./forms/ValidatedEmailInput";
import ValidatedNumberInput from "./forms/ValidatedNumberInput";

import FilePicker from "./forms/FilePicker";

class FormEnergy extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      buttonLoading: false,
      accessToken: '',
      pageContent: '',

      contactName: '',
      contactEmail: '',
      contactNumber: '',
      //companyName: '',
      formSubmitted: false,
    }

    this.handleContactNameChange = this.handleContactNameChange.bind(this);
    this.handleContactEmailChange = this.handleContactEmailChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }



  componentDidMount = () => {
    window.scrollTo(0, 0);
  }


  handleContactNameChange = (event) => {
    this.setState({contactName: event.target.value});
  }

  handleContactEmailChange = (event) => {
    this.setState({contactEmail: event.target.value});
  }

  handleBills = (files) => {
    this.setState({ bills: files });
  }


  // handleGasBill = (file) => {
  //   this.setState({ gasBill: file });
  // }



  handleSubmit = (event) => {

    // prevent browser window jump for link
    event.preventDefault();

    this.setState({buttonLoading: true});

    let formData = new FormData();

    // Add source to change email from/to etc
    formData.append('source', 'yloenergy.com');

    if (this.state.contactName) {
      formData.append('name', this.state.contactName);
    }

    if (this.state.contactEmail) {
      formData.append('email', this.state.contactEmail);
    }

    this.state.bills.forEach((bill, i) => {
      formData.append('bills[]', bill);
    });

    // if (this.state.gasBill) {
    //   formData.append('gasBill', this.state.gasBill);
    // }

    return fetch('https://ylo.io/api/telecom/energy-contact-form', {
      method: 'POST',
      // headers: {
      //   'Authorization': 'Bearer ' + this.state.accessToken,
      // },
      body: formData
    })
    .then((response) => response.json())
    .then((responseJson) => {
        if (responseJson.success) {
            setTimeout(() => {
              this.setState({buttonLoading: false, formSubmitted: true});
            }, 3000);
        } else {
            this.setState({buttonLoading: false});
        }
    })
    .catch((error) => {
      //console.log(error);
      this.setState({buttonLoading: false});
    });

  }




  render() {


    // const content = this.props.content ? (
    //   <div dangerouslySetInnerHTML={{__html: this.props.content}}>
    //   </div>
    // ) : (
    //   <div>
    //     <h2>Reach Out</h2>
    //     <h4 className="secondary-text">To find out how we can help your business, get in touch using the contact form and provide any information you can on your setup and requirements.</h4>
    //     <h4 className="secondary-text">If you prefer to speak to someone over the phone, call (01604) 946888, our team are available from 9am to 5pm.</h4>
    //   </div>
    // );

    if (this.state.formSubmitted) {

      return (
        <div className="form-block-energy" style={{minHeight: '400px', justifyContent: 'center'}}>

          <form style={{ alignSelf: 'center' }}>
            <h3>One Step Closer</h3>
            <p>Your information has been received. One of our team will get back to you as soon as possible.</p>
          </form>

        </div>
      );

    } else {

      let submitButton = this.state.buttonLoading ? (
        <div className="loading-spinner-button loading-spinner-button--black">
          <Spinner name="pacman" color="#121212" fadeIn="half" />
        </div>
      ) : (
        <input type="submit" value="Send" className="large-button large-button--black fadeIn" />
      );

      // <div className="input-wrap">
      //   <div className="input-label">Company Name</div>
      //   <input type="text" onChange={this.handleCompanyNameChange} name="companyName" value={this.state.companyName} className="txt-input" />
      // </div>

      return (
        <div className="form-block-energy">

        <ValidatorForm onSubmit={this.handleSubmit} ref="energyForm">

          <div className="input-wrap">
            <div className="input-label">Full Name</div>
            <ValidatedTextInput
              onChange={this.handleContactNameChange}
              name="contactName"
              value={this.state.contactName}
              validators={['required', "matchRegexp:(^[-'a-zA-ZÀ-ÖØ-öø-ſ\\s]+$)"]}
              errorMessages={['Please provide your name.', 'Ensure you only use letters.']}
            />
          </div>


          <div className="input-wrap">
            <div className="input-label">Email</div>
            <ValidatedEmailInput
              onChange={this.handleContactEmailChange}
              name="contactEmail"
              value={this.state.contactEmail}
              validators={['required']}
              errorMessages={['An email address is required.']}
            />
          </div>

          <div className="input-wrap">
            <div className="input-label">Utility Bill(s)</div>
            <FilePicker processFiles={this.handleBills} />
          </div>

          <div>
            {submitButton}
          </div>

        </ValidatorForm>
        </div>
      );

    }

  }

}

export default FormEnergy;
