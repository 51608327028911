import React from "react";
import { Link } from "react-router-dom";
import Spinner from "react-spinkit";
import moment from 'moment';
import {Helmet} from "react-helmet";

import * as api from "../../config/api";

class BlogPost extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      accessToken: '',
      post: ''
    }

  }



  componentDidMount = () => {
    window.scrollTo(0, 0);

    this.getContent();
  }


  componentDidUpdate = (prevProps) => {

    if (prevProps.match.params.name !== this.props.match.params.name) {


      this.getContent();

      window.scrollTo(0, 0);

    }

  }


  getContent = () => {


   // Fetch page content from wordpress backend

   this.setState({
     isLoading: true,
   });


   return fetch(api.wordpressURL + 'posts/' + this.props.match.params.id + '?_embed', {
     headers: {
       'Accept': 'application/json',
     },
   })
   .then((response) => response.json())
   .then((responseJson) => {


       console.log(responseJson);

       this.setState({
         isLoading: false,
         post: responseJson,
       }, function() {
       });


   })
   .catch((error) => {
   });


 }



 getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1) + min); //The maximum is inclusive and the minimum is inclusive
 }


  render() {


    if (this.state.isLoading) {

      return (
        <div className="loading-spinner">
          <Spinner name="pacman" color="#65c84c" fadeIn="half" />
        </div>
      );

    } else {


      // <div class="row">
      // <div class="row-inner">
      // <h2 style="text-align:center">Register your Interest</h2>
      // </div>
      // </div>

      let formattedDate = moment(this.state.post.date).format("Do MMMM YYYY");

      // const randomDayOfMonth = this.getRandomInt(1, 14);
      // let formattedDate = moment().subtract(randomDayOfMonth, 'days').format("Do MMMM YYYY");

      let imgSrc = '';
      if (this.state.post.featured_media) {
          if (this.state.post._embedded["wp:featuredmedia"][0].media_details.sizes.large) {
              imgSrc = this.state.post._embedded["wp:featuredmedia"][0].media_details.sizes.large.source_url;
          } else {
              imgSrc = this.state.post._embedded["wp:featuredmedia"][0].media_details.sizes.full.source_url;
          }

      }

      return (
        <div className="content-wrap">

          <Helmet>
            <title>{this.state.post.yoast_title}</title>
            <meta name="description" content={this.state.post.excerpt.rendered.replace(/<\/?("[^"]*"|'[^']*'|[^>])*(>|$)/g, "")} />
          </Helmet>

          <div className="blog-intro">
            <h1 dangerouslySetInnerHTML={{__html: this.state.post.title.rendered}}></h1>
            <h4>{formattedDate}</h4>
          </div>

          <img style={{ alignSelf: 'center', borderRadius: '4px' }} src={imgSrc} />

          <div className="row" style={{ marginTop: '60px'}}>
            <div className="row-inner row-blog-post" dangerouslySetInnerHTML={{__html: this.state.post.content.rendered}}>
            </div>
          </div>

        </div>
      );

    }

  }

}

export default BlogPost;
