import React from "react";
import { Link } from "react-router-dom";
import {Helmet} from "react-helmet";

import OrderSummary from "./OrderSummary";

class OrderReceived extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      contract: false,
      order: [],
      totalCost: 0.00
    }

    //this.setOrderText();

  }


  componentDidMount = () => {

    window.scrollTo(0, 0);

    if (localStorage.getItem('cartContents')) {

      let order = JSON.parse(localStorage.getItem('cartContents'));

      this.setState({
        order: order
      }, function() {
        this.setOrderInfo();
      });

    }

  }


  setOrderInfo = () => {

    const orderContents = this.state.order;
    let runningTotal = 0.00;

    if (orderContents) {

      orderContents.forEach((orderItem, index) => {

        if (orderItem.phoneContract || orderItem.simOnlyContract || orderItem.fibreContract) {
          this.setState({
            contract: true
          });
        }

        runningTotal += parseFloat(orderItem.price);

        if (orderContents.length - 1 === index) {
          if (runningTotal > 0) {
            this.setState({
              totalCost: runningTotal.toFixed(2)
            });
          }

          this.emptyCart();
        }
      });

    }


  }



  emptyCart = () => {
    // Order is complete and we saved the order info earlier, safe to empty cart
    localStorage.removeItem('cartContents');
  }



  render() {

      let orderReceivedText = 'We don\'t seem to have your order details';
      let orderReceivedText2 = 'If you haven\'t received an email for your order, please contact us.';

      if (this.state.contract && this.state.totalCost > 0) {
        orderReceivedText = 'Thank you for your order and choosing ylo. Your payment has been received and we will despatch your order as soon as possible.';
        orderReceivedText2 = 'You\'re one step closer to a new contract. We will reach out to you about your contract sign up in the next few days. Your contract and any monthly payments will not be processed until you\'re approved.';
      } else if (this.state.contract) {
        orderReceivedText = 'Thank you for your order and choosing ylo. You\'re one step closer to a new contract. We will reach out to you about your contract sign up in the next few days.';
        orderReceivedText2 = 'Your contract and any monthly payments will not be processed until you\'re approved.';
      } else if (this.state.totalCost > 0) {
        orderReceivedText = 'Thank you for your order and choosing ylo. Your payment has been received.';
        orderReceivedText2 = 'We will despatch your order as soon as possible.';
      }


      return (
        <div className="content-wrap">

            <Helmet>
              <title>Order Received - ylo</title>
              <meta name="description" content="We have your order, thank you for choosing ylo." />
            </Helmet>

            <div className="page-intro">
              <h1>Order Received</h1>
            </div>

            <div className="content-with-sidebar">
              <div className="content-with-sidebar-left">
                <p>{orderReceivedText}</p>
                <p>{orderReceivedText2}</p>
              </div>

              <OrderSummary
                order={this.state.order}
                paid={true}
              />
            </div>

        </div>
      );

  }

}

export default OrderReceived;
