import React from "react";
import { Link } from "react-router-dom";
import Spinner from "react-spinkit";
import {Helmet} from "react-helmet";
import htmr from 'htmr';

import * as api from "../config/api";
import FormContact from "./other/FormContact";
import FormEV from "./other/FormEV";
import FormEnergy from "./other/FormEnergy";
import SwitchContent from "../lib/SwitchContent";

import NoMatch from "./other/NoMatch";
import CustomForm from "./other/CustomForm";

import { ValidatorForm } from 'react-form-validator-core';
import ValidatedTextInput from "./other/forms/ValidatedTextInput";


class Page extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      accessToken: '',
      notFound: false,
      allPages: [],
      pageContent: '',
      pageTitle: '',
      pageMeta: {},
      showVoipForm: false,
      showContactForm: false,
      postcodeProvided: ""
    }

    this.handlePostcodeCheck = this.handlePostcodeCheck.bind(this);
  }


  componentDidMount = () => {

    window.scrollTo(0, 0);

    this.getPage();

    if (document.getElementById('scroll-more')) {
      document.getElementById('scroll-more').addEventListener('click', this.scrollDown);
    }

  }


  componentDidUpdate = (prevProps) => {

    if (prevProps.match.params.name !== this.props.match.params.name) {

        this.getPage();
        window.scrollTo(0, 0);

    } else if (prevProps.contentType !== this.props.contentType && this.state.allPages.length > 0) {

      const currentPath = this.props.location.pathname;
      const newPath = SwitchContent(this.props.contentType, [currentPath], this.state.allPages);
      if (newPath !== currentPath) {
        this.props.history.push(newPath);
      }

      window.scrollTo(0, 0);

    }

    if (document.getElementById('scroll-more')) {
      document.getElementById('scroll-more').addEventListener('click', this.scrollDown);
    }

  }

  scrollDown = () => {
    window.scrollTo({
      top: 500,
      left: 0,
      behavior: 'smooth'
    });
  }

  getPage = () => {

    this.setState({
      isLoading: true,
      pageContent: '',
      pageTitle: '',
      pageMeta: [],
      showVoipForm: false,
      showContactForm: false
    });

    const path = this.props.location.pathname;
    let slug = this.props.match.params.name;

    // Default to home and goto home if /business
    if (path === '/' || path === '/business') {
      slug = 'home';
    }

    //return fetch(api.wordpressURL + 'pages?slug=' + slug, {
    return fetch(api.cacheURL + 'item-by-id.php?slug=' + slug, {
      headers: {
        'Accept': 'application/json'
      },
    })
    .then((response) => response.json())
    .then((responseJson) => {

        const page = responseJson;

        let metaObj = {
          title: 'ylo',
          description: "Welcome, we are ylo. Bringing energy, telecoms and more."
        };

        if (!page?.id) {
            this.setState({
              isLoading: false,
              pageContent: "",
              notFound: true,
              pageMeta: metaObj
            });

            return;
        }

        if (page?.yoast_head_json?.og_title) {
          metaObj.title = page.yoast_head_json.og_title;
        }

        if (page?.yoast_head_json?.og_description) {
          metaObj.description = page.yoast_head_json.og_description;
        }

        if (page.acf.display_contact_form) {

          this.setState({
            isLoading: false,
            notFound: false,
            pageContent: page.content.rendered,
            pageMeta: metaObj,
            showContactForm: true,
            contactFormContent: page.acf.contact_form_content,
            contactFormRedirectSlug: page.acf.contact_form_redirect_slug
          });

        } else {
          this.setState({
            isLoading: false,
            notFound: false,
            pageContent: page.content.rendered,
            pageMeta: metaObj
          });
        }

    })
    .catch((error) => {
    });

  }

 handleContactNameChange = (event) => {
   this.setState({contactName: event.target.value});
 }

 // handleContactJobChange = (event) => {
 //   this.setState({contactJob: event.target.value});
 // }

 handleContactNumberChange = (event) => {
   this.setState({contactNumber: event.target.value});
 }

 handleContactEmailChange = (event) => {
   this.setState({contactEmail: event.target.value});
 }


 handlePostcodeCheck = () => {
     let postcode = this.state.postcodeProvided;
     postcode = postcode.replace(/\s/g, '');
     //window.location = "https://weareylo.com/availability-checker.html?postcode=" + postcode;
     this.props.history.push("/availability-checker?postcode=" + postcode);
 }

 renderPostcodeChecker = () => {
     let submitButton = (
         <input
             type="submit"
             value="Check Availability"
             className="medium-button fadeIn"
         />
     );

     return (
       <ValidatorForm onSubmit={this.handlePostcodeCheck} className="postcode-checker-section">
           <ValidatedTextInput
               onChange={(event) => this.setState({ postcodeProvided: event.target.value})}
               name="postcode"
               placeholder="LE1 2AB"
               value={this.state.postcodeProvided}
           />
           <div>
               {submitButton}
           </div>
       </ValidatorForm>
     );
 }

 renderBackgroundVideo = (videoSrc, videoType) => {
   return (
     <video key="big-video" preload="auto" playsInline loop autoPlay muted>
       <source src={videoSrc} type={videoType} />
     </video>
   );
 }

 renderContactForm = (formId) => {

    if (formId === "form-energy") {
        return (
            <FormEnergy key={formId} />
        );
    } else if (formId === "form-ev") {
        return (
            <FormEV
                key={formId}
                history={this.props.history}
                content={false}
            />
        );
    } else {
        return (
            <FormContact
                key="form-element"
                history={this.props.history}
                content={this.state.contactFormContent}
                redirectSlug={this.state.contactFormRedirectSlug}
            />
        );
    }

 }

 renderForm = (config) => {
     return (
         <CustomForm
             key="custom-form"
             history={this.props.history}
             config={config}
         />
     );
 }

  render() {

    if (this.state.isLoading) {

      return (
        <div className="loading-spinner">
          <Spinner name="pacman" color="#65c84c" fadeIn="half" />
        </div>
      );

    } else if (this.state.notFound) {

      return (
          <NoMatch />
      );

    } else {

      let htmlElements = htmr(this.state.pageContent);
      htmlElements = htmlElements.map((element, index) => {

        if (element.props?.id === "homenew-calc") {

          let columnIdxToReplace = 0;
          const elementColumns = element.props.children[0]?.props?.children;
          for (let i=0; i < elementColumns.length; i++) {

              let column = elementColumns[i];
              const columnClassArray = column.props?.className?.split(" ");

              if (columnClassArray?.includes('postcode-checker-column')) {
                  columnIdxToReplace = i;
              }

          }

          let mainElements = elementColumns[columnIdxToReplace]?.props?.children[0]?.props?.children;

          if (mainElements.length < 4) {
              mainElements = elementColumns[columnIdxToReplace]?.props?.children[0]?.props?.children[0]?.props?.children[0]?.props?.children;
          }

          // Pull out last element from nested column div
          mainElements.splice(mainElements.length - 1, 1);

          // Replace block with react form
          mainElements.push(this.renderPostcodeChecker());
        }

        if (element.props?.className) {

            const classArray = element.props.className?.split(" ");

            if (classArray.includes('json-form-container')) {

                // We have a container marked for a json form, dig in
                // to the columns and find the json script data, use it to
                // generate a form and then put that in the column it should be displayed

                let columnIdx= 0;
                let columnBlockIdx = 0;
                let formData = "";

                // The nesting story, ah, ah, ah (thanks Avada)
                const elementColumns = element.props.children[0]?.props?.children;
                for (let i=0; i < elementColumns.length; i++) {

                    let column = elementColumns[i];
                    const columnClassArray = column.props?.className?.split(" ");

                    if (columnClassArray?.includes('json-form-column')) {
                        columnIdx = i;
                        const columnBlocks = column.props?.children[0]?.props?.children;
                        for (let j=0; j < columnBlocks.length; j++) {
                          if (columnBlocks[j]?.type === "script") {
                            columnBlockIdx = j;
                            formData = columnBlocks[j].props?.children[0];
                          }
                        }
                    }

                }

                // Parse the formdata json text string into proper JSON and pass it into the renderForm component
                const formComponent = this.renderForm(JSON.parse(formData));

                // Replace script block of json text with our generated form inside the column div at the specific position
                elementColumns[columnIdx]?.props?.children[0]?.props?.children.splice(columnBlockIdx, 1, formComponent);

            }

          if (classArray.includes('form-container')) {

              // To be used to render different forms in the future
              const formId = element.props?.id;

            //if (element.props.children[0].props.children[0].props.className === "form-block") {

              // pull out dummy form
              element.props.children.splice(0, 1);

              // add custom react form
              element.props.children.push(this.renderContactForm(formId));

            //}

          }

          if (classArray.includes('video-background')) {
            if (element.props.children[0].props.children[0].type === 'video') {

              const videoSrc = element.props.children[0].props.children[0].props.children[0].props.src;
              const videoType = element.props.children[0].props.children[0].props.children[0].props.type;

              element.props.children[0].props.children.splice(0, 1);

              element.props.children[0].props.children.push(this.renderBackgroundVideo(videoSrc, videoType));

            }
          }

        }


        return element;

      });

      //<div dangerouslySetInnerHTML={{__html: this.state.pageContent}}></div>

      let pageForm = (<></>);

      if (this.state.showContactForm) {
          pageForm = (
              <FormContact
                history={this.props.history}
                content={this.state.contactFormContent}
                redirectSlug={this.state.contactFormRedirectSlug}
              />
          );

          if (this.props.match.params.name === 'ev') {
              pageForm = (
                <FormEV
                  history={this.props.history}
                  content={this.state.contactFormContent}
                  redirectSlug={this.state.contactFormRedirectSlug}
                />
              )
          }
      }

      return (
        <div className="content-wrap" style={{paddingTop: '0px', paddingBottom: '0px'}}>

          <Helmet>
            <title>{this.state.pageMeta.title}</title>
            <meta name="description" content={this.state.pageMeta.description} />
            <script type="text/javascript" src="https://assets.calendly.com/assets/external/widget.js" async></script>
          </Helmet>

          <div>{htmlElements}</div>

          {pageForm}

        </div>
      );

    }

  }

}

export default Page;
