import React, { useState, useRef, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import Spinner from "react-spinkit";
import { ValidatorForm } from 'react-form-validator-core';

import * as api from "../../config/api";

import ValidatedTextInput from "./forms/ValidatedTextInput";
import ValidatedTextArea from "./forms/ValidatedTextArea";
import ValidatedEmailInput from "./forms/ValidatedEmailInput";
import ValidatedNumberInput from "./forms/ValidatedNumberInput";

function ToolTip({ content, style }) {

    const [showBox, setShowBox] = useState(false)

    function toggleBox(e) {
      e.preventDefault();
      setShowBox(!showBox);
    }

    return (
        <>
        <a
            onMouseOver={() => setShowBox(true)}
            onMouseLeave={() => setShowBox(false)}
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: 30,
                height: 30,
                marginLeft: 4,
                textDecoration: 'none',
                cursor: 'pointer'
            }}
        >
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: 18,
                    minWidth: 18,
                    height: 18,
                    backgroundColor: showBox ? '#121212' : '#ececec',
                    borderRadius: 20,
                    fontSize: 15,
                    color: showBox ? '#ffffff' : '#121212'
                }}
            >
                ?
            </div>
        </a>
        <div
            style={{
                position: 'absolute',
                maxWidth: 500,
                backgroundColor: '#121212',
                padding: 14,
                borderRadius: 5,
                fontSize: 14,
                lineHeight: '22px',
                color: '#ffffff',
                zIndex: showBox ? 10 : 0,
                visibility: showBox ? 'visible' : 'hidden',
                top: 34,
                ...style
            }}
        >
            {content}
        </div>
        </>
    )
}

function generateId(label) {
    let id = "";

    // Convert the label to a simple identifier for the form field
    for(let i=0; i < label.length; i++) {
        if (label[i] !== " ") {
            // Add characters but skip spaces

            if (i === 0) {
                id = label[i].toLowerCase();
            } else {
                id += label[i];
            }
        }
    }

    return id;
}

// Takes a json config object prop and converts it into a form

function CustomForm({ history, config }) {

    const inputData = config?.inputs;
    let initialFormData = {};
    //let inputRefs = useRef([]);
    const inputRefs = useMemo(() => inputData.map(i=> React.createRef()), []);

    for (let i=0; i < inputData.length; i++) {
        const inputId = generateId(inputData[i].label);
        if (inputData[i].type !== "file") {
            initialFormData[inputId] = "";
        }
    }

    const [sFormData, setSFormData] = useState(initialFormData);
    const [sButtonLoading, setSButtonLoading] = useState(false);
    const [sFormSubmitted, setSFormSubmitted] = useState(false);
    const form = useRef(null);

    const handleInputChange = (inputId, event) => {
            setSFormData((prevState) => ({
              ...prevState,
              [inputId]: event?.target?.value
            }));
    }

    const handleFileChange = (inputId, event) => {
        setSFormData((prevState) => ({
          ...prevState,
          files: {...prevState.files, ...{[inputId]: event?.target?.files}}
        }));
    }

    const handleOptionSelected = (inputId, option, multi, event) => {
        event.preventDefault();

        if (!multi) {
            setSFormData((prevState) => ({
              ...prevState,
              [inputId]: [option]
            }));
            return;
        }

        console.log('multi only')
        console.log(sFormData?.[inputId])

        if (sFormData?.[inputId]?.includes(option)) {
            // Remove from options selected
            setSFormData((prevState) => ({
              ...prevState,
              [inputId]: prevState[inputId].filter((item) => item !== option)
            }));
        } else {
            setSFormData((prevState) => ({
              ...prevState,
              [inputId]: [...prevState[inputId], option]
            }));
        }

    }

    // Convert config input data to html elements label + input
    let formFields = inputData.map((input, index) => {
        if (input.label) {
            const inputId = generateId(input.label); // label as identifier

            if (input.type === "number") {
                return (
                  <div className="input-wrap" key={inputId}>
                    <div className="input-label" style={config?.style?.label ? config.style.label : {}}>
                      <span>{input.label}</span>
                      {input.tooltip && <ToolTip content={input.tooltip} />}
                    </div>
                    <ValidatedNumberInput
                        onChange={(event) => handleInputChange(inputId, event)}
                        name={inputId}
                        value={sFormData?.[inputId]}
                        style={config?.style?.input ? config.style.input : {}}
                        validators={['required', "matchRegexp:^[0-9 ()+-]+$"]}
                        errorMessages={['Please provide a value.', 'Only use numbers.']}
                    />
                  </div>
                )
            } else if (input.type === "select" && input?.options?.length) {

                let multiSelect = input["multi-select"] ? input["multi-select"] : false;

                return (
                  <div style={{position: 'relative'}} key={inputId}>
                      <div className="input-label" style={config?.style?.label ? config.style.label : {}}>
                          <span>{input.label}</span>
                          {input.tooltip && <ToolTip content={input.tooltip} />}
                      </div>
                      <div className="data-options">
                        {input.options.map((option) => {
                            if (option?.value) {
                                let selectedOption = sFormData?.[inputId]?.includes(option.value);

                                let customStyle = {};
                                if (config?.style?.option && selectedOption) {
                                    customStyle = config.style["option-selected"];
                                } else if (config?.style?.option) {
                                    customStyle = config.style.option;
                                }

                                // Allows for providing another class that can alter the appearence of an individual option
                                let additionalClass = option?.class ? option.class : "";

                                return (
                                    <a
                                        href="#"
                                        key={option.value}
                                        onClick={(event) => handleOptionSelected(inputId, option.value, multiSelect, event)}
                                        className={selectedOption ? "data-option data-option--selected " + additionalClass : "data-option " + additionalClass}
                                        style={customStyle}
                                    >
                                        {option?.icon &&
                                            <img className="data-option-icon" src={option.icon} alt={option.value + " icon"} />
                                        }
                                        {option.value}
                                        <img className="data-option-tick" src="/img/tick-in-circle-icon.png" alt="tick in circle icon" />
                                    </a>
                                )}
                            }
                        )}
                      </div>
                  </div>
                )
            } else if (input.type === "file") {
                let selectedFilesList = '';
                if (sFormData?.files?.[inputId]) {
                    let filesFromInput = [...sFormData.files[inputId]];
                    selectedFilesList = filesFromInput.map((file, idx) => {
                        if (filesFromInput.length > 1 && filesFromInput.length - 1 !== idx) {
                            return file?.name + ', ';
                        } else {
                            return file?.name;
                        }
                    });
                }

                let multiple = input?.multiple ? input?.multiple : false;

                return (
                  <div className="input-wrap" key={inputId} style={config?.style?.inputWrap ? config.style.inputWrap : {}}>
                    <div className="input-label" style={config?.style?.label ? config.style.label : {}}>
                        <span>{input.label}</span>
                        {input.tooltip && <ToolTip content={input.tooltip} />}
                    </div>
                    <div className="file-browse-wrap">
                      <div style={{
                              display: 'flex',
                              flexDirection: 'row'
                      }}>
                      <a className="small-button small-button--outline" style={{width: '130px', marginRight: 20}} onClick={e => inputRefs[index].current && inputRefs[index].current.click()}>Select a File</a>
                      </div>
                      <input ref={inputRefs[index]} className="file-input" type="file" multiple={multiple} onChange={(event) => handleFileChange(inputId, event)} />
                      {sFormData?.files?.[inputId] &&
                          <div style={{display: 'flex', alignItems: 'center', marginTop: 10}}>
                              <span style={{ marginRight: 6, fontSize: 14, fontWeight: 500 }}>Selected:</span>
                              <span style={{ fontSize: 14 }}>{selectedFilesList}</span>
                          </div>
                      }
                    </div>
                  </div>
                )
            } else if (input.type === "email") {
                return (
                  <div className="input-wrap" key={inputId} style={config?.style?.inputWrap ? config.style.inputWrap : {}}>
                    <div className="input-label" style={config?.style?.label ? config.style.label : {}}>
                        <span>{input.label}</span>
                        {input.tooltip && <ToolTip content={input.tooltip} />}
                    </div>
                    <ValidatedEmailInput
                        onChange={(event) => handleInputChange(inputId, event)}
                        name={inputId}
                        value={sFormData?.[inputId]}
                        style={config?.style?.input ? config.style.input : {}}
                        validators={['required']}
                        errorMessages={['An email address is required.']}
                    />
                  </div>
                )
            } else if (input.type === "textarea") {
                return (
                  <div className="input-wrap" key={inputId}>
                    <div className="input-label" style={config?.style?.label ? config.style.label : {}}>
                        <span>{input.label}</span>
                        {input.tooltip && <ToolTip content={input.tooltip} />}
                    </div>
                    <ValidatedTextArea
                        onChange={(event) => handleInputChange(inputId, event)}
                        name={inputId}
                        value={sFormData?.[inputId]}
                        rows="5"
                        style={config?.style?.input ? config.style.input : {}}
                    />
                  </div>
                )
            } else if (input.type === "text") {
                let regexValidation = "matchRegexp:^[-'a-zA-Z0-9À-ÖØ-öø-ſ\\s]+$";
                let regexValidationMsg = "Only use letters and numbers.";
                if (input.label.includes("Mobile Number") || input.label.includes("Phone Number")) {
                    // Adjustment to support area codes and other phone number symbols
                    regexValidation = "matchRegexp:^[0-9 ()+-]+$";
                    regexValidationMsg = "Only use numbers.";
                }
                return (
                  <div className="input-wrap" key={inputId} style={config?.style?.inputWrap ? config.style.inputWrap : {}}>
                    <div className="input-label" style={config?.style?.label ? config.style.label : {}}>
                        <span>{input.label}</span>
                        {input.tooltip && <ToolTip content={input.tooltip} />}
                    </div>
                    <ValidatedTextInput
                        onChange={(event) => handleInputChange(inputId, event)}
                        name={inputId}
                        value={sFormData?.[inputId]}
                        style={config?.style?.input ? config.style.input : {}}
                        validators={['required', regexValidation]}
                        errorMessages={['This field is required.', regexValidationMsg]}
                    />
                  </div>
                )
            }
        }
    })


    const handleFormError = () => {
        // Move us to the top of the form to adjust relevant input error
        document.getElementById("form-anchor").scrollIntoView();
    }


    const handleSubmit = (event) => {
        // prevent browser window jump for link
        event.preventDefault();

        setSButtonLoading(true);
        let formData = new FormData();

        formData.append("page", window.location.pathname);
        formData.append("reference", config?.reference ? config.reference : "Enquiry");

        if (config["send-to"]) {
            formData.append("send-to", config["send-to"]);
        }

        for (const key in sFormData) {
            if (key === "files") {
                for (const inputId in sFormData[key]) {
                    let filesSelected = sFormData[key][inputId];
                    [...filesSelected].forEach((file, i) => {
                        if (file !== "none") {
                            formData.append("attachments[]", file);
                        }
                    });
                }
            } else {
                let value = sFormData[key];
                if (Array.isArray(value)) {
                    value = value.join(", ");
                }

                formData.append(key, value);
            }
        }

        return fetch('https://ylo.io/api/custom-form', {
          method: 'POST',
          body: formData
        })
        .then((response) => response.json())
        .then((responseJson) => {

          if (responseJson.success) {
              setSButtonLoading(false);
              setSFormSubmitted(true);
          } else {
              setSButtonLoading(false);
          }

        })
        .catch((error) => {
          console.log(error);
          setSButtonLoading(false);
        });
    }

    useEffect(() => {
        // Redirect to success page if relevant slug provided
        if (sFormSubmitted) {
            if (config?.["success-page"]) {
                history?.push('/' + config["success-page"]);
            }
        }
    }, [sFormSubmitted])

    let submitButton = sButtonLoading ? (
        <div className="loading-spinner-button">
          <Spinner name="pacman" color="#65c84c" fadeIn="half" />
        </div>
    ) : (
        <input
            type="submit"
            value={config?.["submit-button-label"]}
            style={config?.style?.button ? config.style.button : {}}
            className="form-button fadeIn"
        />
    );

    return (
        <div id="form-area" style={{position: 'relative'}}>
            <div id="form-anchor" style={{ position: 'absolute', top: '-100px' }}></div>
            <h2>{config?.title}</h2>
            <ValidatorForm onSubmit={handleSubmit} onError={handleFormError} className="form-block" style={config?.style?.form ? config.style.form : {}} ref={form}>
                {sFormSubmitted ? (
                    <div className="alert alert--ylo">
                        Thank you for contacting us. One of our team will be in touch as soon as possible.
                    </div>
                ): (
                    <>
                    {formFields}
                    <div>
                        {submitButton}
                    </div>
                    </>
                )}
            </ValidatorForm>
        </div>
    )
}

export default CustomForm;
