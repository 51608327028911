import React from "react";
import Truncate from 'react-truncate';
import qs from "query-string";

import Deal from "./Deal";
import StickyDeal from "./StickyDeal";

class ProductDeals extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      monthlyData: '',
      deals: [],
      recommendedDeals: [],
      stickyDeal: '',
      filters: {
        dealType: ''
      },
      numOfConnections: 1
    }

    this.handleConnectionsChange = this.handleConnectionsChange.bind(this);
  }


  componentDidMount = () => {

    this.getDeals();

  }


  componentDidUpdate = (prevProps) => {
    if (prevProps.deals !== this.props.deals || prevProps.recommendedDeals !== this.props.recommendedDeals) {
      this.getDeals();
    }
  }


  shuffleArray = (array) => {

    // FisherYates

    let i = array.length;
    while (i--) {
      const ri = Math.floor(Math.random() * (i + 1));
      [array[i], array[ri]] = [array[ri], array[i]];
    }
    return array;

  }




  setupStickyDeal = (deals) => {

    let stickyDeal = '';

    if (this.props.urlParams) {
      if (this.props.urlParams.externalid) {

        const externalID = this.props.urlParams.externalid;
        const urlDeal = deals.find(function( obj ) {
            return obj.external_id === externalID;
        });

        if (urlDeal && urlDeal.retailer) {
          // We have a valid deal for the url reference
          stickyDeal = urlDeal;
        }

      }
    }

    return stickyDeal;

  }



  setupRecommendations = (deals) => {

    // let recommendations = [
    //   {"provider": "O2", "data": "UNLIMITED"},
    //   {"provider": "Vodafone", "data": "5GB"},
    //   {"provider": "EE", "data": "8GB"}
    // ];

    const dealsCopy = [...deals];
    const dealsOrderedByMonthlyPrice = dealsCopy.sort(function (a, b) {
      // if (a.month_cost) {
      //   return parseFloat(a.month_cost) - parseFloat(b.month_cost);
      // }

      // if (parseFloat(a.month_cost) >= parseFloat(b.month_cost)) {
      //   let initialCost = parseFloat(a.initial_cost) - parseFloat(b.initial_cost);
      //   if (initialCost < 10) {
      //     return initialCost;
      //   }
      // }

      // 45 - 55 = -10
      // return -10
      //
      // 60 - 40 =  20


      //if (parseFloat(a.month_cost) - parseFloat(b.month_cost) && parseFloat(a.initial_cost) < 100) {
        return parseFloat(a.month_cost) - parseFloat(b.month_cost);
      //}

      //return b.month_cost - a.month_cost || a.initial_cost - b.initial_cost;

      // if(a.duration && a.duration === b.duration)
      // {
      //     if(a.initial_cost === b.initial_cost)
      //     {
      //         return b.month_cost - a.month_cost;
      //     }
      //     return a.initial_cost - b.initial_cost;
      // }
      //
      // return a.duration - b.duration;


      // if (a.month_cost > b.month_cost || a.month_cost > 60) return -1;
      // if (a.month_cost < b.month_cost) return 1;
      //
      // if (a.initial_cost > b.initial_cost) return -1;
      // if (a.initial_cost < b.initial_cost) return 1;

    });

    let dealsOrderedByPrice = [];
    dealsOrderedByMonthlyPrice.forEach((deal, i) => {
      if ((deal.month_cost && parseFloat(deal.month_cost) < 60) && parseFloat(deal.initial_cost) < 100) {
        dealsOrderedByPrice.unshift(deal);
      } else {
        dealsOrderedByPrice.push(deal);
      }
    });

    // const dealsOrderedByUpfrontPrice = dealsCopy.sort(function (a, b) {
    //     return parseFloat(a.initial_cost) - parseFloat(b.initial_cost);;
    // });
    //&& parseFloat(a.initial_cost) - parseFloat(b.initial_cost)

    //console.log(dealsOrderedByPrice);

    const cheapestDeal = dealsOrderedByPrice[0];

    // Make sure we get every other deal not including the best/cheapest we pulled out
    deals = deals.filter(function( obj ) {
        return obj.external_id !== dealsOrderedByPrice[0].external_id;
    });

    // Get a random deal and add that below
    let randomSelection = deals.slice(0, 1);

    // Set the recommendations
    let recommendations = [cheapestDeal, ...randomSelection];


    // Set recommendations per backend provided preference
    if (this.props.recommendedDeals && this.props.recommendedDeals.length > 0) {
      recommendations = this.props.recommendedDeals;
    }

    // Get any recommendations or a particulary monthly data option from the URL

    if (this.props.urlParams) {
      if (this.props.urlParams.externalid) {

        // Add to top of established recommendations via url defined id
        const externalID = this.props.urlParams.externalid;
        const urlDeal = deals.find(function( obj ) {
            return obj.external_id === externalID;
        });

        if (urlDeal && urlDeal.retailer) {
          // We have a valid deal for the url reference
          recommendations.unshift(urlDeal);
        }

      } else if (this.props.urlParams.data) {

        // Set the deal picker section to highlight a particular set of options per URL
        this.setState({
          monthlyData: this.props.urlParams.data.toUpperCase()
        });

      }
    }

    return recommendations;

  }



  getDeals = () => {

      // Shuffle array of deals
      const availableDeals = [...this.props.deals];

      // Get Sticky Deal
      let stickyDeal = this.setupStickyDeal(availableDeals);

      if (stickyDeal) {
        this.setState({
          stickyDeal: stickyDeal
        });
      }

      // Create shuffle of deals
      const shuffledDeals = this.shuffleArray(availableDeals);

      // Get recommended
      let recommendedDeals = this.setupRecommendations(shuffledDeals);


      this.setState({
        recommendedDeals: recommendedDeals,
        deals: shuffledDeals
      });


  }



  handleConnectionsChange = (event) => {
    this.setState({numOfConnections: event.target.value});
  }


  handleDataOptionSelected = (monthlyData, event) => {
    // prevent browser window jump for link
    event.preventDefault();
    this.setState({monthlyData: monthlyData});
  }


  handleOptionSelected = (reference, value, event) => {

    // prevent browser window jump for link
    event.preventDefault();

    this.setState(prevState => {
      let filters = { ...prevState.filters };  // creating copy of current state variable

      // Set the filter or remove it if already set currently (toggle)
      if (filters[reference] === value) {
        filters[reference] = '';
      } else {
        filters[reference] = value;
      }

      return { filters };
    });

  }




  parseButtonPress = (url) => {

    // Tweak URL ------------------------------------------------
    // Adjust the URL by adding any parameters we need for tracking, including
    // google gclid if present in the existing url, to track ad conversions
    console.log(url);

    // Split the deal url provided
    const dealURLArray = url.toString().split("?");

    // Set initial values
    let initalURLParam = '?';
    let gclid = '';

    // Where possible use the original url when coming to the website, collected via script in index.html head
    // This is designed to prevent the issue of the url losing the gclid due to other clicks within the webapp wiping it
    const originalURL = sessionStorage.getItem('originalURL');
    const originalURLArray = originalURL?.toString().split("?");
    if(originalURL && originalURLArray[1]) {
      // Use originalURL when first visiting site as basis for extracting gclid
      gclid = qs.parse(originalURLArray[1], { ignoreQueryPrefix: true }).gclid;
      //console.log(gclid);
    } else {
      // Use back up url query params from props for extracting gclid
      gclid = qs.parse(this.props.queryParams, { ignoreQueryPrefix: true }).gclid;
    }

    // Append gclid if present to new url
    if (gclid) {
      initalURLParam = '?clickref=' + gclid + "&"
    }

    // Add randomly generated User ID, which will be saved and stored with any
    // email they provide to ensure free gift from verfied purchase


    // Assemble final/new url
    let newURL = dealURLArray[0] + initalURLParam + dealURLArray[1];
    //console.log(newURL);

    // Parent component opens url, pass through new URL
    this.props.buttonPress(newURL);

  }



  render() {

    let recommendedDeals = null;
    let deals = null;


    recommendedDeals = this.state.recommendedDeals.map((deal, index) => {

      let dealDisplayType = 'contract';

      if ((!deal.month_cost || deal.month_cost === "0.00")) {
        dealDisplayType = 'simfree';
      }

      return (
        <Deal
          key={index.toString()}
          index={index}
          dealDisplayType={dealDisplayType}
          dealInfo={deal}
          buttonPress={this.parseButtonPress}
        />
      );

    });



    deals = this.state.deals.map((deal, index) => {

      if (
        (!deal.month_cost || deal.month_cost === "0.00") &&
        this.state.filters.dealType === 'Sim Free'
      ) {

        return (
          <Deal
            key={index.toString()}
            index={index}
            dealDisplayType='simfree'
            dealInfo={deal}
            buttonPress={this.parseButtonPress}
          />
        );

      } else if (deal.month_cost && this.state.filters.dealType === 'Pay Monthly') {

        let monthlyDataMin = 0;
        let monthlyDataMax = 0;
        let monthlyDataUnlimited = false;

        switch(this.state.monthlyData)
        {
           case 'Any':
             monthlyDataMin = 500;
             monthlyDataMax = 500000;
             monthlyDataUnlimited = true;
           break;
           case '1GB':
             monthlyDataMin = 500;
             monthlyDataMax = 5000;
           break;
           case '5GB':
            monthlyDataMin = 5000;
            monthlyDataMax = 10000;
           break;
           case '10GB':
            monthlyDataMin = 10000;
            monthlyDataMax = 20000;
           break;
           case '20GB':
            monthlyDataMin = 20000;
            monthlyDataMax = 50000;
           break;
           case '50GB':
            monthlyDataMin = 50000;
            monthlyDataMax = 100000;
           break;
           case '100GB':
            monthlyDataMin = 100000;
            monthlyDataMax = 500000;
           break;
           case 'Unlimited':
            monthlyDataUnlimited = true;
           break;
        }


        let dataAllowanceNumber = parseInt(deal.inc_data);

        if (
          (monthlyDataUnlimited && deal.inc_data === "Unlimited") ||
          (dataAllowanceNumber >= monthlyDataMin && dataAllowanceNumber <= monthlyDataMax)
        ) {

          return (
            <Deal
              key={index.toString()}
              index={index}
              dealDisplayType='contract'
              dealInfo={deal}
              buttonPress={this.parseButtonPress}
            />
          );

        }

      }

    });



    let productCurrOptions = '';

    this.props.selectedOptions.forEach((sOpt, index) => {
      productCurrOptions += sOpt.option;

      if (index != this.props.selectedOptions.length - 1) {
        productCurrOptions += ', ';
      }
    });


    // <div className="device-summary">
    //   <div className="mini-product-image"><img className="fill-div" src={this.props.productImage} alt="product image" /></div>
    //   <div className="device-summary-text">
    //     <h5>{this.props.productName}, {productCurrOptions}</h5>
    //     <h5 style={{ color: '#737373' }}>Chosen Handset</h5>
    //   </div>
    // </div>


    return (
      <div className="contract-deals">

        <div className="contract-deals-block">

          <h3 style={{ marginBottom: '3px' }}>Recommended Deals</h3>
          <h5 style={{ color: '#737373' }}></h5>

          <div className="deals">
            {recommendedDeals}
          </div>

        </div>


        <div className="contract-deals-block" style={{ marginBottom: 0 }}>

          <h3 style={{ marginBottom: '20px' }}>Find a Deal</h3>

          <div style={{ height: 1, width: '100%', background: '#EEEEEE', marginBottom: '26px'}}></div>

          <div className="option-label"><h4>Type of Deal</h4></div>
          <div className="filter-options">
            <a href="#" onClick={this.handleOptionSelected.bind(this, 'dealType', 'Pay Monthly')} className={'Pay Monthly' === this.state.filters.dealType ? "filter-option filter-option--selected" : "filter-option"}>Pay Monthly</a>
            <a href="#" onClick={this.handleOptionSelected.bind(this, 'dealType', 'Sim Free')} className={'Sim Free' === this.state.filters.dealType ? "filter-option filter-option--selected" : "filter-option"}>Pay As You Go | Sim Free</a>
          </div>

          {this.state.filters.dealType === 'Pay Monthly' &&
            <React.Fragment>
              <div className="option-label"><h4>Monthly Data</h4></div>
              <div className="filter-options">
                <a href="#" onClick={this.handleDataOptionSelected.bind(this, 'Any')} className={'Any' === this.state.monthlyData ? "filter-option filter-option--selected" : "filter-option"}>Any</a>
                <a href="#" onClick={this.handleDataOptionSelected.bind(this, '1GB')} className={'1GB' === this.state.monthlyData ? "filter-option filter-option--selected" : "filter-option"}>500MB - 5GB</a>
                <a href="#" onClick={this.handleDataOptionSelected.bind(this, '5GB')} className={'5GB' === this.state.monthlyData ? "filter-option filter-option--selected" : "filter-option"}>5GB - 10GB</a>
                <a href="#" onClick={this.handleDataOptionSelected.bind(this, '10GB')} className={'10GB' === this.state.monthlyData ? "filter-option filter-option--selected" : "filter-option"}>10GB - 20GB</a>
                <a href="#" onClick={this.handleDataOptionSelected.bind(this, '20GB')} className={'20GB' === this.state.monthlyData ? "filter-option filter-option--selected" : "filter-option"}>20GB - 50GB</a>
                <a href="#" onClick={this.handleDataOptionSelected.bind(this, '50GB')} className={'50GB' === this.state.monthlyData ? "filter-option filter-option--selected" : "filter-option"}>50GB - 100GB</a>
                <a href="#" onClick={this.handleDataOptionSelected.bind(this, '100GB')} className={'100GB' === this.state.monthlyData ? "filter-option filter-option--selected" : "filter-option"}>100GB +</a>
                <a href="#" onClick={this.handleDataOptionSelected.bind(this, 'Unlimited')} className={'Unlimited' === this.state.monthlyData ? "filter-option filter-option--selected" : "filter-option"}>Unlimited</a>
              </div>
            </React.Fragment>
          }

        </div>


        <div className="contract-deals-block" style={
          ((this.state.filters.dealType === 'Pay Monthly' && this.state.monthlyData) ||
          this.state.filters.dealType === 'Sim Free') ? {opacity: '1', width: '100%'} : {opacity: '0.4', width: '100%'}
        }>

          {((this.state.filters.dealType === 'Pay Monthly' && this.state.monthlyData) || this.state.filters.dealType === 'Sim Free') &&
            <div className="deals">
              {deals}
            </div>
          }

        </div>

        {this.state.stickyDeal &&
          <StickyDeal
            dealInfo={this.state.stickyDeal}
            buttonPress={this.parseButtonPress}
          />
        }

      </div>
    );

  }

}

export default ProductDeals;
