import React from 'react';
import {CardElement} from '@stripe/react-stripe-js';
import Spinner from "react-spinkit";
import { ValidatorForm } from 'react-form-validator-core';

import ValidatedTextInput from "./ValidatedTextInput";
import ValidatedEmailInput from "./ValidatedEmailInput";
import ValidatedNumberInput from "./ValidatedNumberInput";

import * as api from "../../../config/api";
import OrderSummary from "../OrderSummary";


class PaymentForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      buttonLoading: false,
      formSubmitted: false,
      displayError: false,
      errorMessage: '',
      billingName: this.props.checkout.contactName,
      billingAddressLine1: this.props.checkout.addressLine1,
      billingAddressLine2: this.props.checkout.addressLine2,
      billingAddressCity: this.props.checkout.addressCity,
      billingPostcode: this.props.checkout.postcode
    }

    this.handleBillingNameChange = this.handleBillingNameChange.bind(this);
    this.handleBillingAddressLine1Change = this.handleBillingAddressLine1Change.bind(this);
    this.handleBillingAddressLine2Change = this.handleBillingAddressLine2Change.bind(this);
    this.handleBillingAddressCityChange = this.handleBillingAddressCityChange.bind(this);
    this.handleBillingPostcodeChange = this.handleBillingPostcodeChange.bind(this);

  }

  handleBillingNameChange = (event) => {
    this.setState({billingName: event.target.value});
  }

  handleBillingAddressLine1Change = (event) => {
    this.setState({billingAddressLine1: event.target.value});
  }

  handleBillingAddressLine2Change = (event) => {
    this.setState({billingAddressLine2: event.target.value});
  }

  handleBillingAddressCityChange = (event) => {
    this.setState({billingAddressCity: event.target.value});
  }

  handleBillingPostcodeChange = (event) => {
    this.setState({billingPostcode: event.target.value});
  }



  handleSubmit = () => {

    // Tell server to create stripe payment intent for order, which will get finalised later in confirmPayment()

    this.setState({buttonLoading: true});

    const orderID = this.props.checkout.orderID;
    const billingEmail = this.props.checkout.contactEmail;


    const data = {
      "payment_method": "stripe",
      "order_id": orderID
    };
    const bodyJSON = JSON.stringify(data);

    return fetch(api.woocommerceURL + 'create-payment-intent', {
      method: 'POST',
      headers: {
        'Authorization': api.woocommerceAuth,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: bodyJSON
    })
    .then((response) => response.json())
    .then((responseJson) => {

      //console.log(responseJson);

      // Send client secret which is essentially a payment intent from server
      // back to client to finalise card payment with stripe
      this.confirmPayment(responseJson.clientSecret);

    })
    .catch((error) => {
      //console.log(error);
      this.handlePaymentError("Something went wrong processing your payment, double check your details and try again. If the problem continues, call us: (01604) 946888");
    });

  }


  confirmPayment  = async (clientSecret) => {

    // Take clientsecret based on payment intent from server and use it process payment
    // with confirmCardPayment

    const {stripe, elements} = this.props

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make  sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const orderID = this.props.checkout.orderID;
    const billingEmail = this.props.checkout.contactEmail;

    const result = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            name: this.state.billingName,
            email: billingEmail,
            address: {
              line1: this.state.billingAddressLine1,
              line2: this.state.billingAddressLine2,
              city: this.state.billingAddressCity,
              postal_code: this.state.billingPostcode,
              country: 'GB'
            }
          },
        }
      });


      if (result.error) {

        // Show error to your customer (e.g., insufficient funds)
        // Your postal code is incomplete.
        //console.log(result.error.message);
        this.handlePaymentError(result.error.message);

      } else {

        // The payment has been processed!
        if (result.paymentIntent.status === 'succeeded') {
          // Show a success message to your customer
          // There's a risk of the customer closing the window before callback
          // execution. Set up a webhook or plugin to listen for the
          // payment_intent.succeeded event that handles any business critical
          // post-payment actions.

          this.setState({buttonLoading: false});
          this.props.history.push('/checkout/order-received');

        }

      }

  }



  handlePaymentError = (message) => {

    this.setState({
      displayError: true,
      errorMessage: message,
      buttonLoading: false
    });

    window.scrollTo(0, 0);

  }


  handleOrderSummaryButton = () => {
    this.refs['paymentForm'].submit();
  }



  render() {

    // let submitButton = this.state.buttonLoading ? (
    //   <div className="loading-spinner-button">
    //     <Spinner name="pacman" color="#65c84c" fadeIn="half" />
    //   </div>
    // ) : (
    //   <input type="submit" value="Place Order" className="medium-button fadeIn" style={{ width: '160px'}} />
    // );

    const topAlert = this.state.displayError ? (
      <div className="alert alert--red">
        {this.state.errorMessage}
      </div>
    ) : (
      <div>
      </div>
    );

    return (
      <div className="content-with-sidebar">
        <ValidatorForm onSubmit={this.handleSubmit} ref="paymentForm" style={{ maxWidth: '500px' }}>

          <div>
            <h3 style={{ marginBottom: '28px' }}>Payment Details</h3>
            {topAlert}
          </div>

          <div className="input-group">
            <div className="input-wrap">
              <div className="input-label">Name on Card</div>
              <ValidatedTextInput
                onChange={this.handleBillingNameChange}
                name="billingName"
                value={this.state.billingName}
                validators={['required', "matchRegexp:(^[-'a-zA-ZÀ-ÖØ-öø-ſ\\s]+$)"]}
                errorMessages={['Please provide your name.', 'Ensure you only use letters.']}
              />
            </div>
          </div>

          <div className="input-wrap" style={{ paddingBottom: '5px', marginBottom: '30px', borderBottom: '1px solid #f1f1f1' }}>
            <div className="input-label">Card Details</div>
            <div className="payment-input">
              <CardElement options={{ hidePostalCode: true, style: { base: {fontSize: '16px', fontFamily: 'Roboto, sans-serif'} } }}  />
            </div>
          </div>

          <div className="input-group">
            <div className="input-wrap">
              <div className="input-label">Address Line 1</div>
              <ValidatedTextInput
                onChange={this.handleBillingAddressLine1Change}
                name="billingAddressLine1"
                value={this.state.billingAddressLine1}
                validators={['required', "matchRegexp:^[-'a-zA-Z0-9À-ÖØ-öø-ſ\\s]+$"]}
                errorMessages={['An address is required.', 'Only use letters and numbers.']}
              />
            </div>

            <div className="input-wrap">
              <div className="input-label">Address Line 2</div>
              <ValidatedTextInput
                onChange={this.handleBillingAddressLine2Change}
                name="billingAddressLine2"
                value={this.state.billingAddressLine2}
                validators={["matchRegexp:^[-'a-zA-Z0-9À-ÖØ-öø-ſ\\s]+$"]}
                errorMessages={['Only use letters and numbers.']}
              />
            </div>
          </div>

          <div className="input-group">
            <div className="input-wrap">
              <div className="input-label">City/Town</div>
              <ValidatedTextInput
                onChange={this.handleBillingAddressCityChange}
                name="billingAddressCity"
                value={this.state.billingAddressCity}
                validators={['required', "matchRegexp:^[-'a-zA-ZÀ-ÖØ-öø-ſ\\s]+$"]}
                errorMessages={['A city or town is required.', 'Only use letters.']}
              />
            </div>

            <div className="input-wrap">
              <div className="input-label">Postcode</div>
              <ValidatedTextInput
                onChange={this.handleBillingPostcodeChange}
                name="billingPostcode"
                value={this.state.billingPostcode}
                validators={['required', "matchRegexp:^([A-Za-z][A-Ha-hJ-Yj-y]?[0-9][A-Za-z0-9]? ?[0-9][A-Za-z]{2}|[Gg][Ii][Rr] ?0[Aa]{2})$"]}
                errorMessages={['A postcode is required.', 'Postcodes in the UK only.']}
              />
            </div>
          </div>

        </ValidatorForm>

        <OrderSummary
          order={this.props.order}
          buttonPress={this.handleOrderSummaryButton}
          buttonLoading={this.state.buttonLoading}
          buttonText="Place Order ->"
        />
      </div>
    );

  }
}

export default PaymentForm;
