import { useFilePicker } from 'use-file-picker';
import React, {useEffect} from 'react';

export default function FilePicker(props) {
  const [openFileSelector, { filesContent, loading, errors, plainFiles, clear }] = useFilePicker({
    multiple: true,
    readAs: 'DataURL', // availible formats: "Text" | "BinaryString" | "ArrayBuffer" | "DataURL"
    accept: ['.txt', '.pdf', '.doc', '.docx'],
    limitFilesConfig: { min: 1, max: 10 }
    // readFilesContent: false, // ignores file content
  });

  useEffect(() => {
    if (plainFiles.length > 0) {
      props.processFiles(plainFiles);
    }
  }, [plainFiles]);

  if (errors.length) {
    return (
      <div>
        <button onClick={() => openFileSelector()}>Something went wrong, retry! </button>
        {errors[0].fileSizeTooSmall && 'File size is too small!'}
        {errors[0].fileSizeToolarge && 'File size is too large!'}
        {errors[0].readerError && 'Problem occured while reading file!'}
        {errors[0].maxLimitExceeded && 'Too many files'}
        {errors[0].minLimitNotReached && 'Not enought files'}
      </div>
    );
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <div className="file-browse-wrap">
        <a className="small-button small-button--outline" style={{width: '74px'}} onClick={() => openFileSelector()}>Browse</a>
        <div>
          {plainFiles.map(file => {
            return (
              <span className="file-name" key={file.name}>{file.name}</span>
            )
          })}
        </div>
      </div>
    </div>
  );
}
