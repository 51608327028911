import React from "react";
import { Link } from "react-router-dom";
import qs from "query-string";
import {Helmet} from "react-helmet";

import CartItem from "./CartItem";

class Cart extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      checkoutLink: '/checkout/details',
      totalCost: 0.00
    }

    this.saveCartAsOrder = this.saveCartAsOrder.bind(this);

  }



  componentDidMount = () => {


    let cartContents = JSON.parse(localStorage.getItem('cartContents'));


    if (this.props.location && this.props.location.search) {

      let newCartContents = JSON.parse(qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).add);
      newCartContents.quantity = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).quantity;

      if (cartContents) {
        cartContents.push(newCartContents);
      } else {
        cartContents = [newCartContents];
      }

    }


    if (cartContents) {

      this.setState({
        cartContents: cartContents
      }, function(){

        if (this.state.cartContents) {

          localStorage.setItem('cartContents', JSON.stringify(this.state.cartContents));

          this.checkForContract();
          this.calcTotal();

        }

      });

    }

    window.scrollTo(0, 0);

  }



  checkForContract = () => {
      this.state.cartContents.forEach((cartItem, index) => {
        if (cartItem.phoneContract || cartItem.simOnlyContract || cartItem.fibreContract) {
          this.setState({
            checkoutLink: '/checkout/contract'
          });
        }
      });
  }


  calcTotal = () => {

    let runningTotal = 0.00;

    if (this.state.cartContents.length === 0) {

      this.setState({
        totalCost: runningTotal.toFixed(2)
      });

    } else {

      this.state.cartContents.forEach((cartItem, index) => {
        runningTotal += parseFloat(cartItem.price);

        if (this.state.cartContents.length - 1 === index) {
          this.setState({
            totalCost: runningTotal.toFixed(2)
          });
        }
      });

    }

  }



  removeFromCart = (indexToRemove) => {
    //console.log(indexToRemove);

    let newCartContents = [];

    const cartContents = this.state.cartContents;
    newCartContents = cartContents.filter((item, index) => index !== indexToRemove);


    this.setState({
      cartContents: newCartContents
    }, function () {
      console.log(this.state.cartContents);

      if (this.state.cartContents.length === 0) {
        localStorage.removeItem('cartContents');
      } else {
        localStorage.setItem('cartContents', JSON.stringify(this.state.cartContents));
      }

      this.calcTotal();

    });

  }


  updateCart = (indexToUpdate, newQuantity) => {
    //console.log(indexToRemove);

    let runningTotal = 0.00;
    let newCartContents = this.state.cartContents;

    newCartContents.forEach((cartItem, index) => {

      if (index === indexToUpdate) {
        cartItem.quantity = parseInt(newQuantity);
      }

      runningTotal += parseFloat(cartItem.price * cartItem.quantity);

      if (newCartContents.length - 1 === index) {
        this.setState({
          cartContents: newCartContents,
          totalCost: runningTotal.toFixed(2)
        }, function () {
          console.log(this.state.cartContents);

          localStorage.setItem('cartContents', JSON.stringify(this.state.cartContents));
        });
      }
    });

  }


  saveCartAsOrder = () => {

    // TO REMOVE - I think probably unneccessary, order summary now takes from cartContents
    // Save all cart items to orderContents
    const cartContents = this.state.cartContents;
    localStorage.setItem('orderContents', JSON.stringify(cartContents));

    //localStorage.setItem('orderInfo', JSON.stringify({ id: '', }));
  }



  render() {


    if (this.state.cartContents && this.state.cartContents.length > 0) {

      let cartItems = null;
      cartItems = this.state.cartContents.map((cartItem, index) => {

          return (
            <CartItem
              key={index.toString()}
              index={index}
              item={cartItem}
              updateCart={this.updateCart}
              removeFromCart={this.removeFromCart}
            />
          );

      });


      return (
        <div className="content-wrap">

            <Helmet>
              <title>In Your Bag - ylo</title>
              <meta name="description" content="Keep track of all the items in your bag and easily add more of your chosen items." />
            </Helmet>

            <div className="page-intro">
              <h1>In Your Bag</h1>
            </div>

            <div className="cart">
              <div className="cart-inner">

                {cartItems}

                <div>
                  <h5 style={{ marginBottom: '6px' }}>Free Delivery</h5>
                </div>

                <div className="order-total">
                  <div></div>
                  <div style={{ textAlign: 'right' }}>
                    <h5 style={{ color: '#a5a5a5', marginBottom: '2px' }}>Pay Today</h5>
                    <h3 style={{ marginBottom: '6px' }}>£{this.state.totalCost}</h3>
                  </div>
                </div>

                <p style={{ marginTop: '10px', textAlign: 'right' }}>
                  <img src="/img/footer-logos/visa.png" alt="Visa" style={{ width: '45px', height: 'auto', marginRight: '5px' }} />
                  <img src="/img/footer-logos/mastercard.png" alt="Mastercard" style={{ width: '45px', height: 'auto', marginRight: '5px' }} />
                  <img src="/img/footer-logos/ae.png" alt="American Express" style={{ width: '45px', height: 'auto', }} />
                </p>

                <div className="checkout-button-strip">
                  <Link className="medium-button medium-button--grey" to={"/"}>Continue Shopping</Link>
                  <Link className="medium-button" style={{ minWidth: '160px'}} to={this.state.checkoutLink} onClick={this.saveCartAsOrder}>Checkout -></Link>
                </div>

              </div>
            </div>

        </div>
      );

    } else {


      return (
        <div className="content-wrap">

            <div className="page-intro">
              <h1>In Your Bag</h1>
            </div>

            <div className="cart">
              <div className="cart-inner">

                <div className="cart-item">

                    <div className="item-summary">
                      <div className="item-summary-text">
                        <h5 style={{textTransform: 'none'}}>Looks like an empty bag.</h5>
                      </div>
                    </div>

                </div>

                <div>
                  <h5 style={{ color: '#a5a5a5', marginBottom: '6px' }}>Free Delivery</h5>
                </div>

                <div className="order-total">
                    <h5 style={{ color: '#a5a5a5', marginBottom: '2px' }}>Pay Today</h5>
                    <h3 style={{ marginBottom: '6px' }}>£0.00</h3>
                </div>

                <p style={{ marginTop: '10px', textAlign: 'right' }}>
                  <img src="/img/footer-logos/visa.png" alt="Visa" style={{ width: '45px', height: 'auto', marginRight: '5px' }} />
                  <img src="/img/footer-logos/mastercard.png" alt="Mastercard" style={{ width: '45px', height: 'auto', marginRight: '5px' }} />
                  <img src="/img/footer-logos/ae.png" alt="American Express" style={{ width: '45px', height: 'auto', }} />
                </p>

                <div className="checkout-button-strip">
                  <Link className="medium-button medium-button--grey" to={"/"}>Continue Shopping</Link>
                </div>

              </div>
            </div>

        </div>
      );


    }

  }

}

export default Cart;
