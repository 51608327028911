import React from "react";
import { Link } from "react-router-dom";

class Cart extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      quantity: this.props.item.quantity,
    }

  }



  componentDidMount = () => {
  }



  remove = (indexToRemove, event) => {
    //console.log(indexToRemove);

    event.preventDefault();

    this.props.removeFromCart(indexToRemove);

  }


  handleQuantityChange = (indexToUpdate, event) => {
   this.setState({quantity: event.target.value});

   this.props.updateCart(indexToUpdate, event.target.value);
  }



  render() {

        let itemTotal = (
          <div className="item-total">
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <h4 style={{ marginRight: '6px' }}>£{parseFloat(this.props.item.price * this.state.quantity).toFixed(2)}</h4><h5 style={{ color: '#a5a5a5', marginBottom: '20px' }}>cost</h5>
            </div>
          </div>
        );


        if (this.props.item.phoneContract) {

          itemTotal = (
            <div>
              <div className="contract-summary">
                <div className="plus-icon">
                  <div className="vertical"></div>
                  <div className="horizontal"></div>
                </div>
                <div className="icon"><img className="fill-div" src={this.props.item.contractPlanIcon} alt="mobile network icon" /></div>
                <div className="contract-summary-text">
                  <p>{this.props.item.contractMonthlyData} Monthly Data. Unlimited Calls & Texts</p>
                  <p style={{ color: '#a5a5a5' }}>{this.props.item.contractPlan}</p>
                </div>
              </div>

              <div className="item-total">
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <h4 style={{ marginBottom: '6px', marginRight: '6px' }}>£{parseFloat(this.props.item.contractMonthlyCost * this.state.quantity).toFixed(2)}</h4><h5 style={{ color: '#a5a5a5', marginBottom: '6px' }}>per month<span style={{fontSize: '15px', color: '#a5a5a5'}}> (exc VAT)</span></h5>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <h4 style={{ marginRight: '6px' }}>£{parseFloat(this.props.item.contractUpfrontCost * this.state.quantity).toFixed(2)}</h4><h5 style={{ color: '#a5a5a5', marginBottom: '20px' }}>up front<span style={{fontSize: '15px', color: '#a5a5a5'}}> (exc VAT)</span></h5>
                </div>

                <p style={{ color: '#a5a5a5', marginBottom: '20px' }}>The first payment for your contract will only be taken once your contract is approved.</p>
              </div>
            </div>
          );

        } else if (this.props.item.simOnlyContract) {

          itemTotal = (
            <div>
              <div className="item-total">
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <h4 style={{ marginBottom: '6px', marginRight: '6px' }}>£{parseFloat(this.props.item.contractMonthlyCost * this.state.quantity).toFixed(2)}</h4><h5 style={{ color: '#a5a5a5', marginBottom: '6px' }}>per month<span style={{fontSize: '15px', color: '#a5a5a5'}}> (exc VAT)</span></h5>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <h4 style={{ marginRight: '6px' }}>£{parseFloat(this.props.item.contractUpfrontCost * this.state.quantity).toFixed(2)}</h4><h5 style={{ color: '#a5a5a5', marginBottom: '20px' }}>up front<span style={{fontSize: '15px', color: '#a5a5a5'}}> (exc VAT)</span></h5>
                </div>

                <p style={{ color: '#a5a5a5', marginBottom: '20px' }}>The first payment for your contract will only be taken once your contract is approved.</p>
              </div>
            </div>
          );

        } else if (this.props.item.fibreContract) {

          itemTotal = (
            <div>
              <div className="item-total">
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <h4 style={{ marginBottom: '6px', marginRight: '6px' }}>£{parseFloat(this.props.item.contractMonthlyCost * this.state.quantity).toFixed(2)}</h4><h5 style={{ color: '#a5a5a5', marginBottom: '6px' }}>per month<span style={{fontSize: '15px', color: '#a5a5a5'}}> (exc VAT)</span></h5>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <h4 style={{ marginRight: '6px' }}>£{parseFloat(this.props.item.contractUpfrontCost * this.state.quantity).toFixed(2)}</h4><h5 style={{ color: '#a5a5a5', marginBottom: '20px' }}>up front<span style={{fontSize: '15px', color: '#a5a5a5'}}> (exc VAT)</span></h5>
                </div>

                <p style={{ color: '#a5a5a5', marginBottom: '20px' }}>The first payment for your broadband will only be taken once you're approved.</p>
              </div>
            </div>
          );

        }


        return (
          <div className="cart-item">

              <div className="item-summary">
                <div className="item-product">
                  {this.props.item.image &&
                    <div className="mini-product-image"><img className="fill-div" src={this.props.item.image} alt="product" /></div>
                  }
                  <div className="item-summary-text">
                    <h4>{this.props.item.name}</h4>
                    <p>{this.props.item.summary}</p>
                  </div>
                </div>

                <div className="edit-item">
                  <label style={{ marginRight: 12, }}>Quantity</label>
                  <input type="number" onChange={this.handleQuantityChange.bind(this, this.props.index)} name="connections" step="1" min="1" value={this.state.quantity} className="order-quantity-input" />
                  <a className="remove-button" href="#" onClick={this.remove.bind(this, this.props.index)}>X</a>
                </div>
              </div>

              {itemTotal}

          </div>
        );

  }

}

export default Cart;