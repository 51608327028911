import React from "react";
import Truncate from 'react-truncate';
import qs from "query-string";

class Deal extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    }

  }


  handleButtonPress = (url, event) => {

    // prevent browser window jump for link
    event.preventDefault();

    // Parent component handles press, pass through url
    this.props.buttonPress(url);

  }



  getCompanyLogo = (company) => {

    let filteredCompanyName = company.replace(/\s/g, "-");
    filteredCompanyName = filteredCompanyName.toLowerCase();

    return "/img/companies/" + filteredCompanyName + ".png";

    // switch(company.toLowerCase()) {
    //   case "mobiles.co.uk":
    //     return "/img/companies/mobiles-co-uk.png";
    //     break;
    //   case "the carphone warehouse":
    //     return "/img/companies/the-carphone-warehouse.png";
    //     break;
    //   case "id mobile":
    //     return "/img/companies/id-mobile.png";
    //     break;
    //   default:
    //     return "/img/companies/o2.png";
    // }

  }




  render() {


    // Names and Logo of Retailer

    const retailerName = this.props.dealInfo.retailer;
    const retailerLogoSrc = this.getCompanyLogo(this.props.dealInfo.retailer);

    let retailerLogo = (
      <img className={"deal-logo" + " deal-logo--" + retailerName.replace(/\s/g, "")} src={retailerLogoSrc} alt={retailerName} />
    );


    if (this.props.dealDisplayType === 'simfree') {

      // No monthly cost / tarrif, one off purchase ---------------------

      return (
        <div className="deal" key={this.props.index.toString()}>
          <div className="deal-info-wrap deal-info-wrap--contract">

              <div className="deal-details-wrap">

                <div className="deal-block" style={{ minWidth: 50 }}>
                  <h5 className="deal-block-info">{this.props.dealInfo.tariff}</h5>
                  <h5 className="deal-block-info-label">Type</h5>
                </div>

                <div className="deal-block-divider"></div>

                <div className="deal-cost">
                  <div className="deal-block">
                    <h5 className="deal-block-info">£{this.props.dealInfo.initial_cost}</h5>
                    <h5 className="deal-block-info-label">Total</h5>
                  </div>

                  <div className="deal-block-divider deal-block-divider--invisible"></div>
                </div>

              </div>

              <div className="deal-buy-and-extras">
                <div>
                  <a href="#" onClick={this.handleButtonPress.bind(this, this.props.dealInfo.deep_link)} className="medium-button" style={{width: 120, marginRight: 20}}>Get The Deal</a>
                </div>

                <div className="deal-logos">
                  {retailerLogo}
                </div>
              </div>

            </div>
          </div>
        );

    } else {

      // Contract Deals ----------------------


      // Names and Logo of Network
      const networkName = this.props.dealInfo.network;
      const networkLogoSrc = this.getCompanyLogo(this.props.dealInfo.network);

      // Set display text for data allowance

      let dataAllowance = this.props.dealInfo.inc_data;
      let dataAllowanceNumber = parseInt(this.props.dealInfo.inc_data);

      if (dataAllowanceNumber >= 1000) {
        dataAllowance = (dataAllowanceNumber / 1000) + 'GB';
      } else if (dataAllowanceNumber < 1000) {
        dataAllowance = dataAllowanceNumber + 'MB';
      }


      let incMinutesTexts = (
        <React.Fragment>
          <div className="deal-block">
            <h5 className="deal-block-info">{this.props.dealInfo.inc_minutes}</h5>
            <h5 className="deal-block-info-label">Minutes</h5>
          </div>
          <div className="deal-block-divider"></div>
          <div className="deal-block">
            <h5 className="deal-block-info">{this.props.dealInfo.inc_texts}</h5>
            <h5 className="deal-block-info-label">Texts</h5>
          </div>
        </React.Fragment>
      );

      if (this.props.dealInfo.inc_minutes === "Unlimited" && this.props.dealInfo.inc_texts === "Unlimited") {
        incMinutesTexts = (
          <div className="deal-block">
            <h5 className="deal-block-info">{this.props.dealInfo.inc_minutes}</h5>
            <h5 className="deal-block-info-label">Minutes & Texts</h5>
          </div>
        );
      }


      let extras = null;
      let displayExtras = false;

      if (this.props.dealInfo.extras) {

        const extrasJson = JSON.parse(this.props.dealInfo.extras);

        if (extrasJson.groups) {

          extras = extrasJson.groups.map((extraGroup) => {

            if (extraGroup["Bundled Products"]) {

              return extraGroup["Bundled Products"].map((extra, i) => {

                displayExtras = true;

                let giftTitle = extra["Product"][0].title;

                if (extra["Product"][0].title === "Sony Playstation 5 1TB White + FIFA 21 (1TB White)") {
                    giftTitle = "FIFA 21 (PS5)";
                } else if (extra["Product"][0].title === "Microsoft Xbox Series X White 1TB + FIFA 21") {
                    giftTitle = "FIFA 21 (Xbox Series X)";
                }

                return (
                  <div className="extra" key={i.toString()}>
                    <h5>
                      {giftTitle}
                    </h5>
                  </div>
                )

              })

            } else if (extraGroup["Entertainment Offers"]) {

              return extraGroup["Entertainment Offers"].map((extra, i) => {

                displayExtras = true;

                return (
                  <div className="extra" key={i.toString()}>
                    <h5 style={{flex: 1}}>
                      {extra["Inclusive Entertainment"][0].title}
                    </h5>
                  </div>
                )

              })

            }

          });

        }

      }


      let duration = null;
      if (this.props.dealInfo.duration && parseInt(this.props.dealInfo.duration) > 1) {
        duration = (
          <div className="deal-block" style={{ minWidth: 40 }}>
            <h5 className="deal-block-info">{this.props.dealInfo.duration} Month</h5>
            <h5 className="deal-block-info-label">Contract</h5>
          </div>
        );
      } else {
        duration = (
          <div className="deal-block" style={{ minWidth: 40 }}>
            <h5 className="deal-block-info">{this.props.dealInfo.duration} Month</h5>
            <h5 className="deal-block-info-label">Rolling</h5>
          </div>
        );
      }


      let overallCost = (parseFloat(this.props.dealInfo.month_cost) * this.props.dealInfo.duration) + parseFloat(this.props.dealInfo.initial_cost);


      return (
        <div className="deal">

          <div className="deal-info-wrap deal-info-wrap--contract">

              <div className="deal-details-wrap">

                <div className="deal-details">

                  <div className="deal-block" style={{ minWidth: 40 }}>
                    <h5 className="deal-block-info">{dataAllowance}</h5>
                    <h5 className="deal-block-info-label">Data</h5>
                  </div>

                  <div className="deal-block-divider"></div>

                  {incMinutesTexts}

                  <div className="deal-block-divider deal-block-divider--last-of-line"></div>

                </div>

                <div className="deal-cost">

                  {duration}

                  <div className="deal-block-divider"></div>

                  <div className="deal-block">
                    <h5 className="deal-block-info">£{this.props.dealInfo.initial_cost}</h5>
                    <h5 className="deal-block-info-label">Up Front</h5>
                  </div>

                  <div className="deal-block-divider"></div>
                  <div className="deal-block">
                    <h5 className="deal-block-info">£{this.props.dealInfo.month_cost}</h5>
                    <h5 className="deal-block-info-label">Per Month</h5>
                  </div>

                </div>

              </div>


              <div className="deal-buy-and-extras">

                <div>
                  <a href="#" onClick={this.handleButtonPress.bind(this, this.props.dealInfo.deep_link)} className="medium-button" style={{width: 120, marginRight: 20}}>Get The Deal</a>
                </div>

                <div className="deal-logos">
                  {retailerLogo}
                  {networkName !== retailerName &&
                    <img style={{marginLeft: '15px'}} className={"deal-logo" + " deal-logo--" + networkName.replace(/\s/g, "")} src={networkLogoSrc} alt={networkName} />
                  }
                </div>

              </div>


              <div style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                alignSelf: 'flex-start',
                padding: '4px 12px',
                background: '#F7F7F7',
                borderRadius: 4,
                marginTop: '2px',
                marginBottom: '26px'
              }}>
                <h5 className="deal-contract-length-total">Total Cost after {this.props.dealInfo.duration} Months: £{overallCost.toFixed(2)}</h5>
              </div>


              {displayExtras &&
                <div className="deal-extras">
                  <h5 className="deal-extras-key">Included Free</h5>
                  <div className="deal-extras-list">
                    {extras}
                  </div>
                </div>
              }

            </div>
          </div>
        );


    } // end of deal display type check

  } // end of render

} // end of class/function

export default Deal;
