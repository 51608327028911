import React from "react";
import * as AvailablePlans from "../config/AvailablePlans";

class ProductContractPlans extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      monthlyData: '',
      simPlans: [],
      recommendedPlans: [],
      numOfConnections: 1
    }

    this.handleConnectionsChange = this.handleConnectionsChange.bind(this);
  }


  componentDidMount = () => {

    this.getPlans();

  }



  setupRecommendations = () => {

    let recommendations = [
      {"provider": "O2", "data": "UNLIMITED"},
      {"provider": "Vodafone", "data": "5GB"},
      {"provider": "EE", "data": "8GB"}
    ];


    // Set recommendations per backend provided preference
    if (this.props.recommendedPlans && this.props.recommendedPlans.length > 0) {
      recommendations = this.props.recommendedPlans;
    }


    // Get any recommendations or a particulary monthly data option from the URL

    if (this.props.urlParams) {
      if (this.props.urlParams.data && this.props.urlParams.provider) {

        // Add to top of established recommendations a url defined option
        recommendations.unshift({ "provider": this.props.urlParams.provider, "data": this.props.urlParams.data });

      } else if (this.props.urlParams.data) {

        // Set the plan picker section to highlight a particular set of options per URL
        this.setState({
          monthlyData: this.props.urlParams.data.toUpperCase()
        });

      }
    }

    //console.log('reccs');
    //console.log(recommendations);

    return recommendations;

  }



  getPlans = () => {

    return fetch(AvailablePlans.baseURL + AvailablePlans.simPlans, {
      headers: {
        'Accept': 'application/json'
      },
    })
    .then((response) => response.json())
    .then((responseJson) => {

      // Set recommened plans from rest of options

      // Creates duplicate of response to modify not a refernece
      const availablePlans = [...responseJson];

      // Get recommended
      let recommendations = this.setupRecommendations();


      // Final version of recommended plans will be added to this array

      let recommendedPlans = [];

      recommendations.forEach((recommendation, i) => {

        let planToInclude = {};

        if (recommendation.provider) {
          planToInclude = availablePlans.find(plan => plan.provider.toUpperCase() === recommendation.provider.toUpperCase());
        }


        // Replace object with new version, swapping out all tiers for just the one needed per recommendations
        // Neccessary to create new object, even though we made a new array as
        // the objects in that array are still mirrored with the original
        planToInclude = {...planToInclude, tiers: planToInclude.tiers.filter(tier => tier.data.toUpperCase() === recommendation.data.toUpperCase())};

        recommendedPlans.push(planToInclude);

      });


      this.setState({
        recommendedPlans: recommendedPlans,
        simPlans: responseJson
      });


    })
    .catch((error) => {
    });

  }



  handleConnectionsChange = (event) => {
    this.setState({numOfConnections: event.target.value});
  }


  handleDataOptionSelected = (monthlyData, event) => {

    // prevent browser window jump for link
    event.preventDefault();

    this.setState({monthlyData: monthlyData});

  }


  handleButtonPress = (planName, planIcon, monthlyData, monthlyTexts, monthlyMinutes, monthlyCost, upfrontCost, numOfConnections, event) => {

    // prevent browser window jump for link
    event.preventDefault();

    // Parent component handles press, pass through seleted contract info
    this.props.buttonPress(planName, planIcon, monthlyData, monthlyTexts, monthlyMinutes, monthlyCost, upfrontCost, numOfConnections);

  }



  render() {


    let productCurrOptions = '';

    this.props.selectedOptions.forEach((sOpt, index) => {
      productCurrOptions += sOpt.option;

      if (index !== this.props.selectedOptions.length - 1) {
        productCurrOptions += ', ';
      }
    });


    let recommendedPlans = null;
    let plans = null;


    recommendedPlans = this.state.recommendedPlans.map((plan, index) => {

      const planName = plan.name;
      const planIcon = plan.icon;

      return plan.tiers.map((tier, i) => {

        let monthlyData = [];

        let connections = 1;
        if (this.state.numOfConnections) {
          connections = this.state.numOfConnections;
        }

        let singleprice = parseFloat(tier.price) + parseFloat(this.props.productPrice);
        let singlepriceRounded = parseFloat(Math.ceil(singleprice*1)/1 - 0.01, 2).toFixed(2);
        let fullprice = singlepriceRounded * parseInt(connections);

        return (
          <div className="deal" key={i.toString()}>

            <div className="deal-info-wrap deal-info-wrap--contract">

                <div className="deal-name">
                  <img className="deal-icon" style={{ marginRight: '10px' }} src={planIcon} alt="o2" />
                  <h4>{planName}</h4>
                </div>

                <div className="deal-details-wrap">
                  <div className="deal-details">

                    <div className="deal-block">
                      <h5>{tier.texts}</h5>
                      <h5 style={{ color: '#737373' }}>Calls & Texts</h5>
                    </div>

                    <div className="deal-block-divider"></div>
                    <div className="deal-block" style={{ minWidth: 66 }}>
                      <h5>{tier.data}</h5>
                      <h5 style={{ color: '#737373' }}>Data</h5>
                    </div>

                  </div>

                  <div className="deal-cost">

                    <div className="deal-block-divider"></div>
                    <div className="deal-block">
                      <h5>£0</h5>
                      <h5 style={{ color: '#737373' }}>Up Front</h5>
                    </div>

                    <div className="deal-block-divider"></div>
                    <div className="deal-block">
                      <h5 style={{ fontWeight: '500' }}>£{fullprice.toFixed(2)}</h5>
                      <h5 style={{ color: '#737373' }}>Per Month</h5>
                    </div>

                  </div>
                </div>

              </div>

              <a href="#" onClick={this.handleButtonPress.bind(this, planName, planIcon, tier.data, tier.texts, tier.minutes, singlepriceRounded, '0.00', connections)} className="medium-button">Select Plan</a>

            </div>
          );

      })

    });


    plans = this.state.simPlans.map((plan, index) => {

      const planName = plan.name;
      const planIcon = plan.icon;

      return plan.tiers.map((tier, i) => {

        let monthlyData = [];

        switch(this.state.monthlyData.toUpperCase())
        {
           case '1GB':
            monthlyData = ['500MB','1GB','2GB','3GB'];
           break;
           case '5GB':
            monthlyData = ['4GB','5GB','8GB'];
           break;
           case '10GB':
            monthlyData = ['8GB','10GB','12GB','13GB','14GB','15GB','16GB'];
           break;
           case '20GB':
            monthlyData = ['16GB','20GB','21GB','22GB','23GB','24GB','25GB','26GB','27GB','28GB','29GB','30GB'];
           break;
           case '50GB':
            monthlyData = ['50GB','55GB','60GB','70GB','75GB','80GB','100GB'];
           break;
           case 'UNLIMITED':
            monthlyData = ['UNLIMITED'];
           break;
           default:
            monthlyData = ['500MB','1GB','2GB','3GB','4GB','5GB','6GB','7GB','8GB','9GB','10GB'];
        }

        if (monthlyData.includes(tier.data.toUpperCase())) {

          let connections = 1;
          if (this.state.numOfConnections) {
            connections = this.state.numOfConnections;
          }

          let singleprice = parseFloat(tier.price) + parseFloat(this.props.productPrice);
          let singlepriceRounded = parseFloat(Math.ceil(singleprice*1)/1 - 0.01, 2).toFixed(2);
          let fullprice = singlepriceRounded * parseInt(connections);

          return (
            <div className="deal" key={i.toString()}>

              <div className="deal-info-wrap deal-info-wrap--contract">

                  <div className="deal-name">
                    <img className="deal-icon" style={{ marginRight: '10px' }} src={planIcon} alt="o2" />
                    <h4>{planName}</h4>
                  </div>

                  <div className="deal-details-wrap">
                    <div className="deal-details">

                      <div className="deal-block">
                        <h5>{tier.texts}</h5>
                        <h5 style={{ color: '#737373' }}>Calls & Texts</h5>
                      </div>

                      <div className="deal-block-divider"></div>
                      <div className="deal-block" style={{ minWidth: 66 }}>
                        <h5>{tier.data}</h5>
                        <h5 style={{ color: '#737373' }}>Data</h5>
                      </div>

                    </div>

                    <div className="deal-cost">

                      <div className="deal-block-divider"></div>
                      <div className="deal-block">
                        <h5>£0</h5>
                        <h5 style={{ color: '#737373' }}>Up Front</h5>
                      </div>

                      <div className="deal-block-divider"></div>
                      <div className="deal-block">
                        <h5 style={{ fontWeight: '500' }}>£{fullprice.toFixed(2)}</h5>
                        <h5 style={{ color: '#737373' }}>Per Month</h5>
                      </div>

                    </div>
                  </div>

                </div>

                <a href="#" onClick={this.handleButtonPress.bind(this, planName, planIcon, tier.data, tier.texts, tier.minutes, singlepriceRounded, '0.00', connections)} className="medium-button">Select Plan</a>

              </div>
            );

        }

      })

    });


    return (
      <div className="contract-deals">


        <div className="contract-deals-block">

          <h3 style={{ marginBottom: '3px' }}>Recommended Deals</h3>
          <h5 style={{ color: '#737373' }}>Price Excluding VAT</h5>

          <div className="deals">
            {recommendedPlans}
          </div>

        </div>


        <div className="contract-deals-block">

          <h3 style={{ marginBottom: '3px' }}>Build a Plan</h3>
          <h5 style={{ marginBottom: '22px', color: '#737373' }}>Price Excluding VAT</h5>

          <div className="option-label"><h4>1. Choose Your Monthly Data</h4></div>
          <div className="filter-options">
            <a href="#" onClick={this.handleDataOptionSelected.bind(this, '1GB')} className={'1GB' === this.state.monthlyData ? "filter-option filter-option--selected" : "filter-option"}>1GB</a>
            <a href="#" onClick={this.handleDataOptionSelected.bind(this, '5GB')} className={'5GB' === this.state.monthlyData ? "filter-option filter-option--selected" : "filter-option"}>5GB</a>
            <a href="#" onClick={this.handleDataOptionSelected.bind(this, '10GB')} className={'10GB' === this.state.monthlyData ? "filter-option filter-option--selected" : "filter-option"}>10GB</a>
            <a href="#" onClick={this.handleDataOptionSelected.bind(this, '20GB')} className={'20GB' === this.state.monthlyData ? "filter-option filter-option--selected" : "filter-option"}>20GB</a>
            <a href="#" onClick={this.handleDataOptionSelected.bind(this, '50GB')} className={'50GB' === this.state.monthlyData ? "filter-option filter-option--selected" : "filter-option"}>50GB</a>
            <a href="#" onClick={this.handleDataOptionSelected.bind(this, 'UNLIMITED')} className={'UNLIMITED' === this.state.monthlyData ? "filter-option filter-option--selected" : "filter-option"}>UNL</a>
          </div>


          <div style={this.state.monthlyData ? {opacity: '1', width: '100%'} : {opacity: '0.4', width: '100%'}}>

            <div className="option-label"><h4>2. Select a Plan</h4></div>
            <div className="device-summary">
              <div className="mini-product-image"><img className="fill-div" src={this.props.productImage} alt="product image" /></div>
              <div className="device-summary-text">
                <h5>{this.props.productName}, {productCurrOptions}</h5>
                <h5 style={{ color: '#737373' }}>Chosen Handset</h5>
              </div>
            </div>

            <div className="contract-connections">
              <p style={{ color: '#737373', paddingRight: '20px' }}>Got multiple employees? Add more connections and get everyone setup with their own handset and monthly data.</p>
              <input type="number" onChange={this.handleConnectionsChange} name="connections" step="1" min="1" value={this.state.numOfConnections} className="connections-dropdown" />
            </div>

            {this.state.monthlyData &&
              <div className="deals">
                {plans}
              </div>
            }

          </div>

        </div>

      </div>
    );

  }

}

export default ProductContractPlans;
