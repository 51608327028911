import React, { useState } from "react";
import { Link } from "react-router-dom";
import Spinner from "react-spinkit";
import { ValidatorForm } from 'react-form-validator-core';

import * as api from "../../config/api";

import ValidatedTextInput from "./forms/ValidatedTextInput";
import ValidatedTextArea from "./forms/ValidatedTextArea";
import ValidatedEmailInput from "./forms/ValidatedEmailInput";
import ValidatedNumberInput from "./forms/ValidatedNumberInput";

function ToolTip({ content, style }) {

    const [showBox, setShowBox] = useState(false)

    function toggleBox(e) {
      e.preventDefault();
      setShowBox(!showBox);
    }

    return (
        <>
        <a
            onMouseOver={() => setShowBox(true)}
            onMouseLeave={() => setShowBox(false)}
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: 30,
                height: 30,
                marginLeft: 4,
                textDecoration: 'none',
                cursor: 'pointer'
            }}
        >
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: 18,
                    minWidth: 18,
                    height: 18,
                    backgroundColor: showBox ? '#121212' : '#ececec',
                    borderRadius: 20,
                    fontSize: 15,
                    color: showBox ? '#ffffff' : '#121212'
                }}
            >
                ?
            </div>
        </a>
        <div
            style={{
                position: 'absolute',
                maxWidth: 500,
                backgroundColor: '#121212',
                padding: 14,
                borderRadius: 5,
                fontSize: 14,
                lineHeight: '22px',
                color: '#ffffff',
                zIndex: showBox ? 10 : 0,
                visibility: showBox ? 'visible' : 'hidden',
                top: 34,
                ...style
            }}
        >
            {content}
        </div>
        </>
    )
}

class FormEV extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      buttonLoading: false,
      accessToken: '',
      pageContent: '',

      contactName: '',
      contactEmail: '',
      contactNumber: '',
      contactAddressLine1: '',
      contactAddressLine2: '',
      contactAddressCity: '',
      contactPostcode: '',

      distance: '',
      comments: '',
      selectedImages: [],
      imagePreview: '',
      //companyName: '',
      formSubmitted: false,
    }

    this.handleContactNameChange = this.handleContactNameChange.bind(this);
    this.handleContactEmailChange = this.handleContactEmailChange.bind(this);
    this.handleContactNumberChange = this.handleContactNumberChange.bind(this);
    this.handleContactAddressLine1Change = this.handleContactAddressLine1Change.bind(this);
    this.handleContactAddressLine2Change = this.handleContactAddressLine2Change.bind(this);
    this.handleContactAddressCityChange = this.handleContactAddressCityChange.bind(this);
    this.handleContactPostcodeChange = this.handleContactPostcodeChange.bind(this);

    this.imageInput = React.createRef();

    this.handleDistanceChange = this.handleDistanceChange.bind(this);
    this.handleCommentsChange = this.handleCommentsChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }



  componentDidMount = () => {
    window.scrollTo(0, 0);
  }


  handleContactNameChange = (event) => {
    this.setState({contactName: event.target.value});
  }

  handleContactEmailChange = (event) => {
    this.setState({contactEmail: event.target.value});
  }

  handleContactNumberChange = (event) => {
    this.setState({contactNumber: event.target.value});
  }

  handleContactAddressLine1Change = (event) => {
    this.setState({contactAddressLine1: event.target.value});
  }

  handleContactAddressLine2Change = (event) => {
    this.setState({contactAddressLine2: event.target.value});
  }

  handleContactAddressCityChange = (event) => {
    this.setState({contactAddressCity: event.target.value});
  }

  handleContactPostcodeChange = (event) => {
    this.setState({contactPostcode: event.target.value});
  }

  handleDistanceChange = (event) => {
    this.setState({distance: event.target.value});
  }

  handleCommentsChange = (event) => {
    this.setState({comments: event.target.value});
  }

  handleOptionSelected = (optionKey, optionValue, event) => {
      event.preventDefault();
      this.setState({ [optionKey]: optionValue });
  }


    handleImage = (files) => {
        this.setState({ selectedImages: files });

        const reader = new FileReader();

        reader.onload = (e) => {
            this.setState({ imagePreview: e.target.result });
        }

        reader.readAsDataURL(files[0]);
    }


    handleFormError = () => {
        // Move us to the top of the form to adjust relevant input error
        document.getElementById("form-anchor").scrollIntoView();
    }


  handleSubmit = (event) => {

    // prevent browser window jump for link
    event.preventDefault();

    this.setState({buttonLoading: true});

    // let data = {
    //     "page": window.location.pathname,
    //     "name": this.state.contactName,
    //     "address_1": this.state.contactAddressLine1,
    //     "address_2": this.state.contactAddressLine2,
    //     "city": this.state.contactAddressCity,
    //     "postcode": this.state.contactPostcode,
    //     "email": this.state.contactEmail,
    //     "phone_number": this.state.contactNumber,
    //     "unit_type": this.state.unitType,
    //     "installation_type": this.state.installationType,
    //     "num_of_wires": this.state.numOfWires,
    //     "comments": this.state.comments
    // };

    // const bodyJSON = JSON.stringify(data);

    let formData = new FormData();

    formData.append("page", window.location.pathname);
    formData.append("name", this.state.contactName);
    formData.append("address_1", this.state.contactAddressLine1);
    formData.append("address_2", this.state.contactAddressLine2);
    formData.append("city", this.state.contactAddressCity);
    formData.append("postcode", this.state.contactPostcode);
    formData.append("email", this.state.contactEmail);
    formData.append("phone_number", this.state.contactNumber);

    formData.append("unit_type", this.state.unitType);
    formData.append("installation_type", this.state.installationType);
    formData.append("num_of_wires", this.state.numOfWires);
    formData.append("comments", this.state.comments);

    if ("Yes" === this.state.withinMaxDistance) {
        formData.append("distance_from_fusebox", "Within 10 meters");
    } else {
        formData.append("distance_from_fusebox", this.state.distance);
    }

    [...this.state.selectedImages].forEach((file, i) => {
        if (file !== "none") {
            formData.append("attachments[]", file);
        }
    });

    return fetch('https://ylo.io/api/weareylo/ev-form', {
      method: 'POST',
      body: formData
    })
    .then((response) => response.json())
    .then((responseJson) => {

      if (responseJson.success) {
        this.setState({buttonLoading: false, formSubmitted: true}, function() {
            // Redirect
            if (this.props.redirectSlug) {
                this.props.history.push('/' + this.props.redirectSlug);
            }
        });
      } else {
        this.setState({buttonLoading: false});
      }

    })
    .catch((error) => {
      console.log(error);
      this.setState({buttonLoading: false});
    });

  }


  render() {

    const content = this.props.content ? (
        <div className="contact-area-side">
            <div dangerouslySetInnerHTML={{__html: this.props.content}}>
            </div>
        </div>
    ) : (
        <></>
    );

    if (this.state.formSubmitted) {

        return (
          <div className="content contact-area" style={{position: 'relative', justifyContent: this.props.content ? 'space-between' : 'center'}}>

            {content}

            <form style={{ alignSelf: 'center' }}>
              <div className="alert alert--ylo">
                  Thank you for your enquiry.<br />
                  One of our installers will be in touch as soon as possible.
              </div>
            </form>

          </div>
        );

    } else {

      let submitButton = this.state.buttonLoading ? (
        <div className="loading-spinner-button">
          <Spinner name="pacman" color="#65c84c" fadeIn="half" />
        </div>
      ) : (
        <input type="submit" value="Send" className="large-button fadeIn" />
      );

      // <div className="input-wrap">
      //   <div className="input-label">Company Name</div>
      //   <input type="text" onChange={this.handleCompanyNameChange} name="companyName" value={this.state.companyName} className="txt-input" />
      // </div>

      return (
        <div id="ev-form-area" className="content contact-area" style={{position: 'relative', justifyContent: this.props.content ? 'space-between' : 'center'}}>

        <div id="form-anchor" style={{ position: 'absolute', top: '-100px' }}></div>

        {content}

        <ValidatorForm onSubmit={this.handleSubmit} onError={this.handleFormError} className="form-block-ev" ref="evForm">

          <div className="input-wrap">
            <div className="input-label">Full Name</div>
            <ValidatedTextInput
              onChange={this.handleContactNameChange}
              name="contactName"
              value={this.state.contactName}
              validators={['required', "matchRegexp:(^[-'a-zA-ZÀ-ÖØ-öø-ſ\\s]+$)"]}
              errorMessages={['Please provide your name.', 'Ensure you only use letters.']}
            />
          </div>

            <div className="input-wrap" style={{marginBottom: 14}}>
              <div className="input-label">Address</div>
              <ValidatedTextInput
                onChange={this.handleContactAddressLine1Change}
                name="contactAddressLine1"
                value={this.state.contactAddressLine1}
                validators={['required', "matchRegexp:^[-'a-zA-Z0-9À-ÖØ-öø-ſ\\s]+$"]}
                errorMessages={['An address is required.', 'Only use letters and numbers.']}
              />
            </div>

            <div className="input-wrap">
              <ValidatedTextInput
                onChange={this.handleContactAddressLine2Change}
                name="contactAddressLine2"
                value={this.state.contactAddressLine2}
                validators={["matchRegexp:^[-'a-zA-Z0-9À-ÖØ-öø-ſ\\s]+$"]}
                errorMessages={['Only use letters and numbers.']}
              />
            </div>

          <div className="input-group">
            <div className="input-wrap">
              <div className="input-label">City/Town</div>
              <ValidatedTextInput
                onChange={this.handleContactAddressCityChange}
                name="contactAddressCity"
                value={this.state.contactAddressCity}
                validators={['required', "matchRegexp:^[-'a-zA-ZÀ-ÖØ-öø-ſ\\s]+$"]}
                errorMessages={['A city or town is required.', 'Only use letters.']}
              />
            </div>

            <div className="input-wrap">
              <div className="input-label">Postcode</div>
              <ValidatedTextInput
                onChange={this.handleContactPostcodeChange}
                name="contactPostcode"
                value={this.state.contactPostcode}
                validators={['required']}
                errorMessages={['A postcode is required.']}
              />
            </div>
          </div>

          <div className="input-wrap">
            <div className="input-label">Email</div>
            <ValidatedEmailInput
              onChange={this.handleContactEmailChange}
              name="contactEmail"
              value={this.state.contactEmail}
              validators={['required']}
              errorMessages={['An email address is required.']}
            />
          </div>

          <div className="input-wrap">
            <div className="input-label">Contact Number</div>
            <ValidatedTextInput
              onChange={this.handleContactNumberChange}
              name="contactNumber"
              value={this.state.contactNumber}
              validators={['required', "matchRegexp:^[0-9 ()+-]+$"]}
              errorMessages={['Please provide a phone number.', 'Only use numbers.']}
            />
          </div>


          <div className="input-label">Type of EV Unit</div>
          <div className="data-options">
            <a
                href="#"
                onClick={this.handleOptionSelected.bind(this, "unitType", "Home")}
                className={"Home" === this.state.unitType ? "data-option data-option--selected" : "data-option"}
            >
                <img className="data-option-icon" src="/img/house_icon.png" alt="house icon" />
                Home
                <img className="data-option-tick" src="/img/tick-in-circle-icon.png" alt="tick in circle icon" />
            </a>
            <a
                href="#"
                onClick={this.handleOptionSelected.bind(this, "unitType", "Commercial")}
                className={"Commercial" === this.state.unitType ? "data-option data-option--selected" : "data-option"}
            >
                <img className="data-option-icon" src="/img/office_icon.png" alt="office building icon" />
                Commercial/Office
                <img className="data-option-tick" src="/img/tick-in-circle-icon.png" alt="tick in circle icon" />
            </a>
          </div>


          {this.state.unitType &&
            <div style={{position: 'relative'}}>
                  <div className="input-label" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                      <span>Type of Installation Ground</span>
                      <ToolTip content="Please let us know where you’d like your EV Charger installed – on a wall with no groundworks needed, on grass/soil or on concrete (where groundworks will be needed)" />
                  </div>

                  <div className="data-options">
                      <a
                          href="#"
                          onClick={this.handleOptionSelected.bind(this, "installationType", "Attached to Wall")}
                          className={"Attached to Wall" === this.state.installationType ? "data-option data-option--column data-option--selected" : "data-option data-option--column"}
                      >
                          <img className="data-option-icon" src="/img/wall_icon.svg" alt="brick wall icon" />
                          Attached to Wall
                          <img className="data-option-tick" src="/img/tick-in-circle-icon.png" alt="tick in circle icon" />
                      </a>
                      <a
                          href="#"
                          onClick={this.handleOptionSelected.bind(this, "installationType", "Grass/Soil")}
                          className={"Grass/Soil" === this.state.installationType ? "data-option data-option--column data-option--selected" : "data-option data-option--column"}
                      >
                          <img className="data-option-icon" src="/img/garden_icon.svg" alt="grass and soil icon" />
                          Grass/Soil
                          <img className="data-option-tick" src="/img/tick-in-circle-icon.png" alt="tick in circle icon" />
                      </a>
                      <a
                          href="#"
                          onClick={this.handleOptionSelected.bind(this, "installationType", "Concrete/Asphalt")}
                          className={"Concrete/Asphalt" === this.state.installationType ? "data-option data-option--column data-option--selected" : "data-option data-option--column"}
                      >
                          <img className="data-option-icon" src="/img/ground_icon.svg" style={{paddingTop: 10}} alt="paved ground icon" />
                          Concrete/Asphalt
                          <img className="data-option-tick" src="/img/tick-in-circle-icon.png" alt="tick in circle icon" />
                      </a>
                  </div>
            </div>
          }

          {this.state.installationType &&
              <div style={{position: 'relative'}}>
                  <div className="input-label" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                      <span>How many wires currently go from your Meter to your Fusebox?</span>
                      <ToolTip content="The number of wires going from your electricity meter (where you take meter readings from) to your main fuseboard." />
                  </div>
                  <div className="data-options">
                    <a
                        href="#"
                        onClick={this.handleOptionSelected.bind(this, "numOfWires", 1)}
                        className={1 === this.state.numOfWires ? "data-option data-option--selected" : "data-option"}
                    >
                        1
                        <img className="data-option-tick" src="/img/tick-in-circle-icon.png" alt="tick in circle icon" />
                    </a>
                    <a
                        href="#"
                        onClick={this.handleOptionSelected.bind(this, "numOfWires", 2)}
                        className={2 === this.state.numOfWires ? "data-option data-option--selected" : "data-option"}
                    >
                        2
                        <img className="data-option-tick" src="/img/tick-in-circle-icon.png" alt="tick in circle icon" />
                    </a>
                    <a
                        href="#"
                        onClick={this.handleOptionSelected.bind(this, "numOfWires", 3)}
                        className={3 === this.state.numOfWires ? "data-option data-option--selected" : "data-option"}
                    >
                        3
                        <img className="data-option-tick" src="/img/tick-in-circle-icon.png" alt="tick in circle icon" />
                    </a>
                  </div>
              </div>
          }

          {this.state.numOfWires &&
              <div style={{position: 'relative'}}>
                  <div className="input-label" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                      <span>Provide a picture of your Fusebox. This helps our installers bring the right equipment.</span>
                      <ToolTip content="If you can provide any supporting images of the fuse board to show our installers, please attach them here." />
                  </div>

                  <div className="file-browse-wrap">
                    <div style={{
                            display: 'flex',
                            flexDirection: 'row'
                    }}>
                    <a className="small-button small-button--outline" style={{width: '130px', marginRight: 20}} onClick={e => this.imageInput.current && this.imageInput.current.click()}>Select a Picture</a>
                    <a className="small-button small-button--grey" style={{width: '70px', marginRight: 20}} onClick={e => this.setState({selectedImages: ['none']})}>Skip</a>
                    </div>
                    <input ref={this.imageInput} className="file-input" type="file" accept="image/*" onChange={(e) => this.handleImage(e.target.files)} />
                    {this.state.imagePreview &&
                      <img className="file-preview" src={this.state.imagePreview} />
                    }
                  </div>
              </div>
          }

          {this.state.selectedImages.length > 0 &&
              <>
              <div className="input-label">Is the distance from your meter to your chosen EV charger location 10 Meters (30ft) or less?</div>
              <div className="data-options">
                <a
                    href="#"
                    onClick={this.handleOptionSelected.bind(this, "withinMaxDistance", "Yes")}
                    className={"Yes" === this.state.withinMaxDistance ? "data-option data-option--selected" : "data-option"}
                >
                    Yes
                    <img className="data-option-tick" src="/img/tick-in-circle-icon.png" alt="tick in circle icon" />
                </a>
                <a
                    href="#"
                    onClick={this.handleOptionSelected.bind(this, "withinMaxDistance", "No")}
                    className={"No" === this.state.withinMaxDistance ? "data-option data-option--selected" : "data-option"}
                >
                    No
                    <img className="data-option-tick" src="/img/tick-in-circle-icon.png" alt="tick in circle icon" />
                </a>
              </div>
              </>
          }

          {"No" === this.state.withinMaxDistance &&
              <div className="input-wrap">
                <div className="input-label">Distance from Meter to EV Charger</div>
                <ValidatedTextInput
                  onChange={this.handleDistanceChange}
                  name="distance"
                  value={this.state.distance}
                />
              </div>
          }

          {this.state.withinMaxDistance &&
              <div className="input-wrap">
                <div className="input-label">Any Other Comments</div>
                <ValidatedTextArea
                  onChange={this.handleCommentsChange}
                  name="comments"
                  value={this.state.comments}
                  rows="5"
                />
              </div>
          }

          <div>
            {submitButton}
          </div>

        </ValidatorForm>
        </div>
      );

    }

  }

}

export default FormEV;
