import React from "react";
import { Link } from "react-router-dom";
import Spinner from "react-spinkit";
import {Helmet} from "react-helmet";

import * as api from "../config/api";
import * as AvailablePlans from "../config/AvailablePlans";

import TabsBroadband from "./tabs/TabsBroadband";

class Broadband extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      accessToken: '',
      plans: [],
      plansDisplayed: [],
      orderQuantity: 1
    }

    this.getContent();

  }


  componentDidMount = () => {
    window.scrollTo(0, 0);

    this.organisePlans();

    // if (this.props.match.params.tag) {
    //   this.filterPlans(this.props.match.params.tag);
    // }
  }


  componentDidUpdate = (prevProps) => {

    if (prevProps.match.params.tag !== this.props.match.params.tag) {

      if (this.props.match.params.tag) {

        this.setState({
          isLoading: true,
          plansDisplayed: [],
        });
        this.filterPlans(this.props.match.params.tag);

      } else {
        this.removeFilter();
      }

    }

  }


  organisePlans = () => {

    // Get initial plans and structure them

    return fetch(AvailablePlans.baseURL + AvailablePlans.mobileBroadbandPlans, {
      headers: {
        'Accept': 'application/json'
      },
    })
    .then((response) => response.json())
    .then((responseJson) => {

      let allPlans = responseJson.map((plan) => {

        const planName = plan.name;
        const planIcon = plan.icon;

        return plan.tiers.map((tier, i) => {

          let singlePlan = Object.assign({}, tier);
          singlePlan['name'] = planName;
          singlePlan['icon'] = planIcon;

          return singlePlan;

        })

      });


      allPlans = allPlans.reduce((a, b) => a.concat(b), []);
      let orderedPlans = allPlans.sort((a, b) => {
        return parseFloat(a.price) - parseFloat(b.price)
      });


      return this.filterPlans(orderedPlans);

    })
    .catch((error) => {
    });

  }



  getContent = () => {


     // Fetch additional page content from wordpress backend

     fetch(api.wordpressURL + 'pages?slug=broadband-bottom', {
       headers: {
         'Accept': 'application/json'
       },
     })
     .then((response) => response.json())
     .then((responseJson) => {

         this.setState({
           isLoading: false,
           bottomContent: responseJson[0].content.rendered,
         }, function() {
         });

     })
     .catch((error) => {
     });

  }



  filterPlans = (allPlans) => {

    // Filter if neccessary

    let filteredPlans = [];
    const currTag = this.props.match.params.tag;


    if (currTag === "o2" || currTag === "ee" || currTag === "vodafone") {

      allPlans.forEach((plan, index) => {

        if (plan.name.toLowerCase().includes(currTag)) {
          filteredPlans.push(plan);
        }

        if (allPlans.length - 1 === index) {
          this.setState({
            isLoading: false,
            plans: allPlans,
            plansDisplayed: filteredPlans,
          });
        }

      });

    } else {

      // No filtering needed

      this.setState({
        plans: allPlans,
        plansDisplayed: allPlans,
      });

    }

  }


  removeFilter = () => {

    this.setState({
      isLoading: false,
      plansDisplayed: this.state.plans,
    });

  }



  // shuffleArray = (array) => {
  //
  //   let m = array.length, t, i;
  //
  //   // While there remain elements to shuffle…
  //   while (m) {
  //
  //     // Pick a remaining element…
  //     i = Math.floor(Math.random() * m--);
  //
  //     // And swap it with the current element.
  //     t = array[m];
  //     array[m] = array[i];
  //     array[i] = t;
  //   }
  //
  //   return array;
  //
  // }



  addFibreToCart = (planName, planSpeed, monthlyCost, upfrontCost, priority, event) => {

    // prevent browser window jump for link
    event.preventDefault();

    let newCartContents = [];
    if (localStorage.getItem('cartContents')) {
      newCartContents = JSON.parse(localStorage.getItem('cartContents'));
    }

    // 2002 - Sim-Only product ID
    // 2004 - Mobile Internet product ID
    // 2261 - Fibre Internet

    let cartItem = {};
    cartItem.productID = 2261;

    if (priority) {
      cartItem.name = planName + " · " + planSpeed + " Priority";
    } else {
      cartItem.name = planName + " · " + planSpeed;
    }

    cartItem.summary = "Includes Line Rental. 24 Month Contract";
    cartItem.quantity = 1;
    cartItem.price = upfrontCost;

    cartItem.fibreContract = true;
    cartItem.contractPlan = planName;
    cartItem.contractLength = "24 Months";
    cartItem.contractMonthlyCost = monthlyCost;
    cartItem.contractUpfrontCost = upfrontCost;


    newCartContents.push(cartItem);

    localStorage.setItem('cartContents', JSON.stringify(newCartContents));

    this.props.history.push('/checkout/cart');

  }



  addContractToCart = (planName, planIcon, monthlyData, monthlyTexts, monthlyMinutes, monthlyCost, upfrontCost, event) => {

    // prevent browser window jump for link
    event.preventDefault();

    let newCartContents = [];
    if (localStorage.getItem('cartContents')) {
      newCartContents = JSON.parse(localStorage.getItem('cartContents'));
    }

    // 2002 - Sim-Only product ID
    // 2004 - Mobile Internet product ID
    // 2261 - Fibre Internet

    let cartItem = {};
    cartItem.productID = 2004;

    cartItem.name = planName + " · 12 Months";
    cartItem.summary = monthlyData + ' Monthly Data';
    cartItem.image = planIcon;
    cartItem.quantity = this.state.orderQuantity;
    cartItem.price = upfrontCost;

    cartItem.simOnlyContract = true;
    cartItem.contractPlan = planName;
    cartItem.contractPlanIcon = planIcon;
    cartItem.contractLength = "12 Months";
    cartItem.contractMonthlyData = monthlyData;
    cartItem.contractMonthlyCost = monthlyCost;
    cartItem.contractUpfrontCost = upfrontCost;


    newCartContents.push(cartItem);

    localStorage.setItem('cartContents', JSON.stringify(newCartContents));

    this.props.history.push('/checkout/cart');

  }


  handleQuantityChange = (event) => {

   event.stopPropagation();
   this.setState({orderQuantity: event.target.value});

  }


  setSelectedOverlay = (id, event) => {

    event.stopPropagation();

    // prevent browser window jump for link
    event.preventDefault();

    this.setState({
      selectedOverlayID: id,
    });

  }


  hideOverlay = (event) => {

    // prevent browser window jump for link
    event.preventDefault();

    if (this.state.selectedOverlayID || this.state.selectedOverlayID === 0) {
      this.setState({
        selectedOverlayID: null,
      });
    }

  }



  render() {

    let heading = "Business Broadband";
    let subHeading = "Fibre, Mobile and CityFibre. The choice is yours.";
    if (this.props.match.params.name) {
      heading = this.props.match.params.name.charAt(0).toUpperCase() + this.props.match.params.name.slice(1) + " Broadband";
      subHeading = "Keep working on the move, with O2, EE and Vodafone.";
    }


    if (this.state.isLoading) {

      return (
        <div className="loading-spinner">
          <Spinner name="pacman" color="#65c84c" fadeIn="half" />
        </div>
      );

    } else if (this.props.match.params.name) {

      let plans = (<div></div>);

      if (this.state.plansDisplayed) {

        plans = this.state.plansDisplayed.map((plan, index) => {

          let overlayClass = "product-selected-overlay";
          if (this.state.selectedOverlayID === index) {
            overlayClass = "product-selected-overlay product-selected-overlay--active";
          }

          return (
            <li className="product" key={index.toString()}>

              <a href="#" onClick={this.setSelectedOverlay.bind(this, index)} className="plan-image"><img className="fill-div" src={plan.icon} alt="network provider icon" /></a>

              <h3>{plan.name}</h3>

              <h5 style={{ color: '#9a9a9a', marginBottom: '8px',  }}>{plan.data} Monthly Data + Free Dongle</h5>
              <h5 style={{ color: '#9a9a9a', marginBottom: '14px' }}>12 Month Contract</h5>

              <div className="price-view">
                <h4 className="price">£{plan.price}<br /> <span className="under-price-info">per month</span><span style={{fontSize: '15px', color: '#9a9a9a'}}> (exc VAT)</span></h4>
                <a href="#" onClick={this.setSelectedOverlay.bind(this, index)} className="medium-button">Select Plan</a>
              </div>


              <div className={overlayClass}>

                <a href="#" onClick={this.setSelectedOverlay.bind(this, index)} className="plan-image"><img className="fill-div" src={plan.icon} alt="network provider icon" /></a>

                <h3 style={{ marginBottom: '16px' }}>{plan.name}</h3>

                <h5 style={{ color: '#cecece', marginBottom: '8px' }}>{plan.data} Monthly Data + Free Dongle</h5>
                <h5 style={{ color: '#cecece', marginBottom: '8px' }}>12 Month Contract</h5>
                <h5 style={{ color: '#cecece', marginBottom: '8px' }}>No Upfront Cost</h5>
                <h5 style={{ color: '#cecece', marginBottom: '24px' }}>{plan.extra}</h5>

                <h4 style={{ color: '#fff', textAlign: 'center' }}>£{plan.price}<br /> <span style={{fontSize: '16px', color: '#cecece'}}>per month (exc VAT)</span></h4>

                <div className="price-view">
                  <div className="order-quantity">
                    <label style={{ color: '#cecece', marginRight: 12, }}>How Many?</label>
                    <input type="number" onChange={this.handleQuantityChange} name="connections" step="1" min="1" value={this.state.orderQuantity} className="order-quantity-input order-quantity-input--dark" />
                  </div>
                  <a href="#" onClick={this.addContractToCart.bind(this, plan.name, plan.icon, plan.data, plan.texts, plan.minutes, plan.price, '0.00')} className="medium-button">Add to Bag</a>
                </div>

              </div>

            </li>
          );

        });

      }

      return (
        <div className="content-wrap" onClick={this.hideOverlay.bind(this)}>

          <Helmet>
            <title>{heading} - ylo</title>
            <meta name="description" content={subHeading} />
          </Helmet>

          <div className="category-intro">
            <h1>{heading}</h1>
            <h4>{subHeading}</h4>
          </div>

          <TabsBroadband />

          <ul className="product-listings">
            {plans}
          </ul>
        </div>
      );

    } else {

      // Fixed Line Broadband

      return (
        <div className="content-wrap">

          <Helmet>
            <title>{heading} - ylo</title>
            <meta name="description" content={subHeading} />
          </Helmet>

          <div className="category-intro">
            <h1>{heading}</h1>
            <h4>{subHeading}</h4>
          </div>

          <TabsBroadband />


          <ul className="broadband-plans">
            <li className="plan">

              <div className="plan-main">

                <div className="plan-speed">40Mb</div>
                <h3>SuperFast Fibre Broadband</h3>
                <h5 style={{ color: '#737373', marginBottom: '6px',  }}>40Mb Download Speed</h5>
                <h5 style={{ color: '#737373', marginBottom: '12px',  }}>10Mb Upload Speed</h5>
                <div className="partial-divider"></div>
                <h5 style={{ color: '#737373', marginBottom: '6px' }}>Includes Line Rental</h5>
                <h5 style={{ color: '#737373', marginBottom: '6px' }}>Free Router, Setup and Connection</h5>
                <h5 style={{ color: '#737373' }}>24 Month Contract</h5>

                <div className="limited-time-offer">
                  <img src="/img/office365.png" alt="office 365 logo" />
                  <div className="limited-time-offer-text">
                    <span>Free Office 365 for One Year</span>
                    <span style={{ fontSize: '14px', marginTop: '3px' }}>For a Limited Time Only</span>
                  </div>
                </div>

                <div className="price-view">
                  <h4 className="price">£44.99<br /> <span className="under-price-info">per month</span><span style={{fontSize: '14px', color: '#737373'}}> (exc VAT)</span></h4>
                  <a href="#" onClick={this.addFibreToCart.bind(this, 'SuperFast Fibre Broadband', '40Mb', '44.99', '0.00', false)} className="medium-button">Select Plan</a>
                </div>

              </div>

              <div className="upgrade-option">
                <h3>Upgrade to Priority</h3>
                <p>All of the above + Priority Traffic for your business. Ensuring consistent perfromance at peak times.</p>
                <div className="price-select">
                  <h4 className="price">£54.99<br /> <span className="under-price-info">per month</span><span style={{fontSize: '14px', color: '#737373'}}> (exc VAT)</span></h4>
                  <a href="#" onClick={this.addFibreToCart.bind(this, 'SuperFast Fibre Broadband', '40Mb', '54.99', '0.00', true)} className="medium-button">Select Priority</a>
                </div>
              </div>
            </li>
            <li className="plan plan--faster">

              <div className="plan-main">

                <div className="plan-speed">80Mb</div>
                <h3>SuperFast Fibre Broadband</h3>
                <h5 style={{ color: '#737373', marginBottom: '6px',  }}>80Mb Download Speed</h5>
                <h5 style={{ color: '#737373', marginBottom: '12px',  }}>20Mb Upload Speed</h5>
                <div className="partial-divider"></div>
                <h5 style={{ color: '#737373', marginBottom: '6px' }}>Includes Line Rental</h5>
                <h5 style={{ color: '#737373', marginBottom: '6px' }}>Free Router, Setup and Connection</h5>
                <h5 style={{ color: '#737373' }}>24 Month Contract</h5>

                <div className="limited-time-offer">
                  <img src="/img/office365.png" alt="office 365 logo" />
                  <div className="limited-time-offer-text">
                    <span>Free Office 365 for One Year</span>
                    <span style={{ fontSize: '14px', marginTop: '3px' }}>For a Limited Time Only</span>
                  </div>
                </div>

                <div className="price-view">
                  <h4 className="price">£54.99<br /> <span className="under-price-info">per month</span><span style={{fontSize: '14px', color: '#737373'}}> (exc VAT)</span></h4>
                  <a href="#" onClick={this.addFibreToCart.bind(this, 'SuperFast Fibre Broadband', '80Mb', '54.99', '0.00', false)} className="medium-button">Select Plan</a>
                </div>

              </div>

              <div className="upgrade-option">
                <h3>Upgrade to Priority</h3>
                <p>All of the above + Priority Traffic for your business. Ensuring consistent perfromance at peak times.</p>
                <div className="price-select">
                  <h4 className="price">£60.99<br /> <span className="under-price-info">per month</span><span style={{fontSize: '14px', color: '#737373'}}> (exc VAT)</span></h4>
                  <a href="#" onClick={this.addFibreToCart.bind(this, 'SuperFast Fibre Broadband', '80Mb', '60.99', '0.00', true)} className="medium-button">Select Priority</a>
                </div>
              </div>
            </li>

            <li className="plan plan--cityfibre">
              <div className="plan-speed">1Gb</div>
              <img className="fill-div" src="/img/cityfibre-logo-grey.png" alt="ctyfibre logo" />
              <div className="cityfibre-text">
                <h4 className="secondary-text">Enter the world of ultra fast business broadband. 1Gb download speed, directly into your premises with CityFibre. Discover the benefits and see if your area is taking orders.</h4>
                <div><a href="/page/cityfibre" className="large-button">Find Out More</a></div>
              </div>
            </li>

          </ul>


          <div className="content-wrap">
            <div dangerouslySetInnerHTML={{__html: this.state.bottomContent}}>
            </div>
          </div>


        </div>
      );

    }


  }

}

export default Broadband;
