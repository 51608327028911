import React from "react";
import { Link } from "react-router-dom";
import Spinner from "react-spinkit";
import Select from "react-dropdown-select";
import { ValidatorForm } from 'react-form-validator-core';
import {Helmet} from "react-helmet";

import ValidatedTextInput from "./forms/ValidatedTextInput";
import ValidatedEmailInput from "./forms/ValidatedEmailInput";
import ValidatedNumberInput from "./forms/ValidatedNumberInput";

import * as api from "../../config/api";
import OrderSummary from "./OrderSummary";


class CheckoutDetails extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      buttonLoading: false,
      accessToken: '',
      pageContent: '',

      contactName: '',
      //contactJob: '',
      contactEmail: '',
      contactNumber: '',
      companyName: '',
      companyAddressLine1: '',
      companyAddressLine2: '',
      companyAddressCity: '',
      companyPostcode: '',
      formSubmitted: false,
      displayError: false,
      order: [],

      buttonText: 'Continue ->',
      totalCost: 0.00
    }

    this.handleContactNameChange = this.handleContactNameChange.bind(this);
    this.handleContactEmailChange = this.handleContactEmailChange.bind(this);
    this.handleContactNumberChange = this.handleContactNumberChange.bind(this);
    this.handleCompanyNameChange = this.handleCompanyNameChange.bind(this);
    this.handleCompanyAddressLine1Change = this.handleCompanyAddressLine1Change.bind(this);
    this.handleCompanyAddressLine2Change = this.handleCompanyAddressLine2Change.bind(this);
    this.handleCompanyAddressCityChange = this.handleCompanyAddressCityChange.bind(this);
    this.handleCompanyPostcodeChange = this.handleCompanyPostcodeChange.bind(this);

    this.handleSubmit = this.handleSubmit.bind(this);
  }



  componentDidMount = () => {

    window.scrollTo(0, 0);

    if (localStorage.getItem('cartContents')) {

      let order = JSON.parse(localStorage.getItem('cartContents'));

      console.log(order);

      this.setState({
        order: order
      }, function() {
        this.calcTotal();
      });

    }

  }


  calcTotal = () => {

    let runningTotal = 0.00;
    const orderContents = this.state.order; //orderContents

    if (orderContents) {

      orderContents.forEach((orderItem, index) => {
        runningTotal += parseFloat(orderItem.price);

        if (orderContents.length - 1 === index) {
          this.setState({
            totalCost: runningTotal.toFixed(2)
          });
        }
      });
    }

  }


  handleContactNameChange = (event) => {
    this.setState({contactName: event.target.value});
  }

  // handleContactJobChange = (event) => {
  //   this.setState({contactJob: event.target.value});
  // }

  handleContactNumberChange = (event) => {
    this.setState({contactNumber: event.target.value});
  }

  handleContactEmailChange = (event) => {
    this.setState({contactEmail: event.target.value});
  }

  handleCompanyNameChange = (event) => {
    this.setState({companyName: event.target.value});
  }

  handleCompanyAddressLine1Change = (event) => {
    this.setState({companyAddressLine1: event.target.value});
  }

  handleCompanyAddressLine2Change = (event) => {
    this.setState({companyAddressLine2: event.target.value});
  }

  handleCompanyAddressCityChange = (event) => {
    this.setState({companyAddressCity: event.target.value});
  }

  handleCompanyPostcodeChange = (event) => {
    this.setState({companyPostcode: event.target.value});
  }




  handleSubmit = () => {

    // Not needed as submit is called progamatically now via order summary button props
    // prevent browser window jump for link
    //event.preventDefault();
    // ----

    this.setState({buttonLoading: true});


    let fullName = this.state.contactName.split(' '),
    firstName = fullName[0],
    lastName = fullName[fullName.length - 1];

    const cartContents = this.state.order;
    let lineItems = [];

    cartContents.forEach((cartItem, index) => {

      let lineItem = {};
      lineItem.product_id = cartItem.productID;

      if (cartItem.variationID) {
        lineItem.variation_id = cartItem.variationID;
      }

      //console.log(cartItem.price);

      lineItem.name = cartItem.name;
      lineItem.quantity = cartItem.quantity;
      lineItem.subtotal = cartItem.price.toString();
      lineItem.total = cartItem.price.toString();

      // if (cartItem.phoneContract || cartItem.simOnlyContract) {
      //   lineItem.meta_data = [
      //     {
      //       "key": "Plan",
      //       "value": cartItem.contractPlan + '. ' + cartItem.contractMonthlyData + ' Data. Unlimited Calls & Texts'
      //     },
      //     {
      //       "key": "Monthly Cost",
      //       "value": cartItem.contractMonthlyCost
      //     },
      //   ];
      // } else if (cartItem.fibreContract) {
      //   lineItem.meta_data = [
      //     {
      //       "key": "Details",
      //       "value": cartItem.summary
      //     },
      //     {
      //       "key": "Monthly Cost",
      //       "value": cartItem.contractMonthlyCost
      //     },
      //   ];
      // }

      if (cartItem.summary) {
        lineItem.meta_data = [
          {
            "key": "Details",
            "value": cartItem.summary
          }
        ];
      }

      lineItems.push(lineItem);

    });


    let metaData = [];

    let data = {
       "status": "pending",
       "billing": {
         "first_name": firstName,
         "last_name": lastName,
         "company": this.state.companyName,
         "address_1": this.state.companyAddressLine1,
         "address_2": this.state.companyAddressLine2,
         "city": this.state.companyAddressCity,
         "postcode": this.state.companyPostcode,
         "country": "GB",
         "email": this.state.contactEmail,
         "phone": this.state.contactNumber
       },
       "shipping": {
         "first_name": firstName,
         "last_name": lastName,
         "company": this.state.companyName,
         "address_1": this.state.companyAddressLine1,
         "address_2": this.state.companyAddressLine2,
         "city": this.state.companyAddressCity,
         "postcode": this.state.companyPostcode,
         "country": "GB"
       },
       "line_items": lineItems,
       "shipping_lines": [
         {
           "method_id": "flat_rate",
           "method_title": "Free Delivery",
           "total": "0"
         }
       ],
       "meta_data": metaData
    };


    const bodyJSON = JSON.stringify(data);

    return fetch(api.woocommerceURL + 'orders', {
      method: 'POST',
      headers: {
        'Authorization': api.woocommerceAuth,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: bodyJSON
    })
    .then((response) => response.json())
    .then((responseJson) => {

      //console.log(responseJson);

      if (responseJson.id && responseJson.status) {

        this.setState({ buttonLoading: false });

        this.props.history.push({
          pathname: '/checkout/payment',
          state: {
            orderID: responseJson.id,
            contactName: this.state.contactName,
            contactEmail: this.state.contactEmail,
            addressLine1: this.state.companyAddressLine1,
            addressLine2: this.state.companyAddressLine2,
            addressCity: this.state.companyAddressCity,
            postcode: this.state.companyPostcode
          }
        });

      } else {

        // handle error
        this.handleOrderError();

      }


    })
    .catch((error) => {
        this.handleOrderError();
    });


  }



  handleOrderError = () => {

    this.setState({
      displayError: true,
      buttonLoading: false
    });

    window.scrollTo(0, 0);

  }


  handleOrderSummaryButton = () => {

    this.refs['checkoutForm'].submit();

  }


  render() {

      // let submitButton = this.state.buttonLoading ? (
      //   <div className="loading-spinner-button">
      //     <Spinner name="pacman" color="#65c84c" fadeIn="half" />
      //   </div>
      // ) : (
      //   <input type="submit" value={this.state.buttonText} className="medium-button fadeIn" />
      // );

      const topAlert = this.state.displayError ? (
        <div className="alert alert--red">
          Something went wrong, please try again. If the issue presists, call (01604) 946888.
        </div>
      ) : (
        <div>
        </div>
      );

      return (
        <div className="content-wrap">

          <Helmet>
            <title>Checkout - ylo</title>
            <meta name="description" content="A few details and you'll almost have it." />
          </Helmet>

          <div className="page-intro">
            <h1>Checkout</h1>
            <h4>A few details and you'll almost have it.</h4>
          </div>

          <div className="content-with-sidebar">

            <ValidatorForm onSubmit={this.handleSubmit} ref="checkoutForm" style={{ maxWidth: '500px' }}>

              <div style={{ marginBottom: '10px' }}>
                <h3 style={{ marginBottom: '38px' }}>Your Details</h3>
                {topAlert}
              </div>

              <div className="input-group">
                <div className="input-wrap">
                  <div className="input-label">Full Name</div>
                  <ValidatedTextInput
                    onChange={this.handleContactNameChange}
                    name="contactName"
                    value={this.state.contactName}
                    validators={['required', "matchRegexp:(^[-'a-zA-ZÀ-ÖØ-öø-ſ\\s]+$)"]}
                    errorMessages={['Please provide your name.', 'Ensure you only use letters.']}
                  />
                </div>

                <div className="input-wrap">
                  <div className="input-label">Contact Number</div>
                  <ValidatedTextInput
                    onChange={this.handleContactNumberChange}
                    name="contactNumber"
                    value={this.state.contactNumber}
                    validators={['required', "matchRegexp:^[0-9 ()+-]+$"]}
                    errorMessages={['Please provide a phone number.', 'Only use numbers.']}
                  />
                </div>
              </div>

              <div className="input-group">
                <div className="input-wrap">
                  <div className="input-label">Email</div>
                  <ValidatedEmailInput
                    onChange={this.handleContactEmailChange}
                    name="contactEmail"
                    value={this.state.contactEmail}
                    validators={['required']}
                    errorMessages={['An email address is required.']}
                  />
                </div>
              </div>


              <h3 style={{marginTop: '20px'}}>Address</h3>

              <div className="input-group">
                <div className="input-wrap">
                  <div className="input-label">Address Line 1</div>
                  <ValidatedTextInput
                    onChange={this.handleCompanyAddressLine1Change}
                    name="companyAddressLine1"
                    value={this.state.companyAddressLine1}
                    validators={['required', "matchRegexp:^[-'a-zA-Z0-9À-ÖØ-öø-ſ\\s]+$"]}
                    errorMessages={['An address is required.', 'Only use letters and numbers.']}
                  />
                </div>

                <div className="input-wrap">
                  <div className="input-label">Address Line 2</div>
                  <ValidatedTextInput
                    onChange={this.handleCompanyAddressLine2Change}
                    name="companyAddressLine2"
                    value={this.state.companyAddressLine2}
                    validators={["matchRegexp:^[-'a-zA-Z0-9À-ÖØ-öø-ſ\\s]+$"]}
                    errorMessages={['Only use letters and numbers.']}
                  />
                </div>
              </div>

              <div className="input-group">
                <div className="input-wrap">
                  <div className="input-label">City/Town</div>
                  <ValidatedTextInput
                    onChange={this.handleCompanyAddressCityChange}
                    name="companyAddressCity"
                    value={this.state.companyAddressCity}
                    validators={['required', "matchRegexp:^[-'a-zA-ZÀ-ÖØ-öø-ſ\\s]+$"]}
                    errorMessages={['A city or town is required.', 'Only use letters.']}
                  />
                </div>

                <div className="input-wrap">
                  <div className="input-label">Postcode</div>
                  <ValidatedTextInput
                    onChange={this.handleCompanyPostcodeChange}
                    name="companyPostcode"
                    value={this.state.companyPostcode}
                    validators={['required']}
                    errorMessages={['A postcode is required.']}
                  />
                </div>
              </div>

            </ValidatorForm>

            <OrderSummary
              order={this.state.order}
              buttonPress={this.handleOrderSummaryButton}
              buttonLoading={this.state.buttonLoading}
              buttonText={this.state.buttonText}
            />

          </div>
        </div>
      );


  }

  //<input type="text" onChange={this.handleCompanyIndustryTypeChange} name="companyIndustryType" value={this.state.companyIndustryType} className="txt-input" />

}

export default CheckoutDetails;
