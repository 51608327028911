const SwitchContent = (contentType, paths, availableContent) => {

  // contentType: personal or business
  // slug: the simplified path name for the page/category from the url, missing first slash
  // availableContent: an array of content like all pages or categories to find a potential match

  let slugs = [];

  paths.forEach(pathName => {

      let slug = pathName.substring(1);
      slug = slug.replace(/\/$/, ""); // remove any trailing/final slash

      if (slug.includes('category/')) {
        slug = slug.replace('category/', '');
      }


      if (contentType === 'business') {

        // Switched to business --------------------

        if (slug && !slug.includes('business-')) {
          // Create business version of path/slug and see if it exists
          slug = 'business-' + slug;
          //slugs.push('business-' + slug);

        } else if (slug && slug.includes('business-')) {
          // Do nothing if we switch to business but the slug happens to already be a business page

        } else {
          // Send them home
          //return HomePath(contentType);
        }

      } else {

        // Switched to personal --------------------

        if (slug && slug.includes('business-')) {
          // Remove "business" and see if a corresponding personal page exists
          slug = slug.replace('business-', '');
          //slugs.push(slug.replace('business-', ''));

        } else {
          // Not a business page recognised. Send them home
          //return HomePath(contentType);
        }

      }

      slugs.push(slug);

  });


  const content = availableContent.find(content => {
    const cleanContentSlug = content.slug.replace('/', '');
    return slugs.includes(cleanContentSlug);
  });


  if (content) {
    return '/' + content.slug.replace('/', '');
  } else {
    // Not found a page, take them to the home page relevant to their content type
    return HomePath(contentType);
  }

}


const HomePath = (contentType) => {

  if (contentType === 'business') {
    return '/business-home';
  } else {
    return '/home';
  }

}

export default SwitchContent;
