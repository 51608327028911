import React from "react";
import { NavLink } from "react-router-dom";

// <NavLink
//   exact
//   to={{
//     pathname: "/category/phones/ee/",
//     state: {
//       "category": "phones"
//     },
//   }}
//   activeClassName="selected"
// >

const TabsBroadband = () => (
<nav className="nav-tabs">
  <ul>
    <li>
      <NavLink exact to="/broadband/" activeClassName="selected">Fibre Broadband</NavLink>
    </li>
    <li>
      <NavLink exact to="/broadband/mobile/" activeClassName="selected">Mobile Broadband</NavLink>
    </li>
  </ul>
</nav>
);

export default TabsBroadband;