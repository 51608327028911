import React from "react";
import { Link } from "react-router-dom";
import {Helmet} from "react-helmet";
import htmr from 'htmr';

import * as api from "../../config/api";

class NoMatch extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      pageContent: '',
      pageTitle: '',
      pageMeta: {}
    }
  }

  componentDidMount = () => {
    window.scrollTo(0,0);

    this.getPage();
  }

  getPage = () => {

    return fetch(api.cacheURL + 'item-by-id.php?slug=404-new', {
      headers: {
        'Accept': 'application/json'
      },
    })
    .then((response) => response.json())
    .then((responseJson) => {

        const page = responseJson;

        let metaObj = {
          title: 'ylo',
          description: "Welcome, we are ylo. Bringing energy, telecoms and more."
        };

        if (!page?.id) {
            this.setState({
              isLoading: false,
              pageContent: "",
              notFound: true,
              pageMeta: metaObj
            });

            return;
        }

        if (page?.yoast_head_json?.og_title) {
          metaObj.title = page.yoast_head_json.og_title;
        }

        if (page?.yoast_head_json?.og_description) {
          metaObj.description = page.yoast_head_json.og_description;
        }

        this.setState({
          isLoading: false,
          pageContent: page.content.rendered,
          pageMeta: metaObj
        });

    })
    .catch((error) => {
    });

  }

  render() {
    let htmlElements = htmr(this.state.pageContent);

    return (
      <div className="content-wrap" style={{paddingTop: '0px', paddingBottom: '0px'}}>

        <Helmet>
          <title>{this.state.pageMeta.title}</title>
          <meta name="description" content={this.state.pageMeta.description} />
          <script type="text/javascript" src="https://assets.calendly.com/assets/external/widget.js" async></script>
        </Helmet>

        <div>{htmlElements}</div>

      </div>
    );
  }

}

export default NoMatch;
