import React from "react";

import SidebarSubMenu from "./SidebarSubMenu";

class Sidebar extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      filters: {
        dealsType: 'Pay Monthly',
        monthCost: {},
        upfrontCost: {},
        monthlyData: '',
        totalCost: {},
        order: 'recommended'
      },
      filterOpen: false
    }

    //this.getContent();
  }


  componentDidMount = () => {
  }


  componentDidUpdate = (prevProps) => {

    // if (prevProps.match.params.name !== this.props.match.params.name) {
    //
    //   this.setState({
    //     isLoading: true,
    //     products: [],
    //     productsDisplayed: [],
    //   });
    //   this.getContent();
    //
    // } else if (prevProps.match.params.tag !== this.props.match.params.tag) {
    //
    //   if (this.props.match.params.tag) {
    //
    //     this.setState({
    //       isLoading: true,
    //       productsDisplayed: [],
    //     });
    //     this.filterProducts(this.props.match.params.tag);
    //
    //   } else {
    //     this.removeFilter();
    //   }
    //
    // }

  }


 // removeFilter = () => {
 //
 //   this.setState({
 //     isLoading: false,
 //     productsDisplayed: this.state.products,
 //   });
 //
 // }



 shuffleArray = (array) => {

   let m = array.length, t, i;

   // While there remain elements to shuffle…
   while (m) {

     // Pick a remaining element…
     i = Math.floor(Math.random() * m--);

     // And swap it with the current element.
     t = array[m];
     array[m] = array[i];
     array[i] = t;
   }

   return array;

 }


 filterProducts = (currTag) => {

   let productsFiltered = [];

   if (currTag === "o2" || currTag === "ee" || currTag === "vodafone") {

     const allProducts = [...this.state.products];
     const shuffledProducts = this.shuffleArray(allProducts);

     this.setState({
       isLoading: false,
       productsDisplayed: shuffledProducts,
     });

   } else {

     this.state.products.forEach((product, index) => {

       // See if "currTag" provided in url is infact subcategory, if so add
       let sc = 0;
       for (sc = 0; sc < product.categories.length; sc++) {
         if (product.categories[sc].slug === currTag) {
           productsFiltered.push(product);
         }
       }

       // Not subcategory, check against tags and add product
       let t = 0;
       for (t = 0; t < product.tags.length; t++) {
         if (product.tags[t].slug === currTag) {
           productsFiltered.push(product);
         }
       }

       if (this.state.products.length - 1 === index) {
         this.setState({
           isLoading: false,
           productsDisplayed: productsFiltered,
         }, function() {
         });
       }

     });

   }

 }



 handleOptionSelected = (reference, value, event) => {

   // prevent browser window jump for link
   event.preventDefault();

   this.setState(prevState => {
     let filters = { ...prevState.filters };  // creating copy of current state variable

     // Set the filter or remove it if already set currently (toggle)
     if (filters[reference] === value) {
       filters[reference] = '';
     } else if (JSON.stringify(filters[reference]) === JSON.stringify(value)) {
       filters[reference] = {};
     } else {
       filters[reference] = value;
     }

     return { filters };
   }, function () {
     this.props.filterChanged(this.state.filters);
   });


 }


 phoneFilters = () => {

   return (
     <React.Fragment>
       <div className={this.state.filterOpen ? "sidebar-block sidebar-block--show" : "sidebar-block"}>
         <div className="option-label"><h4>Type of Deal</h4></div>
         <div className="filter-options">
           <a href="#" onClick={this.handleOptionSelected.bind(this, 'dealsType', 'Pay Monthly')} className={'Pay Monthly' === this.state.filters.dealsType ? "filter-option filter-option--selected" : "filter-option"}>Pay Monthly</a>
           <a href="#" onClick={this.handleOptionSelected.bind(this, 'dealsType', 'Sim Free')} className={'Sim Free' === this.state.filters.dealsType ? "filter-option filter-option--selected" : "filter-option"}>Sim Free</a>
           <a href="#" onClick={this.handleOptionSelected.bind(this, 'dealsType', 'Pay As You Go')} className={'Pay As You Go' === this.state.filters.dealsType ? "filter-option filter-option--selected" : "filter-option"}>Pay As You Go</a>
         </div>
       </div>

       {this.state.filters.dealsType === 'Pay Monthly' &&
        <React.Fragment>
           <div className={this.state.filterOpen ? "sidebar-block sidebar-block--show" : "sidebar-block"}>
             <div className="option-label"><h4>Monthly Cost</h4></div>
             <div className="filter-options">
               <a href="#" onClick={this.handleOptionSelected.bind(this, 'monthCost', {value: '10', type: 'upto'})} className={'10' === this.state.filters.monthCost.value ? "filter-option filter-option--selected" : "filter-option"}>Upto £10</a>
               <a href="#" onClick={this.handleOptionSelected.bind(this, 'monthCost', {value: '20', type: 'upto'})} className={'20' === this.state.filters.monthCost.value ? "filter-option filter-option--selected" : "filter-option"}>Upto £20</a>
               <a href="#" onClick={this.handleOptionSelected.bind(this, 'monthCost', {value: '30', type: 'upto'})} className={'30' === this.state.filters.monthCost.value ? "filter-option filter-option--selected" : "filter-option"}>Upto £30</a>
               <a href="#" onClick={this.handleOptionSelected.bind(this, 'monthCost', {value: '40', type: 'upto'})} className={'40' === this.state.filters.monthCost.value ? "filter-option filter-option--selected" : "filter-option"}>Upto £40</a>
               <a href="#" onClick={this.handleOptionSelected.bind(this, 'monthCost', {value: '50', type: 'upto'})} className={('50' === this.state.filters.monthCost.value && 'upto' === this.state.filters.monthCost.type) ? "filter-option filter-option--selected" : "filter-option"}>Upto £50</a>
               <a href="#" onClick={this.handleOptionSelected.bind(this, 'monthCost', {value: '50', type: 'over'})} className={('50' === this.state.filters.monthCost.value && 'over' === this.state.filters.monthCost.type) ? "filter-option filter-option--selected" : "filter-option"}>Over £50</a>
             </div>
           </div>

           <div className={this.state.filterOpen ? "sidebar-block sidebar-block--show" : "sidebar-block"}>
             <div className="option-label"><h4>Upfront Cost</h4></div>
             <div className="filter-options">
               <a href="#" onClick={this.handleOptionSelected.bind(this, 'upfrontCost', {value: '0', type: 'upto'})} className={'0' === this.state.filters.upfrontCost.value ? "filter-option filter-option--selected" : "filter-option"}>No Upfront Cost</a>
               <a href="#" onClick={this.handleOptionSelected.bind(this, 'upfrontCost', {value: '50', type: 'upto'})} className={'50' === this.state.filters.upfrontCost.value ? "filter-option filter-option--selected" : "filter-option"}>Upto £50</a>
               <a href="#" onClick={this.handleOptionSelected.bind(this, 'upfrontCost', {value: '100', type: 'upto'})} className={'100' === this.state.filters.upfrontCost.value ? "filter-option filter-option--selected" : "filter-option"}>Upto £100</a>
               <a href="#" onClick={this.handleOptionSelected.bind(this, 'upfrontCost', {value: '150', type: 'upto'})} className={('150' === this.state.filters.upfrontCost.value && 'upto' === this.state.filters.upfrontCost.type) ? "filter-option filter-option--selected" : "filter-option"}>Upto £150</a>
               <a href="#" onClick={this.handleOptionSelected.bind(this, 'upfrontCost', {value: '150', type: 'over'})} className={('150' === this.state.filters.upfrontCost.value && 'over' === this.state.filters.upfrontCost.type) ? "filter-option filter-option--selected" : "filter-option"}>Over £150</a>
             </div>
           </div>

           <div className={this.state.filterOpen ? "sidebar-block sidebar-block--show" : "sidebar-block"}>
             <div className="option-label"><h4>Monthly Data</h4></div>
             <div className="filter-options">
               <a href="#" onClick={this.handleOptionSelected.bind(this, 'monthlyData', '1GB')} className={'1GB' === this.state.filters.monthlyData ? "filter-option filter-option--selected" : "filter-option"}>500MB - 5GB</a>
               <a href="#" onClick={this.handleOptionSelected.bind(this, 'monthlyData', '5GB')} className={'5GB' === this.state.filters.monthlyData ? "filter-option filter-option--selected" : "filter-option"}>5GB - 10GB</a>
               <a href="#" onClick={this.handleOptionSelected.bind(this, 'monthlyData', '10GB')} className={'10GB' === this.state.filters.monthlyData ? "filter-option filter-option--selected" : "filter-option"}>10GB - 20GB</a>
               <a href="#" onClick={this.handleOptionSelected.bind(this, 'monthlyData', '20GB')} className={'20GB' === this.state.filters.monthlyData ? "filter-option filter-option--selected" : "filter-option"}>20GB - 50GB</a>
               <a href="#" onClick={this.handleOptionSelected.bind(this, 'monthlyData', '50GB')} className={'50GB' === this.state.filters.monthlyData ? "filter-option filter-option--selected" : "filter-option"}>50GB - 100GB</a>
               <a href="#" onClick={this.handleOptionSelected.bind(this, 'monthlyData', '100GB')} className={'100GB' === this.state.filters.monthlyData ? "filter-option filter-option--selected" : "filter-option"}>100GB +</a>
               <a href="#" onClick={this.handleOptionSelected.bind(this, 'monthlyData', 'Unlimited')} className={'Unlimited' === this.state.filters.monthlyData ? "filter-option filter-option--selected" : "filter-option"}>Unlimited</a>
             </div>
           </div>
         </React.Fragment>
       }

       {(
         this.state.filters.dealsType === 'Pay As You Go' ||
         this.state.filters.dealsType === 'Sim Free'
        ) &&
         <div className={this.state.filterOpen ? "sidebar-block sidebar-block--show" : "sidebar-block"}>
           <div className="option-label"><h4>Total Cost</h4></div>
           <div className="filter-options">
             <a href="#" onClick={this.handleOptionSelected.bind(this, 'totalCost', {value: '150', type: 'upto'})} className={'150' === this.state.filters.totalCost.value ? "filter-option filter-option--selected" : "filter-option"}>Upto £150</a>
             <a href="#" onClick={this.handleOptionSelected.bind(this, 'totalCost', {value: '300', type: 'upto'})} className={'300' === this.state.filters.totalCost.value ? "filter-option filter-option--selected" : "filter-option"}>Upto £300</a>
             <a href="#" onClick={this.handleOptionSelected.bind(this, 'totalCost', {value: '400', type: 'upto'})} className={'400' === this.state.filters.totalCost.value ? "filter-option filter-option--selected" : "filter-option"}>Upto £400</a>
             <a href="#" onClick={this.handleOptionSelected.bind(this, 'totalCost', {value: '500', type: 'upto'})} className={('500' === this.state.filters.totalCost.value && 'upto' === this.state.filters.totalCost.type) ? "filter-option filter-option--selected" : "filter-option"}>Upto £500</a>
             <a href="#" onClick={this.handleOptionSelected.bind(this, 'totalCost', {value: '500', type: 'over'})} className={('500' === this.state.filters.totalCost.value && 'over' === this.state.filters.totalCost.type) ? "filter-option filter-option--selected" : "filter-option"}>Over £500</a>
           </div>
         </div>
       }


     </React.Fragment>
   );

 }



 regularFilters = () => {

   // <div className={this.state.filterOpen ? "sidebar-block sidebar-block--show" : "sidebar-block"}>
   //   <div className="option-label"><h4>Price</h4></div>
   //   <div className="filter-options">
   //     <a href="#" onClick={this.handleOptionSelected.bind(this, 'totalCost', {value: '150', type: 'upto'})} className={'150' === this.state.filters.totalCost.value ? "filter-option filter-option--selected" : "filter-option"}>Upto £150</a>
   //     <a href="#" onClick={this.handleOptionSelected.bind(this, 'totalCost', {value: '300', type: 'upto'})} className={'300' === this.state.filters.totalCost.value ? "filter-option filter-option--selected" : "filter-option"}>Upto £300</a>
   //     <a href="#" onClick={this.handleOptionSelected.bind(this, 'totalCost', {value: '400', type: 'upto'})} className={'400' === this.state.filters.totalCost.value ? "filter-option filter-option--selected" : "filter-option"}>Upto £400</a>
   //     <a href="#" onClick={this.handleOptionSelected.bind(this, 'totalCost', {value: '500', type: 'upto'})} className={('500' === this.state.filters.totalCost.value && 'upto' === this.state.filters.totalCost.type) ? "filter-option filter-option--selected" : "filter-option"}>Upto £500</a>
   //     <a href="#" onClick={this.handleOptionSelected.bind(this, 'totalCost', {value: '500', type: 'over'})} className={('500' === this.state.filters.totalCost.value && 'over' === this.state.filters.totalCost.type) ? "filter-option filter-option--selected" : "filter-option"}>Over £500</a>
   //   </div>
   // </div>


   return (
     <React.Fragment>

       <div className={this.state.filterOpen ? "sidebar-block sidebar-block--show" : "sidebar-block"}>
         <div className="option-label"><h4>Order by</h4></div>
         <div className="filter-options">
           <a href="#" onClick={this.handleOptionSelected.bind(this, 'order', 'recommended')} className={'recommended' === this.state.filters.order ? "filter-option filter-option--selected" : "filter-option"}>Recommended</a>
           <a href="#" onClick={this.handleOptionSelected.bind(this, 'order', 'low to high')} className={'low to high' === this.state.filters.order ? "filter-option filter-option--selected" : "filter-option"}>Price: Low to High</a>
           <a href="#" onClick={this.handleOptionSelected.bind(this, 'order', 'high to low')} className={'high to low' === this.state.filters.order ? "filter-option filter-option--selected" : "filter-option"}>Price: High to Low</a>
         </div>
       </div>

     </React.Fragment>
   );

 }


 toggleFilter = (event) => {

   event.preventDefault();

   this.setState(prevState => ({
    filterOpen: !prevState.filterOpen
   }));

 }



  render() {

    if (this.state.isLoading) {

      return (
        <div className="main-sidebar">
        </div>
      );

    } else {

      let mobileFilterBttn = this.state.filterOpen ? (
        <a
          href="#"
          onClick={this.toggleFilter.bind(this)}
          className="mobile-filter-bttn mobile-filter-bttn--active">
          Filter ↑
        </a>
      ) : (
        <a
          href="#"
          onClick={this.toggleFilter.bind(this)}
          className="mobile-filter-bttn">
          Filter ↓
        </a>
      );


      let regularFilters = null;
      if (this.props.info.id !== 327 && this.props.info.id !== 16) {
        regularFilters = this.regularFilters();
      }

      return (
        <div className="main-sidebar">

          {this.props.showSummary &&
            <div className="category-summary">
              <h1>{this.props.info.name}</h1>
              <h5>{this.props.info.description}</h5>
            </div>
          }

          {mobileFilterBttn}

          {this.props.info.id === 327 &&
            this.phoneFilters()
          }

          {regularFilters}

          {this.props.info.display_submenu &&
            <SidebarSubMenu filterOpen={this.state.filterOpen} menuName={this.props.info.slug + "-tabs"} />
          }

        </div>
      );

    }

  }

}

export default Sidebar;
