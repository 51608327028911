import React from "react";
import { Link } from "react-router-dom";
import Spinner from "react-spinkit";
import {Helmet} from "react-helmet";
import Iframe from 'react-iframe'

import * as api from "../config/api";
import * as AvailablePlans from "../config/AvailablePlans";
//import Modal from "./Modal";
import OrderSummary from "./cart-checkout/OrderSummary";

class ProductExtras extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      accessToken: '',
      pageContent: '',
      pageTitle: '',
      pageMeta: {},
      insurancePlans: [],
      products: [],
      cases: [],
      order: [],
      pressedButtons: []
    }

  }



  componentDidMount = () => {

    window.scrollTo(0, 0);

    if (localStorage.getItem('cartContents')) {

      let order = JSON.parse(localStorage.getItem('cartContents'));

      this.setState({
        order: order
      }, function() {
        this.getContent();
      });

    }

  }



  getContent = () => {

    if (this.props.location && this.props.location.state) {

      // Get Insurance (if available)
      this.getInsurancePlans();

      // Used to get id for cases product category
      const phoneCategory = this.props.location.state.product.categories.find(cat => {
        if (cat.slug === "business-phones" || cat.slug === "phones") {
          return cat;
        }
      });

      if (phoneCategory) {

        // Get cases
        this.getCases("325");

      } else {

        // Get backend set cross sells for product
        //this.props.location.state.crossSellIDs

      }

    }

  }



  getInsurancePlans = () => {

    const insuranceInfo = this.props.location.state.insurance;

    console.log(insuranceInfo);

    if (insuranceInfo && insuranceInfo.available) {

      return fetch(AvailablePlans.baseURL + AvailablePlans.insurancePlans, {
        headers: {
          'Accept': 'application/json'
        },
      })
      .then((response) => response.json())
      .then((responseJson) => {

        this.parseInsurancePlans(insuranceInfo, responseJson);

      })
      .catch((error) => {
      });

    }

  }


  parseInsurancePlans = (insuranceInfo, allPlans) => {

    const applicablePlans = [];

    for (let p = 0; p < allPlans.length; p++) {

      let plan = allPlans[p];

      if (plan.equipment === insuranceInfo.equipment) {

        // The plans matches the equipment type of the product e.g. Smartphone

        // Match the item value to the correct insurance band/range to get the appropriate pricing blocks

        const rates = plan.rates;
        for (let r = 0; r < rates.length; r++) {

          //console.log(insuranceInfo.item_value);

          const itemValue = parseFloat(insuranceInfo.item_value);
          const planLowBand = parseFloat(rates[r].low_band);
          const planMaxBand = parseFloat(rates[r].max_band);

          if (itemValue > planLowBand && itemValue < planMaxBand) {
            plan.pricing = rates[r].pricing;
          }

        }

        if (plan.pricing) {
          applicablePlans.push(plan);
        }

      }

    }

    this.setState({
      insurancePlans: applicablePlans,
      isLoading: false
    });

  }




  shuffleArray = (array) => {

    let m = array.length, t, i;

    // While there remain elements to shuffle…
    while (m) {

      // Pick a remaining element…
      i = Math.floor(Math.random() * m--);

      // And swap it with the current element.
      t = array[m];
      array[m] = array[i];
      array[i] = t;
    }

    return array;

  }



  getCases = (catID) => {

    const phoneName = this.props.location.state.product.name;

    return fetch(api.woocommerceURL + 'products/?per_page=100&orderby=menu_order&order=asc&page=1&status=publish&stock_status=instock&category=' + catID, {
      headers: {
        'Accept': 'application/json',
        'Authorization': api.woocommerceAuth,
      },
    })
    .then((response) => response.json())
    .then((responseJson) => {

        console.log(responseJson)

      // Shuffle cases but keep the black option at the top
      let shuffledCases = this.shuffleArray(responseJson);

      let filteredCases = shuffledCases.filter(product => {
        if (product.attributes[0].options.includes(phoneName)) {
          return true;
        }
      });

      let orderedCases = filteredCases.sort(function (x, y) {
        return x.name == "ylo Snap Case - Black" ? -1 : y.name == "ylo Snap Case - Black" ? 1 : 0;
      });

      let finalCases = orderedCases.map(product => {

        product.product_variations.forEach((variation, i) => {
          if (variation.attributes[0].option === phoneName) {
            product.images.unshift(variation.image);
            product.active_variation = variation;
          }
        });

        return product;

      });


      if (finalCases && finalCases.length <= 4) {
        //this.getCaseImages(orderedCases, phoneName);

        this.setState({
          cases: finalCases,
          isLoading: false
        });

      } else if (finalCases) {
        //this.getCaseImages(orderedCases.slice(0, 10), phoneName);

        this.setState({
          cases: finalCases.slice(0, 4),
          isLoading: false
        });

      }


    })
    .catch((error) => {
    });

  }



  // getCaseImages = (products, phoneName) => {
  //
  //   // Take the cases and use each id to retrive the full product info with the variation images
  //   // this then allows us to display the correct case design based on the ordered phone.
  //
  //   let completeCasesArray = [];
  //
  //   let foreachPromise = Promise.all(products.map(function (product, index) {
  //
  //       return fetch(api.cacheURL + 'product.php?productID=' + product.id, {
  //         headers: {
  //           'Accept': 'application/json',
  //         }
  //       })
  //       .then((response) => response.json())
  //       .then((responseJson) => {
  //
  //         // Find varation data for the case that matches the phone being ordered
  //         // take the image obj and add it to the beginning of the images array
  //         // ensuring the correct case design is displayed
  //
  //         if (responseJson.product_variations) {
  //
  //           responseJson.product_variations.forEach(vd => {
  //             if (vd.attributes[0].option === phoneName) {
  //               responseJson.images.unshift(vd.image);
  //               responseJson.active_variation = vd;
  //             }
  //           });
  //
  //         } else {
  //
  //           responseJson.variations_data.forEach(vd => {
  //             if (vd.attributes[0].option === phoneName) {
  //               responseJson.images.unshift(vd.image);
  //               responseJson.active_variation = vd;
  //             }
  //           });
  //
  //         }
  //
  //
  //
  //         completeCasesArray.push(responseJson);
  //
  //       })
  //       .catch((error) => {
  //       });
  //
  //   }));
  //
  //
  //   return Promise.all([foreachPromise]).then(() => {
  //
  //     // Second reordering, promises sometimes complete at different times,
  //     // meaning the ordering needs to be done again to ensure the black case is first
  //     // black case is assumed most popular (ultimately need to create ordering solution in backend)
  //
  //     let reorderedCases = completeCasesArray.sort(function (x, y) {
  //       return x.name == "ylo Snap Case - Black" ? -1 : y.name == "ylo Snap Case - Black" ? 1 : 0;
  //     });
  //
  //     this.setState({
  //       cases: reorderedCases,
  //       isLoading: false
  //     });
  //
  //   });
  //
  // }





  addExtraToCart = (buttonRef, product, quantity, event) => {

    // prevent browser window jump for link
    event.preventDefault();

    let cartItem = {};

    cartItem.productID = product.id;

    if (product.variationID) {
      cartItem.variationID = product.variation_id;
    }

    cartItem.name = product.name;
    if (product.summary) {
      cartItem.summary = product.summary;
    }
    cartItem.image = product.image;
    cartItem.quantity = quantity;
    cartItem.price = product.price;

    //newCartContents.push(cartItem);

    //console.log(cartItem);

    this.setState(prevState => ({
      order: [...prevState.order, cartItem],
      pressedButtons: [...prevState.pressedButtons, buttonRef]
    }), this.saveOrder);

  }



  saveOrder = () => {

    localStorage.setItem('cartContents', JSON.stringify(this.state.order));

  }


  moveForward = () => {

     if (this.props.location.state.product.contract_product === "1") {
       this.props.history.push('/checkout/contract');
     } else {
       this.props.history.push('/checkout/details');
     }

  }



  render() {

    // {productData}
    // {this.state.productsDisplayed.length === 0 &&
    //   <li>
    //     <h3>No Results</h3>
    //   </li>
    // }

    let insuranceOptions = (<div></div>);

    if (this.state.insurancePlans.length > 0) {

      insuranceOptions = this.state.insurancePlans.map((plan, index) => {

        let pricingOptions = (<div></div>);
        const insuranceOptionIndex = index;

        pricingOptions = plan.pricing.map((option, pIndex) => {

          let price = option.price;
          let priceParsed = parseFloat(option.price);
          let reducedPrice = priceParsed - (priceParsed * .10);

          let quantity = 1;

          const cartData = {
            "id": 11320,
            "name": plan.name,
            "summary": plan.duration + " | " + option.description,
            "image": plan.icon,
            "price": reducedPrice.toFixed(2)
          };

          const button = this.state.pressedButtons.includes("insurance-" + insuranceOptionIndex + "-" + pIndex) ? (
            <div className="medium-button medium-button--grey">Added</div>
          ) : (
            <a href="#" onClick={this.addExtraToCart.bind(this, "insurance-" + insuranceOptionIndex + "-" + pIndex, cartData, quantity)} className="medium-button">Add to Order</a>
          );

          // if (pIndex === 1) {
          //
          //   // Recommended product
          //
          //   return (
          //     <div className="pricing-option" key={pIndex.toString()}>
          //       <div><span className="price-and-badge"><h4 className="price"><span style={{ textDecoration: "line-through", marginRight: "4px"}}>£{option.price}</span><span>£{reducedPrice.toFixed(2)}</span></h4><span className="recommended-badge">Popular</span></span> <span className="under-price-info">{option.description}</span></div>
          //       <a href="#" onClick={this.addExtraToCart.bind(this)} className="medium-button">Add to Order</a>
          //     </div>
          //   );
          //
          // } else {

            return (
              <div className="pricing-option" key={pIndex.toString()}>
                <div><h4 className="price"><span style={{ textDecoration: "line-through", marginRight: "4px"}}>£{option.price}</span><span>£{reducedPrice.toFixed(2)}</span></h4> <span className="under-price-info">{option.description}</span></div>
                {button}
              </div>
            );

          //}

        });

        return (
          <li className="product-extra" key={index.toString()}>

            <div className="product-extra-image"><img className="fill-div" src={plan.icon} alt={plan.name} /></div>

            <h3>{plan.name}</h3>

            <div className="plan-duration">{plan.duration}</div>

            <div className="price-view">
              {pricingOptions}
            </div>

          </li>
        );

      });

    }



    let phoneCases = (<div></div>);

    phoneCases = this.state.cases.map((product, index) => {

      let pricingText = (
        <span>£{product.price}</span>
      );

      const quantity = "1";

      const cartData = {
        "id": product.id,
        "variation_id": product.active_variation.id,
        "name": product.name,
        "summary": product.active_variation.attributes[0].option,
        "image": product.images[0].src,
        "price": product.price
      };

      const button = this.state.pressedButtons.includes("product-" + index) ? (
        <div className="medium-button medium-button--grey">Added</div>
      ) : (
        <a href="#" onClick={this.addExtraToCart.bind(this, "product-" + index, cartData, quantity)} className="medium-button">Add to Order</a>
      );


      if (index < 10) {

        return (
          <li className="product-extra" key={index.toString()}>

            <Link to={"/product/" + product.id + "/" + product.slug} className="product-extra-image"><img className="fill-div" src={product.images[0].src} alt={product.images[0].name} /></Link>

            <h3>{product.name}</h3>

            <div className="price-view">
              <h4 className="price" style={{marginBottom: '8px',  marginRight: '20px'}}>{pricingText}</h4>
              {button}
            </div>

          </li>
        );

      }

    });


    if (this.state.isLoading) {

      return (
        <div className="loading-spinner">
          <Spinner name="pacman" color="#65c84c" fadeIn="half" />
        </div>
      );

    } else {

      return (
        <div className="content-wrap">

          <Helmet>
            <title>Product Extras - ylo</title>
            <meta name="description" content="Add any extras to your order." />
          </Helmet>

          <div className="page-intro">
            <h1>Finishing Touches</h1>
            <h4>Pick a case and add insurance to protect your phone.</h4>
          </div>

          <div className="content-with-sidebar">

            <div className="columns-wrap product-extra-options" style={{padding: '0px'}}>

              {this.state.cases.length > 0 &&
                <div className="product-extra-listings-intro">
                  <h3 style={{marginBottom: 16}}>Phone Cases</h3>
                  <p className="secondary-text">Pick any design for a perfect fit with your phone.</p>
                </div>
              }
              <ul className="product-extra-listings">
                {phoneCases}
              </ul>

              {this.state.insurancePlans.length > 0 &&
                <div className="product-extra-listings-intro">
                  <h3 style={{marginBottom: 16}}>Mobile Insurance</h3>
                  <p className="secondary-text">With standard cover, if you have any accidents, we’ll repair the device, if it's considerably damaged or stolen we will replace it. Select a premuim plan with loss cover and if the device is misplaced, a replacement is provided.</p>
                  <p><a target="_blank" href="https://weareylo.com/backend/wp-content/uploads/2021/12/IPID_ylotelecom_insurance.pdf">Download IPID for Full Details</a></p>
                  <p>Add insurance to your order now and get 10% off the normal price.</p>
                </div>
              }

              <ul className="product-extra-listings">
                {insuranceOptions}
              </ul>

            </div>

            <OrderSummary
              order={this.state.order}
              buttonPress={this.moveForward}
              buttonLoading={this.state.buttonLoading}
              buttonText="Checkout ->"
            />

          </div>
        </div>
      );

    }


  }

}

export default ProductExtras;
