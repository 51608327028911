import React from "react";
import { Link } from "react-router-dom";
import Spinner from "react-spinkit";

import * as api from "../../config/api";


class FormContact extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      buttonLoading: false,
      accessToken: '',
      pageContent: '',

      contactName: '',
      contactEmail: '',
      contactNumber: '',
      //companyName: '',
      formSubmitted: false,
    }

    this.handleContactNameChange = this.handleContactNameChange.bind(this);
    this.handleContactEmailChange = this.handleContactEmailChange.bind(this);
    this.handleContactNumberChange = this.handleContactNumberChange.bind(this);
    //this.handleCompanyNameChange = this.handleCompanyNameChange.bind(this);
    this.handleContactCommentsChange = this.handleContactCommentsChange.bind(this);

    this.handleSubmit = this.handleSubmit.bind(this);
  }



  componentDidMount = () => {
    window.scrollTo(0, 0);
  }


  handleContactNameChange = (event) => {
    this.setState({contactName: event.target.value});
  }

  handleContactEmailChange = (event) => {
    this.setState({contactEmail: event.target.value});
  }

  handleContactNumberChange = (event) => {
    this.setState({contactNumber: event.target.value});
  }

  // handleCompanyNameChange = (event) => {
  //   this.setState({companyName: event.target.value});
  // }

  handleContactCommentsChange = (event) => {
    this.setState({contactComments: event.target.value});
  }



  handleSubmit = (event) => {

    // prevent browser window jump for link
    event.preventDefault();

    if (!this.state.contactEmail) {
      return;
    }

    this.setState({buttonLoading: true});

    let data = {
        "source": "weareylo.com",
        "page": window.location.pathname,
        "name": this.state.contactName,
        "email": this.state.contactEmail,
        "phone_number": this.state.contactNumber,
        "comments": this.state.contactComments
    };

    const bodyJSON = JSON.stringify(data);

    return fetch('https://ylo.io/api/telecom/contact-form', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: bodyJSON
    })
    .then((response) => response.json())
    .then((responseJson) => {

      if (responseJson.success) {
        this.setState({ buttonLoading: false, formSubmitted: true });

        // Redirect
        // if (this.props.redirectSlug) {
        //     this.props.history.push('/' + this.props.redirectSlug);
        // }
      }

    })
    .catch((error) => {
      console.log(error)
    });

  }




  render() {


    const content = this.props.content ? (
      <div dangerouslySetInnerHTML={{__html: this.props.content}}>
      </div>
    ) : (
      <div>
        <h2>Reach Out</h2>
        <h4 className="secondary-text">To find out how we can help your business, get in touch using the contact form and provide any information you can on your setup and requirements.</h4>
        <h4 className="secondary-text">If you prefer to speak to someone over the phone, call (01604) 946888, our team are available from 9am to 5pm.</h4>
      </div>
    );


    if (this.state.formSubmitted) {

      return (
        <div className="content contact-area">

          <div className="contact-area-side">
            {content}
          </div>

          <form style={{ alignSelf: 'center' }}>
            <div className="alert alert--ylo">Thank you for contacting us. One of our team will be in touch as soon as possible.</div>
          </form>

        </div>
      );

    } else {

      let submitButton = this.state.buttonLoading ? (
        <div className="loading-spinner-button">
          <Spinner name="pacman" color="#65c84c" fadeIn="half" />
        </div>
      ) : (
        <input type="submit" value="Send" className="large-button fadeIn" />
      );

      // <div className="input-wrap">
      //   <div className="input-label">Company Name</div>
      //   <input type="text" onChange={this.handleCompanyNameChange} name="companyName" value={this.state.companyName} className="txt-input" />
      // </div>

      return (
        <div className="content contact-area">

        <div className="contact-area-side">
          {content}
        </div>

        <form onSubmit={this.handleSubmit}>

          <div className="input-group">
            <div className="input-wrap">
              <div className="input-label">Your Name</div>
              <input
                type="text"
                onChange={this.handleContactNameChange}
                name="contactName" value={this.state.contactName}
                className="txt-input"
              />
            </div>

            <div className="input-wrap">
              <div className="input-label">Email</div>
              <input type="email" onChange={this.handleContactEmailChange} name="contactEmail" value={this.state.contactEmail} className="txt-input" />
            </div>
          </div>

          <div className="input-group">
            <div className="input-wrap">
              <div className="input-label">Phone Number</div>
              <input type="text" onChange={this.handleContactNumberChange} name="contactNumber" value={this.state.contactNumber} className="txt-input" />
            </div>
          </div>

          <div className="input-wrap">
            <div className="input-label">Comments</div>
            <textarea onChange={this.handleContactCommentsChange} name="contactComments" className="txt-area" rows="5">
              {this.state.contactComments}
            </textarea>
          </div>

          <div>
            {submitButton}
          </div>

        </form>
        </div>
      );

    }

  }

}

export default FormContact;
