import React from "react";
import { Link } from "react-router-dom";
import Spinner from "react-spinkit";
import {Helmet} from "react-helmet";

import * as api from "../config/api";
import * as AvailablePlans from "../config/AvailablePlans";

import TabsMenu from "./tabs/TabsMenu";


class Plans extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      accessToken: '',
      plans: [],
      plansDisplayed: [],
      orderQuantity: 1,
    }


    this.handleQuantityChange = this.handleQuantityChange.bind(this);

  }


  componentDidMount = () => {
    window.scrollTo(0, 0);

    this.organisePlans();
  }


  componentDidUpdate = (prevProps) => {

    if (prevProps.match.params.tag !== this.props.match.params.tag) {

      if (this.props.match.params.tag) {

        this.setState({
          isLoading: true,
          plansDisplayed: [],
        });
        this.filterPlans(this.state.plans);

      } else {
        this.removeFilter();
      }

    }

  }



  organisePlans = () => {

    // Get initial plans and structure them

    return fetch(AvailablePlans.baseURL + AvailablePlans.simPlans, {
      headers: {
        'Accept': 'application/json'
      },
    })
    .then((response) => response.json())
    .then((responseJson) => {

      let allPlans = responseJson.map((plan) => {

        const planName = plan.name;
        const planIcon = plan.icon;

        return plan.tiers.map((tier, i) => {

          let singlePlan = Object.assign({}, tier);
          singlePlan['name'] = planName;
          singlePlan['icon'] = planIcon;

          return singlePlan;

        })

      });


      // Sort plans by price

      allPlans = allPlans.reduce((a, b) => a.concat(b), []);
      let orderedPlans = allPlans.sort((a, b) => {
        return parseFloat(a.price) - parseFloat(b.price)
      });


      return this.filterPlans(orderedPlans);

    })
    .catch((error) => {
    });


  }



  filterPlans = (allPlans) => {

    // Filter if neccessary

    let filteredPlans = [];
    const currTag = this.props.match.params.tag;

    if (currTag) {

      if (currTag === "o2" || currTag === "ee" || currTag === "vodafone") {

        allPlans.forEach((plan, index) => {

          if (plan.name.toLowerCase().includes(currTag)) {
            filteredPlans.push(plan);
          }

          if (allPlans.length - 1 === index) {
            this.setState({
              isLoading: false,
              plans: allPlans,
              plansDisplayed: filteredPlans,
            });
          }

        });


      } else if (currTag.includes("gb") || currTag.includes("mb") || currTag.includes("unlimited")) {

        const monthlyData = ['500MB','1GB','2GB','3GB','4GB','5GB','6GB','8GB','10GB','12GB','13GB','14GB','15GB','16GB','20GB','21GB','22GB','23GB','24GB','25GB','26GB','27GB','28GB','29GB','30GB','35GB','40GB','45GB','50GB','55GB','60GB','70GB','75GB','80GB','100GB','UNLIMITED'];

        let monthlyDataRange = currTag.split("-");

        let monthlyDataSelected = monthlyData.slice(monthlyData.indexOf(monthlyDataRange[0].toUpperCase()) + 1, monthlyData.indexOf(monthlyDataRange[1].toUpperCase()) + 1);

        if (monthlyDataRange[0].toUpperCase() === '500MB') {
          monthlyDataSelected = monthlyData.slice(monthlyData.indexOf(monthlyDataRange[0].toUpperCase()), monthlyData.indexOf(monthlyDataRange[1].toUpperCase()) + 1);
        }

        allPlans.forEach((plan, index) => {

          if (monthlyDataSelected.includes(plan.data.toUpperCase())) {
            filteredPlans.push(plan);
          }

          if (allPlans.length - 1 === index) {
            this.setState({
              isLoading: false,
              plans: allPlans,
              plansDisplayed: filteredPlans,
            });
          }

        });

      }


    } else {

      // No filtering needed

      this.setState({
        plans: allPlans,
        plansDisplayed: allPlans,
      });

    }

  }


  removeFilter = () => {

    this.setState({
      isLoading: false,
      plansDisplayed: this.state.plans,
    });

  }



  shuffleArray = (array) => {

    let m = array.length, t, i;

    // While there remain elements to shuffle…
    while (m) {

      // Pick a remaining element…
      i = Math.floor(Math.random() * m--);

      // And swap it with the current element.
      t = array[m];
      array[m] = array[i];
      array[i] = t;
    }

    return array;

  }




  addContractToCart = (planName, planIcon, monthlyData, monthlyTexts, monthlyMinutes, monthlyCost, upfrontCost, event) => {

    // prevent browser window jump for link
    event.preventDefault();

    let newCartContents = [];
    if (localStorage.getItem('cartContents')) {
      newCartContents = JSON.parse(localStorage.getItem('cartContents'));
    }

    // 2002 - Sim-Only product ID
    // 2004 - Mobile Internet product ID

    let cartItem = {};
    cartItem.productID = 2002;

    cartItem.name = planName;
    cartItem.summary = monthlyData + ' Monthly Data. Unlimited Calls & Texts';
    cartItem.image = planIcon;
    cartItem.quantity = this.state.orderQuantity;
    cartItem.price = upfrontCost;

    cartItem.simOnlyContract = true;
    cartItem.contractPlan = planName;
    cartItem.contractPlanIcon = planIcon;
    cartItem.contractMonthlyData = monthlyData;
    cartItem.contractMonthlyTexts = monthlyTexts;
    cartItem.contractMonthlyMinutes = monthlyMinutes;
    cartItem.contractMonthlyCost = monthlyCost;
    cartItem.contractUpfrontCost = upfrontCost;


    newCartContents.push(cartItem);

    localStorage.setItem('cartContents', JSON.stringify(newCartContents));

    this.props.history.push('/checkout/cart');

  }


  handleQuantityChange = (event) => {

   event.stopPropagation();
   this.setState({orderQuantity: event.target.value});
  }


  setSelectedOverlay = (id, event) => {

    event.stopPropagation();

    // prevent browser window jump for link
    event.preventDefault();

    this.setState({
      selectedOverlayID: id,
    });

  }


  hideOverlay = (event) => {

    // prevent browser window jump for link
    event.preventDefault();

    if (this.state.selectedOverlayID || this.state.selectedOverlayID === 0) {
      this.setState({
        selectedOverlayID: null,
      });
    }

  }



  render() {

    console.log(this.state.orderQuantity);

    let plans = (<div></div>);

    if (this.state.plansDisplayed) {

      plans = this.state.plansDisplayed.map((plan, index) => {

        let overlayClass = "product-selected-overlay";
        if (this.state.selectedOverlayID === index) {
          overlayClass = "product-selected-overlay product-selected-overlay--active";
        }

        return (
          <li className="product" key={index.toString()}>

            <a href="#" onClick={this.setSelectedOverlay.bind(this, index)} className="plan-image"><img className="fill-div" src={plan.icon} alt="network provider icon" /></a>

            <h3 style={{ marginBottom: '16px' }}>{plan.name}</h3>

            <h5 style={{ color: '#9a9a9a', marginBottom: '8px' }}>{plan.data} Monthly Data</h5>
            <h5 style={{ color: '#9a9a9a' }}>Unlimited Calls & Texts</h5>

            <div className="price-view">
              <h4 className="price"><span>£{plan.price}<br /> <span className="under-price-info">per month</span><span style={{fontSize: '15px', color: '#9a9a9a'}}> (exc VAT)</span></span></h4>
              <a href="#" onClick={this.setSelectedOverlay.bind(this, index)} className="medium-button">Select Plan</a>
            </div>


            <div className={overlayClass}>

              <div className="plan-image"><img className="fill-div" src={plan.icon} alt="network provider icon" /></div>

              <h3 style={{ marginBottom: '16px' }}>{plan.name}</h3>

              <h5 style={{ color: '#cecece', marginBottom: '8px' }}>{plan.data} Monthly Data</h5>
              <h5 style={{ color: '#cecece', marginBottom: '8px' }}>Unlimited Calls & Texts</h5>
              <h5 style={{ color: '#cecece', marginBottom: '24px' }}>{plan.extra}</h5>

              <h4 style={{ color: '#fff', textAlign: 'center' }}>£{plan.price}<br /> <span style={{fontSize: '16px', color: '#cecece'}}>per month (exc VAT)</span></h4>

              <div className="price-view">
                <div className="order-quantity">
                  <label style={{ color: '#cecece', marginRight: 12, }}>How Many?</label>
                  <input type="number" onChange={this.handleQuantityChange} name="connections" step="1" min="1" value={this.state.orderQuantity} className="order-quantity-input order-quantity-input--dark" />
                </div>
                <a href="#" onClick={this.addContractToCart.bind(this, plan.name, plan.icon, plan.data, plan.texts, plan.minutes, plan.price, '0.00')} className="medium-button">Add to Bag</a>
              </div>

            </div>

          </li>
        );

      });

    }



    if (this.state.isLoading) {

      return (
        <div className="loading-spinner">
          <Spinner name="pacman" color="#65c84c" fadeIn="half" />
        </div>
      );

    } else {

      return (
        <div className="content-wrap" onClick={this.hideOverlay.bind(this)}>

          <Helmet>
            <title>Sim-Only Deals - ylo</title>
            <meta name="description" content="Perfect if you're seeking a better deal for your phone." />
          </Helmet>

          <div className="category-intro">
            <h1>Sim-Only Deals</h1>
            <h4>Perfect if you're seeking a better deal for your phone.</h4>
          </div>

          <TabsMenu menuName="sim-only-tabs" />

          <ul className="product-listings">
            {plans}
          </ul>
        </div>
      );

    }

  }

}

export default Plans;
